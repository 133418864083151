// Path: ./src/app/components/MapStatic/index.tsx
/**
 *
 * MapStatic
 *
 */
// import React, { memo } from 'react';
// import { useTranslation } from 'react-i18next';
//

// interface Props {}

// export const MapStatic = memo((props: Props) => {
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   const { t, i18n } = useTranslation();

//   return (
//     <div>
//       {t('')}
//       Static Map will be here
//     </div>
//   );
// });

import * as React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import LocationLogo from '../../assets/blueLocation.png';
import SelectedPin from '../../assets/SelectedPin.png';
import { GoFlyerAppContext } from 'app/store/context';

const containerStyle = {
  width: '100%',
  height: 270,
};

const center = {
  lat: 43.8916834,
  lng: -79.3751358,
};

interface Props {
  store: GfStoreDto;
  center?: any;
}
interface Location {
  lat: number;
  lng: number;
}
export function MapStatic(props: Props) {
  const { state } = React.useContext(GoFlyerAppContext);
  const defaultMapOptions = {
    disableDefaultUI: true,
  };
  const [currentLocation, setCurrentLocation] = React.useState<
    Location | undefined
  >(undefined);
  React.useEffect(() => {
    CurrentLocation();
  }, []);
  const CurrentLocation = () => {
    // TODO: should move this out of common component
    const pos = {
      lat: state.location.coords.latitude,
      lng: state.location.coords.longitude,
    };
    setCurrentLocation(pos);
  };
  return (
    <LoadScript
      googleMapsApiKey={
        window.config.REACT_APP_GOFLYER_GOOGLE_MAP_API_KEY as string
      }
      data-testid="mapstatic-test-id"
    >
      <GoogleMap
        options={defaultMapOptions}
        mapContainerStyle={containerStyle}
        center={props.center ? props.center : center}
        zoom={11}
      >
        {currentLocation !== undefined && (
          <Marker
            key={currentLocation.lat}
            position={currentLocation}
            icon={LocationLogo}
          />
        )}
        <Marker
          key={props.store.id}
          icon={SelectedPin}
          position={{
            lat: props.store.location[`coordinates`][1],
            lng: props.store.location[`coordinates`][0],
          }}
        ></Marker>
      </GoogleMap>
    </LoadScript>
  );
}
