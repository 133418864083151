// Path: ./src/app/desktop-src/app/pages/InfoPage/Footer.tsx
/**
 *
 * FooterInfo
 *
 */

import * as React from 'react';
import styled from 'styled-components/macro';
import { GoFlyerLogo } from 'app/desktop-src/app/components/GoFlyerLogo/Loadable';
import { t } from 'i18next';
import { messages } from 'locales/messages';
import { useHistory } from 'react-router-dom';

interface Props {}

export function FooterInfo(props: Props) {
  const history = useHistory();
  const ListOne = [
    {
      id: 1,
      text: t(messages.Who_we_are()),
      link: '/about',
    },
    {
      id: 2,
      text: 'iOS',
      link: 'https://apps.apple.com/ca/app/goflyer/id1580987749',
      redirect: true,
    },
    {
      id: 3,
      text: 'Android',
      link: 'https://play.google.com/store/apps/details?id=io.ionic.goflyermobile',
      redirect: true,
    },
    // {
    //   id: 4,
    //   text: t(messages.Legal()),
    // },
  ];
  const ListTwo = [
    // {
    //   id: 1,
    //   text: t(messages.FAQ_Help()),
    // },
    // {
    //   id: 2,
    //   text: t(messages.Careers()),
    // },
    {
      id: 3,
      text: t(messages.Privacy_Policy()),
      link: '/privacy-policy',
    },
    {
      id: 4,
      text: t(messages.Terms_of_Use()),
      link: 'terms-of-use',
    },
  ];

  const handleContectUs = () => {
    window.open(
      'https://us18.list-manage.com/contact-form?u=7f4392a5936a835e1e1c0ea2c&form_id=ee7eeb04c707aabb133d4d47080d3562',
      '_blank',
    );
  };

  const handleNav = (link: string | undefined) => {
    if (link) {
      history.push(link);
    }
  };
  const handleGetApp = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <TopContent data-testid="footerinfo-test-id">
      <Wrapper>
        <RightSide>
          <Title data-testid="Have any questions?">
            {' '}
            {t(messages.Have_any_questions())}
          </Title>
          <CardButton data-testid="Contact Us" onClick={handleContectUs}>
            {t(messages.Contact_Us())}
          </CardButton>
        </RightSide>
        <LeftSide>
          <List>
            {' '}
            {ListOne.map(value => {
              return (
                <ListItem
                  key={value.text}
                  onClick={() => {
                    if (value?.link) {
                      if (value?.redirect) {
                        handleGetApp(value?.link);
                      } else {
                        handleNav(value?.link);
                      }
                    }
                  }}
                  link={value?.link ? true : false}
                  data-testid="footerinfo-test-id"
                >
                  {value.text}
                </ListItem>
              );
            })}
          </List>
          <List>
            {' '}
            {ListTwo.map(value => {
              return (
                <ListItem
                  data-testid="info page list item"
                  key={value.text}
                  onClick={() => {
                    handleNav(value?.link);
                  }}
                  link={value?.link ? true : false}
                >
                  {value.text}
                </ListItem>
              );
            })}
          </List>
        </LeftSide>
      </Wrapper>
      <BottomSection>
        <LogoWrapper>
          <GoFlyerLogo height="40px" width="40px" />
        </LogoWrapper>{' '}
        © {t(messages.All_rights_reserved())}
      </BottomSection>
    </TopContent>
  );
}

const LogoWrapper = styled.div`
  margin-right: 10px;
`;
const BottomSection = styled.div`
  width: 1400px;
  border-top: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding-top: 10px;
  font-size: 20px;
  @media (max-width: 707px) {
    font-size: 15px;
  }
`;
const CardButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ef8200 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 10px;
  font: normal normal bold 20px Arial;
  letter-spacing: 0px;
  color: #ffffff;
  width: 180px;
  margin-top: 40px;
  cursor: pointer;
  transition: all 0.5s;
  @media (max-width: 707px) {
    margin-top: 10px;
    width: 120px;
    font-size: 16px;
  }

  &:hover {
    background-color: #ffffff;
    border: 1px solid #ef8200;
    color: #ef8200;
  }
`;
const List = styled.div`
  padding: 10px;
`;

interface ListItemProps {
  link?: boolean;
}
const ListItem = styled.div`
  text-align: left;
  font: normal normal normal 18px/35px Arial;
  letter-spacing: 0px;
  color: #707070;
  cursor: ${(props: ListItemProps) => {
    return props.link ? 'pointer' : 'unset';
  }};
  @media (max-width: 707px) {
    font-size: 14px;
  }
`;
const TopContent = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Wrapper = styled.div`
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 400px;

  @media (max-width: 1400px) {
    margin-left: 40px;
    margin-right: 40px;
  }
  @media (max-width: 464px) {
    margin-top: 50px;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 10px;
  }
  @media (max-width: 471px) {
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
  }
`;
const RightSide = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: column;
  @media (max-width: 1049px) {
    width: 80%;
    margin-top: 10px;
  }
  @media (max-width: 464px) {
    width: 100%;
  }
`;

const LeftSide = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: row;
  justify-content: end;
  @media (max-width: 1400px) {
    width: 80%;
  }
  @media (max-width: 464px) {
    width: 100%;
    justify-content: start;
  }
`;

const Title = styled.div`
  text-align: left;
  font: normal normal bold 53px/61px Arial;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;

  @media (max-width: 707px) {
    font-size: 30px;
    line-height: 36px;
  }
`;
