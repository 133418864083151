// Path: ./src/app/desktop-src/app/components/FlyerCard/Loadable.ts
/**
 *
 * Asynchronously loads the component for FlyerCard
 *
 */

import { lazyLoad } from 'utils/loadable';

export const FlyerCard = lazyLoad(
  () => import('./index'),
  module => module.FlyerCard,
);
