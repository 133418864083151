// Path: ./src/app/components/SearchHistory/styled.ts
import styled from 'styled-components/macro';
import { Button } from '@mui/material';
import { styled as stlyedMUI } from '@mui/material/styles';

export const MuiButton = stlyedMUI(Button)(() => ({
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 3px 12px #00000029',
  borderRadius: '10px',
  opacity: '1',
  height: '34px',
  font: 'normal normal medium 17px/34px  !important',
  fontFamily: 'SFProDisplay',
  letterSpacing: '0px',
  color: '#000000',
  textTransform: 'capitalize',
  margin: '10px',
  marginTop: '-1px',
}));
export const MainDiv = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;
export const BtnWrapper = styled.div`
  margin-top: 12px;
`;
export const SearchTitle = styled.h4`
  padding-bottom: 7px;
  margin: 0px;

  font-family: SFProDisplay;
`;
export const TitleWrapper = styled.div`
  margin-top: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #7676801f;
`;

export const EditButton = styled.button`
  background-color: white;
  outline: none;
  border: 0px;
  color: #f8a45c;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
`;
