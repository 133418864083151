// Path: ./src/app/components/EmptyStoreUi/Loadable.ts
/**
 *
 * Asynchronously loads the component for EmptyStoreUi
 *
 */

import { lazyLoad } from 'utils/loadable';

export const EmptyStoreUi = lazyLoad(
  () => import('./index'),
  module => module.EmptyStoreUi,
);
