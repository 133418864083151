// Path: ./src/app/desktop-src/app/pages/SignInPage/index.tsx
import * as React from 'react';
import styled from 'styled-components';
import { PageHeader } from 'app/desktop-src/app/components/PageHeader';
import { messages } from 'locales/messages';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/RootState';
import { useCustomerSlice } from 'app/desktop-src/store/CustomerSlice';
import { ITokenReturnBody } from '@swagger/typescript-fetch-goflyer';
import { GfCustomerDtoWithLogin } from 'app/desktop-src/store/CustomerSlice';
import CustomHelmet from 'app/components/Helmet';
import { apiService } from 'utils/api';
import { localstorageGet } from 'utils/localstorage';
import SignInPageUI from 'app/mobile-desktop-common-components/SignIn';
import LoggedInProfileUI from 'app/mobile-desktop-common-components/LoggedInProfileUI';

export const SignInPage = () => {
  const { t } = useTranslation();
  const {
    actions: { updateCustomer },
  } = useCustomerSlice();
  const { customer }: RootState = useSelector<RootState, RootState>(
    state => state,
  );
  const dispatch = useDispatch();

  const loginSuccessCallback = async (
    user: GfCustomerDtoWithLogin,
    registerResponse: ITokenReturnBody,
  ) => {
    if (user.id !== customer.id && customer && customer.notificationToken) {
      await apiService.gfCustomerControllerUpdate(
        { notificationToken: undefined },
        customer.id,
      );

      await apiService.gfCustomerControllerUpdate(
        { notificationToken: customer.notificationToken },
        user.id,
      );
    }

    dispatch(
      updateCustomer({
        ...user,
        isLogin: true,
        access_token: registerResponse.token,
      }),
    );
  };

  const onLogoutSuccessful = () => {
    dispatch(
      updateCustomer({
        isLogin: false,
      }),
    );
  };

  const loginCallBackend = async (authResult: any) => {
    const customer = localstorageGet('customer') as GfCustomerDtoWithLogin;
    apiService
      .authControllerRegister(authResult, customer.id, {})
      .then(registerResponse => {
        apiService
          .appControllerGetRequestUser({
            headers: { access_token: registerResponse.token },
          })
          .then(user => {
            let userWithLogin = user as GfCustomerDtoWithLogin;
            if (user) {
              loginSuccessCallback(userWithLogin, registerResponse);
            }
          })
          .catch(error => {
            console.error(`78 error`, error);
          });
      })
      .catch(() => {});
  };
  return (
    <>
      <CustomHelmet
        title={`Goflyer - ${t(messages.Sign_In())}`}
        description={t(messages.signInDescription())}
        keywords={t(messages.signInKeywords())}
      />
      <Container>
        <PageHeader />
        <MainContent>
          {customer?.isLogin ? (
            <LoggedInProfileUI
              customer={customer}
              onLogoutSuccessfulCallBack={onLogoutSuccessful}
            />
          ) : (
            <SignInPageUI loginCallBackend={loginCallBackend} />
          )}
        </MainContent>
      </Container>
    </>
  );
};

const Container = styled.div`
  color: black;
  height: 80%;
  overflow: hidden;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 100%;
`;
