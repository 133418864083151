// Path: ./src/app/store/actions.ts
// reference: https://github.com/hellomuthu23/react-context-example
import { Position } from '@capacitor/geolocation';
import {
  GfPromotionFavouriteListDto,
  GfStoreFavouriteListDto,
  GfFlyerDto,
  GfPromotionDto,
  GfFlyerItemDto,
} from '@swagger/typescript-fetch-goflyer';
import { GfCustomerDtoWithLogin, MapAllStores } from './state';

export enum ActionType {
  SetCustomer,
  SetPromotionFavouriteList,
  SetStoreFavouriteList,
  SetLocation,
  SetExploreFlyerAndPromotionList,
  SetBestDealsFlyerAndPromotionList,
  SetExploreFlyerAndPromotionListNumber,
  SetBestDealListNumber,
  SetMapStoreAllStores,
  SetMapStoreCounter,
  SetExploreScrollPosition,
  SetBestDealsScrollPosition,
  SetMenuSideBar,
  SetFlyerTagList,
  SetFlyerTagListNumber,
  SetFlyerTagListPosition,
  SetRemoteConfig,
  setShowBanner,
  SetLatestFlyer,
  SetLatestFlyerNumber,
  SetLatestFlyerScrollPosition,
  SetUpComingFlyerScrollPosition,
  SetUpComingFlyer,
  SetUpComingFlyerNumber,
  SetFavoriteFlyerList,
  SetFavoriteFlyerPosition,
  SetSearchScrollPosition,
  SetSearchDataList,
  SetSearchDataNumber,
}
interface reFreshPayload {
  noMerge: boolean;
}
export interface SetCustomer {
  type: ActionType.SetCustomer;
  payload: GfCustomerDtoWithLogin;
}

export interface SetShowBanner {
  type: ActionType.setShowBanner;
  payload: boolean;
}

export interface SetPromotionFavouriteList {
  type: ActionType.SetPromotionFavouriteList;
  payload: GfPromotionFavouriteListDto;
}

export interface SetStoreFavouriteList {
  type: ActionType.SetStoreFavouriteList;
  payload: GfStoreFavouriteListDto;
}

export interface SetLocation {
  type: ActionType.SetLocation;
  payload: Position;
}
export interface SetExploreFlyerAndPromotionListPayload extends reFreshPayload {
  result: (GfFlyerDto | GfPromotionDto)[] | undefined;
}
export interface SetExploreFlyerAndPromotionList {
  type: ActionType.SetExploreFlyerAndPromotionList;
  payload: SetExploreFlyerAndPromotionListPayload;
}
export interface SetBestDealsFlyerAndPromotionList {
  type: ActionType.SetBestDealsFlyerAndPromotionList;
  payload: (GfPromotionDto | GfFlyerItemDto)[] | undefined;
}

export interface SetExploreFlyerAndPromotionListNumber {
  type: ActionType.SetExploreFlyerAndPromotionListNumber;
  payload: number;
}
export interface SetBestDealListNumber {
  type: ActionType.SetBestDealListNumber;
  payload: number;
}

export interface SetMapStoreAllStores {
  type: ActionType.SetMapStoreAllStores;
  payload: MapAllStores[] | undefined;
}
export interface SetMapStoreCounter {
  type: ActionType.SetMapStoreCounter;
  payload: number;
}
export interface SetExploreScrollPosition {
  type: ActionType.SetExploreScrollPosition;
  payload: number;
}
export interface SetBestDealsScrollPosition {
  type: ActionType.SetBestDealsScrollPosition;
  payload: number;
}

export interface SetFlyerTagList {
  type: ActionType.SetFlyerTagList;
  payload: any;
}
export interface SetFlyerTagListNumber {
  type: ActionType.SetFlyerTagListNumber;
  payload: any;
}
export interface SetFlyerTagListPosition {
  type: ActionType.SetFlyerTagListPosition;
  payload: any;
}
export interface SetMenuSideBar {
  type: ActionType.SetMenuSideBar;
  payload: boolean;
}

export interface SetRemoteConfig {
  type: ActionType.SetRemoteConfig;
  payload: {
    remoteConfig: {
      getSmallImagesCondition: boolean;
    };
  };
}

export interface latestFlyerPayload extends reFreshPayload {
  result: GfFlyerDto[] | undefined;
}
export interface SetLatestFlyer {
  type: ActionType.SetLatestFlyer;

  payload: latestFlyerPayload;
}
export interface SetLatestFlyerScrollPosition {
  type: ActionType.SetLatestFlyerScrollPosition;

  payload: any;
}

export interface SetLatestFlyerNumber {
  type: ActionType.SetLatestFlyerNumber;

  payload: any;
}
export interface UpComingFlyerPayload extends reFreshPayload {
  result: GfFlyerDto[] | undefined;
}
export interface SetUpComingFlyer {
  type: ActionType.SetUpComingFlyer;
  payload: UpComingFlyerPayload;
}
export interface SetUpComingFlyerScrollPosition {
  type: ActionType.SetUpComingFlyerScrollPosition;
  payload: any;
}

export interface SetUpComingFlyerNumber {
  type: ActionType.SetUpComingFlyerNumber;
  payload: any;
}

export interface SetFavoriteFlyerList {
  type: ActionType.SetFavoriteFlyerList;
  payload: any;
}
export interface SetFavoriteFlyerPosition {
  type: ActionType.SetFavoriteFlyerPosition;
  payload: any;
}

export interface SetSearchScrollPosition {
  type: ActionType.SetSearchScrollPosition;
  payload: number;
}
export interface SetSearchDataListPayload extends reFreshPayload {
  result: (GfFlyerDto | GfPromotionDto | GfFlyerItemDto)[] | undefined;
}
export interface SetSearchDataList {
  type: ActionType.SetSearchDataList;
  payload: SetSearchDataListPayload;
}
export interface SetSearchDataNumber {
  type: ActionType.SetSearchDataNumber;
  payload: number;
}

export type GoFlyerAppActions =
  | SetCustomer
  | SetPromotionFavouriteList
  | SetStoreFavouriteList
  | SetLocation
  | SetExploreFlyerAndPromotionList
  | SetBestDealsFlyerAndPromotionList
  | SetExploreFlyerAndPromotionListNumber
  | SetBestDealListNumber
  | SetMapStoreAllStores
  | SetMapStoreCounter
  | SetExploreScrollPosition
  | SetBestDealsScrollPosition
  | SetMenuSideBar
  | SetFlyerTagList
  | SetFlyerTagListNumber
  | SetFlyerTagListPosition
  | SetShowBanner
  | SetRemoteConfig
  | SetLatestFlyerScrollPosition
  | SetLatestFlyer
  | SetLatestFlyerNumber
  | SetUpComingFlyerScrollPosition
  | SetUpComingFlyer
  | SetUpComingFlyerNumber
  | SetFavoriteFlyerList
  | SetFavoriteFlyerPosition
  | SetSearchScrollPosition
  | SetSearchDataList
  | SetSearchDataNumber;
