// Path: ./src/app/components/Spinner/index.tsx
/**
 *
 * Spinner
 *
 */
import React from 'react';
import { styled as stlyedMUI } from '@mui/material/styles';
import spinner from './spinner.gif';

export function Spinner() {
  return (
    <MuiSpinner className={` Spinner`} data-testid="spinner-test-id">
      <img style={{ width: 87, height: 87 }} src={spinner} alt="loading..." />
    </MuiSpinner>
  );
}

const MuiSpinner = stlyedMUI('div')((theme: any) => ({
  display: 'flex',
  height: '100vh',
  padding: 0,
  margin: 0,
  alignItems: 'center',
  justifyContent: 'center',
  '& > * + *': {
    // marginLeft: theme?.spacing(2),
  },
}));
