// Path: ./src/app/pages/FlyerUpComingPage/index.tsx
/**
 *
 * FlyerListPage
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { apiService } from '../../../utils/api';
import { GfFlyerDto } from '@swagger/typescript-fetch-goflyer';
import { Spinner } from 'app/components/Spinner';
import { GoFlyerAppContext } from 'app/store/context';
import {
  checkIfStoreInFavList,
  getMyDistanceToStore,
  instanceOfFlyer,
  getSmallImageIfExist,
  getFlyerStatusString,
} from 'utils/dtoUtility';
import InfiniteScroll from 'react-infinite-scroller';
import { CardFlyerNew } from 'app/components/CardFlyerNew';
import {
  setUpComingFlyerNumber,
  setUpComingFlyer,
  setLocation,
} from 'app/store/reducer';

import { DownloadBanner } from 'app/components/DownloadBanner';
import { Capacitor } from '@capacitor/core';
import * as analytics from '../../../utils/analytics';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { useClickFavMobileFunc } from 'utils/commonFunctions/ClickFavButtonMobile';
import { sortFlyersFunc } from 'utils/sortFlyer';
import { NoResultsReturn } from 'app/components/NoResultsReturn';
import { messages } from 'locales/messages';
import { Helmet } from 'react-helmet-async';
import { Position } from '@capacitor/geolocation';
import { getPosition } from 'utils/getUserLocation';
import CustomHelmet from 'app/components/Helmet';
import { GetUserLocation } from 'utils/LocationCheck';

export const FlyerUpComingPage = memo(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();

  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const { state, dispatch } = React.useContext(GoFlyerAppContext);
  const [showBanner, setShowBanner] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    if (window !== undefined) {
      setTimeout(() => {
        window.scrollTo({ top: state.upComingFlyerScrollPosition });
      }, 1);
    }
    if (Capacitor.isNativePlatform()) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, []);

  const handleRefresh = async () => {
    setRefresh(true);
    await getUpComingFLyers(1, true, true);
  };
  const getUpComingFLyers = async (
    page,
    reFetchPosition = false,
    ifRefresh = false,
  ) => {
    if (reFetchPosition) {
      const position: Position = await GetUserLocation(true);
      console.log(`pull to refresh new location`, position);
      dispatch(setLocation(position));
    }
    setHasMore(false);

    const flyerLimit = 10;

    const upComingFlyer: any = await apiService.gfFlyerControllerFindUpComing(
      page,
      flyerLimit,
      JSON.stringify({
        orderBy: {
          validStartDate: 'ASC',
        },
      }),
      undefined,
    );

    const FlyerResponse: GfFlyerDto[] = sortFlyersFunc(upComingFlyer.results);

    if (ifRefresh) {
      dispatch(
        setUpComingFlyer({
          result: FlyerResponse,
          noMerge: true,
        }),
      );
      dispatch(setUpComingFlyerNumber(1));
      setRefresh(false);
    } else {
      dispatch(
        setUpComingFlyer({
          noMerge: false,
          result: FlyerResponse,
        }),
      );
    }

    if (upComingFlyer.results?.length >= flyerLimit) {
      setHasMore(true);
    }
    setIsLoading(false);
  };

  async function loadFunc(_page) {
    if (!refresh) {
      if (_page === 1) {
        setIsLoading(true);
      }
      dispatch(setUpComingFlyerNumber(_page));
      getUpComingFLyers(_page);
    }
  }

  const clickFavButton = useClickFavMobileFunc(
    analytics.click_fav_button_in_flyer_card,
    true,
  );

  const upComingFlyerList = state.upComingFlyer?.map((upComing, key) => {
    if (instanceOfFlyer(upComing)) {
      const store_in_fav_list = checkIfStoreInFavList(
        upComing.stores[0],
        state.customer.gfStoreFavouriteList,
      );

      return (
        <React.Fragment key={key}>
          <CardFlyerNew
            data-testid="flyer list page card flyer view"
            startingDate={upComing.validStartDate}
            Logo={
              upComing?.stores[0].merchant.merchantMapLargeLogo
                ? upComing?.stores[0].merchant.merchantMapLargeLogo.src
                : upComing?.stores[0].merchant.merchantLogo.src
            }
            distance={getMyDistanceToStore(upComing.stores[0], state.location)}
            signature={upComing.stores[0].signature}
            name={upComing.stores[0].merchant.name}
            description={upComing.stores[0].merchant.description}
            storeFavList={store_in_fav_list}
            LeftImage={getSmallImageIfExist(
              upComing.gfImages?.[0],
              state.remoteConfig.getSmallImagesCondition,
            )}
            RightImage={getSmallImageIfExist(
              upComing.gfImages?.[1],
              state.remoteConfig.getSmallImagesCondition,
            )}
            daysLeft={getFlyerStatusString(upComing)}
            price={upComing.totalSave}
            onClick={e => {
              clickFavButton(
                e,
                upComing.stores[0],
                store_in_fav_list !== undefined,
              );
            }}
            bottomLink={{
              path: `/flyerview/${upComing.id}`,
              state: {
                storeId: upComing.stores[0].id,
              },
            }}
            topLink={{
              path: `/storedetails`,
              search: `storeId=${upComing.stores[0].id}`,
              storeId: `${upComing.stores[0].id}`,
            }}
          />
        </React.Fragment>
      );
    }
  });

  if (upComingFlyerList !== undefined && showBanner === true) {
    for (let i = 1; i < upComingFlyerList.length; i++) {
      if (i % 10 === 0) {
        upComingFlyerList?.splice(i, 0, <DownloadBanner inFlyer={true} />);
      }
    }
  }

  const spinner = <Spinner></Spinner>;
  const NoUpComingFound = (
    <NoResultsReturn
      content={
        <div>
          {t(messages.THERE_ARE_NO_UPCOMING_FLYER_STAY_TUNED())} <br />
          {t(messages.Stay_Tuned())}
        </div>
      }
    ></NoResultsReturn>
  );
  return (
    <>
      <CustomHelmet
        title={`Goflyer - ${t(messages.UpComing())}`}
        image={state.upComingFlyer?.[0]?.gfImages?.[0]?.src || ''}
        description={t(messages.upcomingDescription())}
        keywords={t(messages.upcomingKeywords())}
      />

      <FlyerListContainer id="FlyerListPage">
        <PullToRefresh
          onRefresh={handleRefresh}
          pullingContent={<></>}
          className="PullRefreshCss"
        >
          {!refresh ? (
            <InfiniteScroll
              key={'scrolLkey'}
              css={{
                marginTop: '-95px',
                paddingBottom: '20px',
              }}
              threshold={1000}
              pageStart={state.upComingFlyerNumber}
              loadMore={loadFunc.bind(this)}
              hasMore={hasMore}
              loader={
                <div
                  style={{ textAlign: 'center', paddingBottom: '20px' }}
                  key={0}
                >
                  Loading ...
                </div>
              }
            >
              {isLoading ? (
                spinner
              ) : upComingFlyerList?.length === 0 ? (
                NoUpComingFound
              ) : (
                <FlyerListWrapper>{upComingFlyerList}</FlyerListWrapper>
              )}
            </InfiniteScroll>
          ) : (
            <></>
          )}
        </PullToRefresh>
      </FlyerListContainer>
    </>
  );
});

const FlyerListContainer = styled.div`
  margin-top: 95px;
  margin-bottom: 70px;
  padding-bottom: 92px;
`;
const FlyerListWrapper = styled.div`
  margin-top: 123px;
`;
