// Path: ./src/utils/commonFunctions/ClickFavButtonMobile.ts
import { GfPromotionDto, GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import { GoFlyerAppContext } from 'app/store/context';
import { setCustomer } from 'app/store/reducer';
import React from 'react';
import { apiService } from 'utils/api';
import ReactGA from 'react-ga';
import * as googleTagManager from '../googleTagManager';

export const useClickFavMobileFunc = (analytics: any, passProps?: boolean) => {
  const { state, dispatch } = React.useContext(GoFlyerAppContext);

  const clickFavButton = async (
    e,
    store: GfStoreDto | GfPromotionDto | any,
    showUnlikeIcon,
  ) => {
    try {
      if (store?.merchant?.name) {
        googleTagManager.push(`clickFavButton`, {
          merchant: store.merchant.name,
        });
        ReactGA.event({
          category: '1.2',
          action: `Click Fav Flyer ${store.merchant.name} Button`,
        });
      }

      if (analytics) {
        if (passProps) {
          analytics(store, showUnlikeIcon);
        } else {
          analytics(store, showUnlikeIcon);
        }
      }

      e.preventDefault();
      let new_fav_list;
      if (showUnlikeIcon) {
        const customer = state.customer;
        customer.gfStoreFavouriteList = {
          ...customer.gfStoreFavouriteList,
          stores: customer.gfStoreFavouriteList.stores?.filter(
            val => val.id !== store.id,
          ),
        };
        dispatch(setCustomer(customer));
        new_fav_list =
          await apiService.gfStoreFavouriteListControllerRemoveStore(
            {
              storeId: store.id,
            },
            state.customer.gfStoreFavouriteList.id,
          );
      } else {
        const customer = state.customer;
        customer.gfStoreFavouriteList = {
          ...customer.gfStoreFavouriteList,
          stores: [...(customer.gfStoreFavouriteList.stores ?? []), store],
        };
        dispatch(setCustomer(customer));
        new_fav_list = await apiService.gfStoreFavouriteListControllerAddStore(
          {
            storeId: store.id,
          },
          state.customer.gfStoreFavouriteList.id,
        );
      }
    } catch (error) {
      console.error(error);
      const customer = state.customer;
      customer.gfStoreFavouriteList = {
        ...customer.gfStoreFavouriteList,
        stores: customer.gfStoreFavouriteList.stores?.filter(
          val => val.id !== store.id,
        ),
      };
      dispatch(setCustomer(customer));
    }
  };

  return clickFavButton;
};
