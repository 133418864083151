// Path: ./src/utils/getUserLocation.ts
import { Geolocation, Position } from '@capacitor/geolocation';
import * as analytics from '../utils/analytics';

export const defaultPosition = {
  coords: {
    accuracy: 30,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: 43.8940859,
    longitude: -79.3690824,
    speed: null,
  },
  timestamp: 11111,
};
const getPosition = async function (): Promise<Position> {
  // becuase google don't allow http to get location(need https)

  const isDev = window.config.REACT_APP_IS_DEV === `true`;
  console.log(`isDev`, isDev);

  if (isDev) {
    return defaultPosition;
  }
  try {
    const position = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
    });

    analytics.share_location();
    return position;
  } catch (error) {
    console.error(`get user location error`, error);
    analytics.not_share_location({ error });
    return defaultPosition;
  }
};

export { getPosition };
