// Path: ./src/utils/api.ts
/**
 * This service implements functionality to make api calls through open api generated client
 * We are adding custom axios instance which helps customize the generated client with interceptors and more axios functionalities
 */
import { DefaultApi, GfImageDto } from '@swagger/typescript-fetch-goflyer';
import { DecorateAll } from 'decorate-all';
// Configuration and base path are not provided
import i18next from 'i18next';
import deepForEach from 'deep-for-each';

function replaceS3SrcWithCloudFrontSrc(value: any, subject: any) {
  /**
   * Will migrate to backend later.
   */
  if (
    value &&
    value.src &&
    value.src.indexOf('s3') >= 0 &&
    subject.length > 0
  ) {
    const _subject = subject as GfImageDto[];
    for (let index = 0; index < _subject.length; index++) {
      const element = _subject[index];
      if (
        element &&
        element.src.indexOf(
          'https://goflyer-app-image.s3.ca-central-1.amazonaws.com',
        ) >= 0
      ) {
        element.src = element.src.replace(
          'https://goflyer-app-image.s3.ca-central-1.amazonaws.com',
          window.config.REACT_APP_CLOUD_FRONT_PROD,
        );
      }
      if (
        element &&
        element.srcSmall &&
        element.srcSmall.indexOf(
          'https://goflyer-app-image.s3.ca-central-1.amazonaws.com',
        ) >= 0
      ) {
        element.srcSmall = element.srcSmall.replace(
          'https://goflyer-app-image.s3.ca-central-1.amazonaws.com',
          window.config.REACT_APP_CLOUD_FRONT_PROD,
        );
      }
      if (
        element &&
        element.src.indexOf(
          'https://goflyer-app-image-test.s3.ca-central-1.amazonaws.com',
        ) >= 0
      ) {
        element.src = element.src.replace(
          'https://goflyer-app-image-test.s3.ca-central-1.amazonaws.com',
          window.config.REACT_APP_CLOUD_FRONT_TEST,
        );
      }
      if (
        element &&
        element.srcSmall &&
        element.srcSmall.indexOf(
          'https://goflyer-app-image-test.s3.ca-central-1.amazonaws.com',
        ) >= 0
      ) {
        element.srcSmall = element.srcSmall.replace(
          'https://goflyer-app-image-test.s3.ca-central-1.amazonaws.com',
          window.config.REACT_APP_CLOUD_FRONT_TEST,
        );
      }
    }
  }
}

const Uppercase = (
  target: any,
  propertyKey: any,
  descriptor?: any | undefined,
) => {
  if (descriptor) {
    const original = descriptor.value;
    descriptor.value = async function (...args) {
      try {
        const result = await original.apply(this, args);
        deepForEach(result, (value, key, subject) => {
          if (i18next.language === 'zh' && key === 'nameChinese') {
            subject.name =
              subject.nameChinese?.length > 0
                ? subject.nameChinese
                : subject.name;
          }
          replaceS3SrcWithCloudFrontSrc(value, subject);
        });
        return result;
      } catch (error: any) {
        console.error(error);
        if (
          error &&
          error.toString().indexOf('Unexpected end of JSON input') >= 0
        ) {
          /**
           * don't use this window.location.href to redirect to 404.
           * it is because when window.location.href, it will reload the page. If error happen during reload, it will come here again and it will keep reloading
           */
          // window.location.href = '/404';
        }
      }
    };
  }
};

@DecorateAll(Uppercase, { deep: true })
class Hello extends DefaultApi {}

let config_response;
const apiService = new Hello(
  undefined,
  window.config.REACT_APP_GOFLYER_API_END_POINT,
);
export { apiService, config_response };
