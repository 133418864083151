// Path: ./src/app/components/Common/FlyerPage.tsx
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FlyerPageContainer = styled.div`
  color: black;
  height: 100%;
  overflow: hidden;
`;

export const FlyerPageFlyerWrapper = styled.div`
  width: 100%;

  padding-top: 40px;
  height: 100%;
  overflow-y: scroll !important;
`;

export const FlyerPageFlyerLink = styled(Link)`
  text-decoration: none;
  outline: none;
  color: inherit;
`;
export const FlyerPageNotFoundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
`;
