// Path: ./src/utils/LocationCheck.ts
import { Position } from '@capacitor/geolocation';
import { defaultPosition, getPosition } from 'utils/getUserLocation';
import { getCityCoords } from './getCityNameFromIP';

/**
 * Retrieves the user's location from local storage if available or fetches the current position
 * using geolocation. Supports pull-to-refresh functionality to optionally refresh the location.
 * If the position is the default, updates it based on the IP address when not in development mode.
 *
 * @param {boolean} refreshLocation - Indicates whether to refresh the location regardless of stored data, typically used for pull-to-refresh. Defaults to false.
 * @returns {Promise<Position>} - The user's current or refreshed geolocation position.
 */
export const GetUserLocation = async function (
  refreshLocation: boolean = false,
) {
  const storedLocation = localStorage.getItem('CustomerLocation');
  let position: Position;
  const isDev = window.config.REACT_APP_IS_DEV === `true`;

  if (storedLocation && !refreshLocation) {
    position = JSON.parse(storedLocation);
  } else {
    // Get current position if not stored
    position = await getPosition();
    updateStoredLocation(position);
  }

  if (isDefaultPosition(position) && !isDev) {
    // Retrieve updated location based on IP if in default position
    position = await updatePositionFromIP(position);
    updateStoredLocation(position);
  }

  return position;
};

/**
 * Updates the location data in local storage with the given position.
 * The coordinates are formatted to 3 decimal places to minimize minor changes.
 *
 * @param {Position} position - The position object containing updated longitude and latitude.
 */
function updateStoredLocation(position: Position) {
  const { longitude, latitude } = position.coords;
  const storedLocation = JSON.stringify({
    coords: {
      longitude: longitude?.changeDecimal(3),
      latitude: latitude?.changeDecimal(3),
    },
  });
  localStorage.setItem('CustomerLocation', storedLocation);
}

/**
 * Checks if the given position matches the application's default geolocation position.
 * Used to determine if the user's location should be updated via IP lookup.
 *
 * @param {Position} position - The position object to check.
 * @returns {boolean} - True if the position matches the default; false otherwise.
 */
export function isDefaultPosition(position: Position): boolean {
  return (
    position?.coords?.longitude?.toFixed(3) ===
      defaultPosition?.coords?.longitude?.toFixed(3) &&
    position?.coords?.latitude?.toFixed(3) ===
      defaultPosition?.coords?.latitude?.toFixed(3)
  );
}
/**
 * Updates the user's position based on their IP address. Retrieves IP address,
 * then fetches location data based on it, updating the current position with these coordinates.
 *
 * @param {Position} currentPosition - The current geolocation position of the user.
 * @returns {Promise<Position>} - The updated position with IP-based location data if available.
 */
async function updatePositionFromIP(
  currentPosition: Position,
): Promise<Position> {
  const coords = await getCityCoords();
  if (coords) {
    return {
      ...currentPosition,
      coords: {
        ...currentPosition.coords,
        ...coords,
      },
    };
  }
  return currentPosition; // Return unchanged if no new data is fetched
}

/**
 * Checks if the user's location has changed by comparing the current geolocation
 * with stored data. If a significant change is detected, the updated location
 * is saved and the page reloads to reflect changes. If no location is stored,
 * initializes storage with the current position.
 *
 * Execution Limit:
 * - The function can be executed a maximum of 3 times within a 30-minutes window.
 * - Execution counts are tracked using localStorage.
 */
export const CheckUserLocationChange = async () => {
  try {
    const EXECUTION_LIMIT = 3;
    const EXECUTION_DATA_KEY = 'CheckUserLocationExecutionData';

    const now = new Date();
    const currentBlock =
      now.toISOString().slice(0, 13) +
      '-' +
      (now.getMinutes() < 30 ? '00' : '30');

    const executionDataStr = localStorage.getItem(EXECUTION_DATA_KEY);
    let executionData: { block: string; count: number } = {
      block: currentBlock,
      count: 0,
    };

    if (executionDataStr) {
      try {
        executionData = JSON.parse(executionDataStr);
        if (executionData.block !== currentBlock) {
          executionData = { block: currentBlock, count: 0 };
        }
      } catch (error) {
        console.error('Error parsing execution data from localStorage:', error);
        executionData = { block: currentBlock, count: 0 };
      }
    }

    if (executionData.count >= EXECUTION_LIMIT) {
      console.warn(
        'Execution limit reached for this 30-minute block (Check Location Change).',
      );
      return;
    }

    executionData.count += 1;
    localStorage.setItem(EXECUTION_DATA_KEY, JSON.stringify(executionData));

    const savedLocation = localStorage.getItem('CustomerLocation');
    const storedPosition: Position | null = savedLocation
      ? JSON.parse(savedLocation)
      : null;
    const currentPosition = await GetUserLocation(true);

    const hasLocationChanged =
      storedPosition &&
      (Number(currentPosition.coords.longitude).toFixed(3) !==
        Number(storedPosition.coords.longitude).toFixed(3) ||
        Number(currentPosition.coords.latitude).toFixed(3) !==
          Number(storedPosition.coords.latitude).toFixed(3));

    if (hasLocationChanged) {
      updateStoredLocation(currentPosition);
      console.warn(
        'Location change detected. Reloading page to reflect updated location data.',
        { previous: storedPosition, current: currentPosition },
      );
      window.location.reload();
    } else if (!storedPosition) {
      updateStoredLocation(currentPosition);
    }
  } catch (error) {
    console.error(
      'An error occurred while checking for location changes:',
      error,
    );
  }
};
