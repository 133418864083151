// Path: ./src/app/components/NewPromotionDetailStoreComp/index.tsx
/**
 *
 * NewPromotionDetailStoreComp
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { styled as stlyedMUI } from '@mui/material/styles';

import { GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import { MapStatic } from '../MapStatic';
import { Box, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Link } from 'react-router-dom';

interface Props {
  storeInfavlist?: GfStoreDto | undefined;
  mapCenter?: {
    lat: number;
    lng: number;
  };
  onClickFavStoreButtonFnc?: any;
  store: GfStoreDto;
  merchantLogo?: string;
  merchantName?: string;
  merchantDescription?: string;
  numberOfDealsText?: string;
}

export const NewPromotionDetailStoreComp: React.FunctionComponent<Props> = memo(
  props => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t, i18n } = useTranslation();

    return (
      <CardDiv>
        <div>
          <Box className={`gfNewPromotionDetailStoreComp`}>
            <a
              href={
                props.store.googleMapUrl ? props.store.googleMapUrl : undefined
              }
              target={props.store.googleMapUrl ? '_blank' : undefined}
              rel="noreferrer"
            >
              <MapStatic
                store={props.store}
                center={props.mapCenter}
              ></MapStatic>
            </a>
          </Box>
        </div>
        <Link
          data-testid="Store Detal Link"
          to={`/storedetails/${props.store.id}`}
        >
          <BottomDiv>
            <LeftDiv>
              <Img src={props.merchantLogo} alt={props.merchantName} />
              <SubLeftDiv>
                <Heading
                  style={{
                    whiteSpace: `nowrap`,
                    overflow: `hidden`,
                    textOverflow: `ellipsis`,
                    maxWidth: 201,
                    height: 36,
                    marginTop: -13,
                  }}
                >
                  {props.merchantName}
                </Heading>
                {props.merchantDescription && (
                  <>
                    <SubText>{props.merchantDescription}</SubText>
                    <br />
                  </>
                )}

                <DealsText>{props.numberOfDealsText}</DealsText>
              </SubLeftDiv>
            </LeftDiv>
            <div>
              <IconButton
                data-testid="new promotion detail store comp fav store button"
                onClick={props.onClickFavStoreButtonFnc}
              >
                {props.storeInfavlist === undefined ? (
                  <MuiFavoriteBorderIcon />
                ) : (
                  <MuiFavoriteIcon />
                )}
              </IconButton>
            </div>
          </BottomDiv>
        </Link>
      </CardDiv>
    );
  },
);

const CardDiv: any = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000040;
  border-radius: 20px;
  opacity: 1;
  width: 356px;
  height: 368px;
  margin-left: 13px;
  margin-right: 13px;
  margin-top: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Img = styled.img`
  height: 601x !important;
  width: 60px !important;
  border-radius: 27px !important;
`;
const BottomDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  padding-left: 13px;
  padding-right: 13px;
  // padding-top: 8px;
`;
const LeftDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;
const SubLeftDiv = styled.div`
  margin-left: 8px;
`;
const Heading = styled.h4`
  margin: 0px;
  text-align: left;
  font: normal normal 600 22px/41px SFProDisplay;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;
const SubText = styled.span`
  margin-top: -8px;
  text-align: left;
  font: normal normal normal 16px/19px SFProText;
  letter-spacing: 0px;
  color: #8a898e;
  opacity: 1;
`;

const DealsText = styled.span`
  text-align: left;
  font: normal normal 600 13px/15px SF Pro;
  letter-spacing: 0px;
  color: #ff9500;
  opacity: 1;
`;

const MuiFavoriteBorderIcon = stlyedMUI(FavoriteBorderIcon)(() => ({
  color: ' #999999 ',
  fontSize: '28px',
}));
const MuiFavoriteIcon = stlyedMUI(FavoriteIcon)(() => ({
  color: '#FF453A',
  fontSize: '28px',
}));
