// Path: ./src/app/pages/MapStorePage/Loadable.tsx
/**
 * Asynchronously loads the component for MapStorePage
 */

import { lazyLoad } from 'utils/loadable';

export const MapStorePage = lazyLoad(
  () => import('./index'),
  module => module.MapStorePage,
);
