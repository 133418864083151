// Path: ./src/app/desktop-src/store/CommonSlice/index.ts
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { CommonStateProps } from './types';
export const initialState: CommonStateProps = {
  prevLocation: '',
};

const slice = createSlice({
  name: 'commonData',
  initialState,
  reducers: {
    updatePrevLocationState(state, action: PayloadAction<any>) {
      state.prevLocation = action.payload;
    },
  },
});

export const { actions: updatePrevLocationState } = slice;
export const UserReducer = slice.reducer;
export const useCommonDataSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer as any });

  return { actions: slice.actions };
};
