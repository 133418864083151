// Path: ./src/app/desktop-src/app/pages/InfoPage/MobileNav.tsx
// @ts-nocheck
import { LanguageSwitcher } from 'app/mobile-desktop-common-components/LanguageSwitcher';
import { useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import { t } from 'i18next';
import { messages } from 'locales/messages';
import { useHistory } from 'react-router-dom';

interface Props {
  open: any;
  toggle: any;
}
const MobileNav = ({ open, toggle }: Props) => {
  const history = useHistory();

  const headings = [
    {
      text: t(messages.InfoStores()),
      Link: 'FourthInfoContent',
    },
    {
      text: t(messages.Reviews()),
      Link: 'SixthInfoContent',
    },
    {
      text: t(messages.Mobile_APP()),
      Link: 'BottomInfoContent',
    },
    {
      text: t(messages.Flyer_Deals()),
      Link: 'SecondInfoContent',
    },
  ];
  const handleFlyer = (value: string) => {
    toggle(false);
    if (history.location.pathname === '/') {
      const element: any = document?.getElementById(value);
      const pos = element?.offsetTop;
      window?.scrollTo({
        top: pos - 110,
        left: 0,
        behavior: 'auto',
      });
    } else {
      history.push('/');

      setTimeout(() => {
        const element: any = document?.getElementById(value);
        const pos = element?.offsetTop;
        window?.scrollTo({
          top: pos - 110,
          left: 0,
          behavior: 'auto',
        });
      }, 500);
    }
  };

  const Animated: any = animated;
  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
      return;
    }
    document.body.style.overflowY = 'auto';
  }, [open]);

  const transition: any = useTransition(open, {
    from: {
      opacity: 0,
      transformMain: 'translateY(40px)',
      transformFoot: 'translateY(200px)',
    },
    enter: {
      opacity: 1,
      transformMain: 'translateY(0px)',
      transformFoot: 'translateY(0px)',
    },
    leave: {
      opacity: 0,
      transformMain: 'translateY(40px)',
      transformFoot: 'translateY(200px)',
    },
  });

  return (
    <>
      {transition(({ opacity, transformMain, transformFoot }, visible) => {
        return visible ? (
          <Animated.nav style={{ opacity }} className="mobile-nav">
            <div className="content-wrapper">
              <Animated.div
                className="icon-wrapper"
                style={{ transform: transformFoot }}
              >
                <LanguageSwitcher />
              </Animated.div>
              <Animated.ul
                style={{ transform: transformMain }}
                className="list"
              >
                {headings.map(heading => (
                  <li
                    to={heading.Link}
                    className="list-item"
                    key={heading.text}
                    onClick={() => {
                      handleFlyer(heading.Link);
                    }}
                  >
                    {heading.text}
                  </li>
                ))}
              </Animated.ul>
              <Animated.div
                className="icon-wrapper"
                style={{ transform: transformFoot }}
              ></Animated.div>
            </div>
          </Animated.nav>
        ) : null;
      })}
    </>
  );
};

export default MobileNav;
