// Path: ./src/app/components/ImageLoader/index.tsx
import React, { useState, useRef } from 'react';
import { Spinner } from '../Spinner';
import { useIntersection } from './SectionObserver';
import './style.css';

type ImageRendererProps = {
  url: string | undefined;
  width: string;
  height: string;
  contain: boolean;
  startingDate: Date;
};
const ImageRenderer = ({
  url,
  width,
  height,
  contain,
  startingDate,
}: ImageRendererProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const imgRef: any = useRef();
  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };
  return (
    <div
      className="image-container"
      ref={imgRef}
      style={{
        height,
        width,
      }}
      data-testid="imagerenderer-test-id"
    >
      {isInView ? (
        <img
          alt={url}
          // crossOrigin="anonymous" //disable this due to image from promobiz-photos bucket is not showing
          className={`image ${isLoaded ? 'isLoaded' : ''}  ${
            contain ? 'imageContain' : 'ImagCover'
          }`}
          src={url + '?startingDate=' + startingDate}
          onLoad={handleOnLoad}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ImageRenderer;
