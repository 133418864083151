// Path: ./src/utils/googleTagManager.ts
import TagManager from 'react-gtm-module';
/**
 * @deprecated please don't use google tag manager
 */
const init = function (): void {
  const tagManagerArgs = {
    gtmId: 'GTM-TJFD4QG',
  };

  TagManager.initialize(tagManagerArgs);
};

const push = function (event: string, data: any): void {
  const tagManagerArgs2 = {
    dataLayer: {
      event,
      platform: 'mobile',
      ...data,
    },
  };
  TagManager.dataLayer(tagManagerArgs2);
};

export { init, push };
