import { ToastOptions, toast } from 'react-toastify';

export const ToastConfiguration: ToastOptions = {
  position: 'bottom-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

export const toastError = (message: string) => {
  toast.error(message, ToastConfiguration);
};

export const toastSuccess = (message: string) => {
  toast.success(message, ToastConfiguration);
};

export const toastWarn = (message: string) => {
  toast.warn(message);
};
