// Path: ./src/app/components/TextRoundWithBackground/index.tsx
/**
 *
 * TextRoundWithBackground
 *
 */
import { Avatar, Box } from '@mui/material';
import React, { memo } from 'react';

interface Props {}

export const TextRoundWithBackground: React.FunctionComponent<Props> = memo(
  props => {
    return (
      <Box>
        <Avatar style={{ background: '#FFEACC 0% 0%' }}>
          {props.children}
        </Avatar>
      </Box>
    );
  },
);
