// Path: ./src/app/components/ModalSubscription/index.tsx
/**
 *
 * ModalSubscription
 *
 */
// @ts-ignore
import {
  Box,
  Button,
  CardMedia,
  Modal,
  Snackbar,
  Typography,
} from '@mui/material';
import { styled as stlyedMUI } from '@mui/material/styles';

import { GfPromotionDto } from '@swagger/typescript-fetch-goflyer';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { apiService } from 'utils/api';
import { TextInputPhoneNumberOrEmail } from '../TextInputPhoneNumberOrEmail';
import { TextRoundWithBackground } from '../TextRoundWithBackground';
import { TextWithBackgroundColor } from '../TextWithBackgroundColor';
import styled from 'styled-components/macro';
import { GoFlyerAppContext } from 'app/store/context';
import MuiAlert from '@mui/lab/Alert';
import { getPromotionDiscount } from 'utils/dtoUtility';
import { messages } from 'locales/messages';
import ReactGA from 'react-ga';
import Checkbox from './CheckBox';
import { localstorageGet, localstorageSet } from 'utils/localstorage';
import * as analytics from '../../../utils/analytics';
import { GetUserLocation, isDefaultPosition } from 'utils/LocationCheck';
interface Props {}
function isMoreThan30DaysAgo(date: number) {
  const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
  const timestampThirtyDaysAgo = new Date().getTime() - thirtyDaysInMs;
  if (timestampThirtyDaysAgo > date) {
    return true;
  } else {
    return false;
  }
}

// export function ModalSubscription(props) {
export const ModalSubscription: React.FunctionComponent<Props> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const { state } = React.useContext(GoFlyerAppContext);

  const [openModal, setOpenModal] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const [email, setEmail] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [checkMonth, setCheckMonth] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState(false);
  const [checkEmail, setCheckEmail] = React.useState(false);

  const [promotions, setPromotions] = React.useState<
    GfPromotionDto[] | undefined
  >([]);

  React.useEffect(() => {
    const data = localstorageGet('emailPopup');

    if (isMoreThan30DaysAgo(data)) {
      getPromotions();
      checkCustomerPhoneNumber();
    } else {
      setOpenModal(false);
    }
  }, []);
  const getPromotions = async () => {
    const response = await apiService.gfPromotionControllerFindAll(
      undefined,
      3,
    );
    setPromotions(response.results);
  };
  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const checkCustomerPhoneNumber = async () => {
    const customerInfo: any = localStorage.getItem('customer');
    const customer = JSON.parse(customerInfo);
    const customerResponse = await apiService.gfCustomerControllerFindOne(
      customer.id,
    );
    if (
      customerResponse &&
      customerResponse.email &&
      customerResponse.email.length !== 0
    ) {
      setCheckEmail(true);
    } else {
      setOpenModal(true);
    }
  };
  const handleModalClose = () => {
    analytics.click_close_modal();
    if (checkMonth) {
      const date = new Date();
      localstorageSet('emailPopup', date.getTime());
    }
    setOpenModal(false);
  };
  const handleSnackBarClose = () => {
    setOpenSnackBar(false);
  };
  const onClickSubscribeButton = async () => {
    analytics.click_subscription_button();
    ReactGA.event({
      category: '1.2',
      action: 'Click Subscribe Button',
    });
    if (email === '') {
      setShowError(true);
      setErrorMessage('Invalid Email');
      return;
    } else if (validateEmail(email)) {
      try {
        const position = await GetUserLocation();
        const response = await apiService.gfCustomerControllerUpdate(
          { email },
          state.customer.id,
          !isDefaultPosition(position) ? position.coords.longitude : undefined,
          !isDefaultPosition(position) ? position.coords.latitude : undefined,
        );
        if (response.message === 'Email Already Exits') {
          analytics.error_subscription_email_already_exist();
          setShowError(true);
          setErrorMessage('Email Already Exits');
        } else {
          analytics.success_subscription_email();
          setOpenModal(false);
          // TODO: need a way to handle error
          setOpenSnackBar(true);
          //
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      analytics.error_subscription_invalid_email();
      setShowError(true);
      setErrorMessage('Invalid Email Please Enter Correct Email');
    }
  };
  const PromotionList = promotions?.map(promotion => {
    return (
      <Box
        key={promotion.id}
        style={{ position: 'relative' }}
        data-testid="modalsubscription-test-id"
      >
        {promotion?.placement?.price && (
          <Box
            style={{
              position: 'absolute',
              right: -10,
              top: -20,
              boxShadow: '0px 3px 6px #00000029',
              height: '39px',
              width: '39px',
              borderRadius: '20px',
            }}
          >
            <TextRoundWithBackground>
              <MuiTypography style={{ fontSize: 13 }} color="primary">
                {getPromotionDiscount(promotion)}
              </MuiTypography>
              <MuiTypography style={{ fontSize: 9 }} color="primary">
                %
              </MuiTypography>
            </TextRoundWithBackground>
          </Box>
        )}
        <CardMedia
          style={{
            width: 97,
            height: 97,
            borderRadius: 10,
            border: '1px solid #BDBDC3',
            objectFit: 'contain',
          }}
          component="img"
          alt={promotion.placement.product.name}
          image={promotion.placement.product.gfImages?.[0]?.src}
        />
      </Box>
    );
  });
  if (checkEmail) {
    return <></>;
  }
  return (
    <Box className="ModalSubscription" data-testid="modalsubscription-test-id">
      <Modal
        onClose={handleModalClose}
        open={openModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: 'auto',
            backgroundColor: 'white',
            width: 343,
            borderRadius: 25,
            padding: 15,
            border: '0px',
            outline: 'none',
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <TextWithBackgroundColor
                content="2"
                width={28}
              ></TextWithBackgroundColor>
              <Box ml="3px">
                <TextWithBackgroundColor
                  content="8"
                  width={28}
                ></TextWithBackgroundColor>
              </Box>
              <Box ml="9px">
                <MuiTypography color="primary">
                  {t(messages.Spots_Left())}
                </MuiTypography>
              </Box>
            </Box>
            <Box>
              <ModalCloseButton
                data-testid="model subscription handle close "
                onClick={handleModalClose}
              >
                X
              </ModalCloseButton>
            </Box>
          </Box>
          <Box>
            {/* TODO: this font size is not correct */}
            <Typography style={{ fontSize: 30 }}>
              {t(messages.Get_Exclusive_Deals())}!
            </Typography>
            <Typography style={{ fontSize: 16 }}>
              {t(
                messages.Receive_the_best_promotions_available_right_within_your_SMS_inbox(),
              )}
              .
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt={'20px'}
          >
            {PromotionList}
          </Box>
          <Box mt="12px">
            <TextInputPhoneNumberOrEmail
              error={showError}
              helperText={errorMessage}
              value={email}
              setNumber={e => setEmail(e.target.value)}
            ></TextInputPhoneNumberOrEmail>
            <CheckBoxDiv>
              <Checkbox
                id="check1"
                checked={checkMonth}
                onChange={() => {
                  setCheckMonth(!checkMonth);
                }}
              />
              <CheckBoxLabel>Don't Show this again for 30 days</CheckBoxLabel>
            </CheckBoxDiv>
            <MuiButton
              data-testid="Subscribe"
              onClick={() => onClickSubscribeButton()}
              className={` subscribeButton`}
              fullWidth
              variant="contained"
              color="primary"
            >
              {t(messages.Subscribe())}
            </MuiButton>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      >
        <MuiAlert
          data-testid="Thanks for subscribing"
          style={{ width: '100%' }}
          elevation={6}
          variant="filled"
        >
          {t(messages.Thanks_for_subscribing())}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const ModalCloseButton = styled.div`
  color: white;
  font: normal bold 15px/26px SFProText;
  background-color: #ff9500;
  width: 28px;
  text-align: center;
  border-radius: 8px;
  height: 25px;
  margin-top: -11px;
  margin-right: -2px;
`;
const CheckBoxLabel = styled.span`
  color: gray;
  margin-top: 1px;
  margin-left: 5px;
`;
const CheckBoxDiv = styled.div`
  padding-left: 1px;
  margin-top: 10px;
`;

const MuiButton = stlyedMUI(Button)(() => ({
  background: '#FF9500 0% 0%',
  borderRadius: 8,
  height: 50,
  color: 'white',
  marginTop: 30,
  fontFamily: ' SFProText',
}));
const MuiTypography = stlyedMUI(Typography)(() => ({
  font: 'normal medium SFProText',
  fontSize: '18px',
  color: '#FF9500',
}));
