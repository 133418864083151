// Path: ./src/app/components/MapA/index.tsx
// doc: https://github.com/JustFly1984/react-google-maps-api/tree/master/packages/react-google-maps-api
import { useEffect, useState } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import UnSelectedPin from '../../assets/Pin.png';
import SelectedPin from '../../assets/SelectedPin.png';
import * as analytics from '../../../utils/analytics';
import React from 'react';
const containerStyle = {
  width: '100%',
  height: '100%',
};

interface Props {
  stores?: GfStoreDto[];
  center?: any;
  useSelectedMarker?: boolean;
  storeId?: string;
  storeDetails?: boolean;
  onClickMarker?: (e: google.maps.MapMouseEvent, store: GfStoreDto) => void;
  onDragEnd?: (
    newCenter: google.maps.LatLngLiteral | undefined,
    range: number,
  ) => void;
  onZoomChanged?: (
    newCenter: google.maps.LatLngLiteral | undefined,
    range: number,
  ) => void;
}
export function MapA(props: Props) {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState<any>();
  const [zoom, setZoom] = useState<number | undefined>(13);
  const [selectedMarker, setSelectedMarker] = useState<string | undefined>(
    props.storeId,
  );

  // Function to update the center state
  const onDragEnd = () => {
    if (map) {
      const newCenter = map.getCenter()?.toJSON();
      setCenter(newCenter);
      const newRange = calculateRange(map);
      props.onDragEnd && props.onDragEnd(newCenter, newRange);
    }

    // You can also perform other actions here if needed
  };

  // Function to update the zoom state and calculate the range
  const onZoomChanged = () => {
    if (map) {
      const newZoom = map.getZoom();
      setZoom(newZoom);
      // Calculate the range (radius from center to edge) based on the new zoom level
      const range = calculateRange(map);
      props.onZoomChanged && props.onZoomChanged(center, range);
      // Perform actions with the range value
    }
  };

  // Function to calculate the range of the map
  const calculateRange = map => {
    const bounds = map.getBounds();

    if (bounds) {
      // Get the North East and South West points of the bounds
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      // Calculate distance between the center and one of the bounds points
      const radius =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng(sw.lat(), sw.lng()),
          new window.google.maps.LatLng(ne.lat(), ne.lng()),
        );
      console.log(`radius`, radius / 1000);

      return radius / 1000; // radius in kilometers
    }
    return 0;
  };

  useEffect(() => {
    if (props.storeDetails && props.stores) {
      setCenter({
        lat: props?.stores[0].location[`coordinates`][1],
        lng: props?.stores[0].location[`coordinates`][0],
      });
    } else {
      const _center = {
        lat: 43.8916834,
        lng: -79.3751358,
      };
      setCenter(_center);
    }
    setSelectedMarker(props.storeId);
  }, [props.storeId, props.stores]);
  const MarkerList = props.stores?.map(store => (
    <Marker
      data-testid="map A click map marker"
      onClick={e => {
        analytics.click_map_marker(store);
        props.onClickMarker && props.onClickMarker(e, store);
        setSelectedMarker(store.id);
      }}
      key={store.id}
      icon={
        props.useSelectedMarker
          ? store.merchant.merchantMapLargeLogo
            ? store.merchant.merchantMapLargeLogo.src
            : SelectedPin
          : selectedMarker === store.id
          ? store.merchant.merchantMapLargeLogo
            ? store.merchant.merchantMapLargeLogo.src
            : SelectedPin
          : store.merchant.merchantMapSmallLogo
          ? store.merchant.merchantMapSmallLogo.src
          : UnSelectedPin
      }
      position={{
        lat: store.location[`coordinates`][1],
        lng: store.location[`coordinates`][0],
      }}
    ></Marker>
  ));
  if (props.center) {
    MarkerList?.push(
      <Marker
        key={1}
        position={{
          lat: props.center.lat,
          lng: props.center.lng,
        }}
      ></Marker>,
    );
  }

  const defaultMapOptions = {
    disableDefaultUI: true,
  };

  return (
    <LoadScript
      googleMapsApiKey={
        window.config.REACT_APP_GOFLYER_GOOGLE_MAP_API_KEY as string
      }
      // Add this line
      libraries={['geometry']}
      data-testid="mapa-test-id"
    >
      <GoogleMap
        onLoad={map => {
          setMap(map);
        }}
        mapContainerClassName="MapA"
        options={defaultMapOptions}
        mapContainerStyle={containerStyle}
        center={
          props.center ? (props.storeDetails ? center : props.center) : center
        }
        zoom={zoom}
        onDragEnd={onDragEnd}
        onZoomChanged={onZoomChanged}
      >
        {MarkerList}
      </GoogleMap>
    </LoadScript>
  );
}
