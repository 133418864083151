// Path: ./src/app/desktop-src/app/pages/FlyerRestuarantDetailPage/Loadable.ts
/**
 *
 * Asynchronously loads the component for FlyerRestuarantDetailPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const FlyerRestuarantDetailPage = lazyLoad(
  () => import('./index'),
  module => module.FlyerRestuarantDetailPage,
);
