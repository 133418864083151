import { styled } from '@mui/system';
import { Box, Modal, IconButton } from '@mui/material';

export const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ModalBox = styled(Box)({
  backgroundColor: '#fff',
  padding: '32px',
  borderRadius: '8px',
  boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
  width: '100%',
  maxWidth: '400px',
  textAlign: 'center',
  position: 'relative',
});

export const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '8px',
  right: '8px',
});
