import { getCookieValue, setCookie } from 'utils/cookieUtil';

interface Coordinates {
  latitude: number;
  longitude: number;
}

/**
 * Performs a fetch request with an AbortController to handle timeouts.
 * @param {string} resource - The resource URL.
 * @param {RequestInit} [options={}] - The fetch options.
 * @returns {Promise<Response>} - A Promise that resolves with the fetch response.
 * @throws Will throw an error if the fetch fails or times out.
 */
async function fetchWithAbortController(
  resource: string,
  options: RequestInit = {},
): Promise<Response> {
  const timeout = 20000;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  try {
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response;
  } catch (error) {
    clearTimeout(id);
    throw error;
  }
}

/**
 * Retrieves the user's IP address from an external service.
 * @returns {Promise<string | undefined>} - A Promise that resolves with the IP address if successful, or `undefined` if there is an error.
 */
export async function getIpAddress(): Promise<string | undefined> {
  try {
    const response = await fetchWithAbortController(
      'https://api.ipify.org?format=json',
    );
    if (!response.ok) {
      throw new Error('Failed to fetch IP address');
    }
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return undefined;
  }
}

/**
 * Hashes the IP address using SHA-256.
 * @param {string} ip - The IP address to hash.
 * @returns {Promise<string>} - A Promise that resolves with the hashed IP address.
 */
async function hashIpAddress(ip: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(ip);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

/**
 * Retrieves city coordinates based on the provided IP address, with API usage limits and error handling.
 * @param {string} ip - The IP address to use for location lookup.
 * @returns {Promise<Coordinates | undefined>} - A Promise that resolves with the coordinates if successful, or `undefined` if there is an error or throttle limit is reached.
 *
 * Throttle and Failure Handling:
 * - Limits API calls to a maximum of 5 per 30 minutes.
 * - Suspends API calls for 3 hours if there are more than 3 consecutive failures.
 * - Resets throttle and failure data if a different IP is detected.
 */
export async function getCityNameFromIp(
  ip: string,
): Promise<Coordinates | undefined> {
  const ipHash = await hashIpAddress(ip);

  const THROTTLE_COOKIE_NAME = 'ipapiThrottle';
  const FAILURE_COOKIE_NAME = 'ipapiFailures';
  const MAX_CALLS_PER_WINDOW = 5;
  const THROTTLE_WINDOW_MS = 30 * 60 * 1000; // 30 minutes
  const MAX_FAILURES = 3;
  const FAILURE_SNOOZE_MS = 3 * 60 * 60 * 1000; // 3 hours

  const now = Date.now();

  let throttleData: {
    ipHash: string;
    startTime: number;
    count: number;
  } = {
    ipHash,
    startTime: now,
    count: 0,
  };

  let failureData: {
    ipHash: string;
    failureCount: number;
    lastFailureTime: number;
  } = {
    ipHash,
    failureCount: 0,
    lastFailureTime: 0,
  };

  const throttleDataStr = getCookieValue(THROTTLE_COOKIE_NAME);
  if (throttleDataStr) {
    try {
      const storedThrottleData = JSON.parse(throttleDataStr);

      // If IP hash matches, use stored data
      if (storedThrottleData.ipHash === ipHash) {
        throttleData = storedThrottleData;

        if (now - throttleData.startTime > THROTTLE_WINDOW_MS) {
          throttleData.startTime = now;
          throttleData.count = 0;
        }
      } else {
        // Different IP, reset throttle data
        throttleData = { ipHash, startTime: now, count: 0 };
      }
    } catch (error) {
      console.error('Error parsing throttle cookie:', error);
    }
  }

  if (throttleData.count >= MAX_CALLS_PER_WINDOW) {
    console.warn('API call limit reached for this time window.');
    return undefined;
  }

  // Read failure data from cookie
  const failureDataStr = getCookieValue(FAILURE_COOKIE_NAME);
  if (failureDataStr) {
    try {
      const storedFailureData = JSON.parse(failureDataStr);

      // If IP hash matches, use stored data
      if (storedFailureData.ipHash === ipHash) {
        failureData = storedFailureData;
        const timeSinceLastFailure = now - failureData.lastFailureTime;

        if (
          failureData.failureCount >= MAX_FAILURES &&
          timeSinceLastFailure < FAILURE_SNOOZE_MS
        ) {
          console.warn(
            'getCityName API calls are snoozed due to repeated failures(too many requests).',
          );
          return undefined;
        } else if (timeSinceLastFailure >= FAILURE_SNOOZE_MS) {
          failureData.failureCount = 0;
          failureData.lastFailureTime = 0;
        }
      } else {
        // Different IP, reset failure data
        failureData = { ipHash, failureCount: 0, lastFailureTime: 0 };
      }
    } catch (error) {
      console.error('Error parsing failure cookie:', error);
    }
  }

  try {
    const response = await fetchWithAbortController(
      `https://ipapi.co/${ip}/json/`,
      {
        mode: 'no-cors',
      },
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch data for IP: ${ip}`);
    }
    const result = await response.json();
    const coords =
      result && result.latitude && result.longitude
        ? { latitude: result.latitude, longitude: result.longitude }
        : undefined;

    if (coords) {
      failureData.failureCount = 0;
      failureData.lastFailureTime = 0;
      failureData.ipHash = ipHash;
      setCookie(
        FAILURE_COOKIE_NAME,
        JSON.stringify(failureData),
        FAILURE_SNOOZE_MS,
      );
    }

    throttleData.count += 1;
    throttleData.ipHash = ipHash;
    setCookie(
      THROTTLE_COOKIE_NAME,
      JSON.stringify(throttleData),
      THROTTLE_WINDOW_MS,
    );

    return coords;
  } catch (error) {
    console.error('Error fetching city name:', error);

    failureData.failureCount += 1;
    failureData.lastFailureTime = now;
    failureData.ipHash = ipHash;
    setCookie(
      FAILURE_COOKIE_NAME,
      JSON.stringify(failureData),
      FAILURE_SNOOZE_MS,
    );

    return undefined;
  }
}

/**
 * Retrieves city coordinates based on the user's IP address with throttle and failure handling.
 * @returns {Promise<Coordinates | undefined>} - A Promise that resolves with the coordinates if successful, or `undefined` if there is an error or throttle limit is reached.
 */
export async function getCityCoords(): Promise<Coordinates | undefined> {
  const ip = await getIpAddress();
  if (!ip) {
    console.warn('Unable to retrieve IP address');
    return undefined;
  }

  const coords = await getCityNameFromIp(ip);
  if (!coords) {
    console.warn('Unable to retrieve city coordinates');
    return undefined;
  }

  return coords;
}
