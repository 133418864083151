// Path: ./src/app/desktop-src/app/pages/AboutPage/styled.ts
import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;
export const Content = styled.div``;

export const OrderList = styled.ol`
  padding: 0px;
  padding-left: 19px;
  margin: 0px;
`;
export const ListItem = styled.li`
  line-height: normal;
  font-size: 15px;
  padding-bottom: 15px;
`;

export const Title = styled.h1`
  @media (max-width: 985px) {
    font-size: 26px;
  }
`;
export const ContentWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin: auto;
  margin-top: 130px;
  @media (max-width: 985px) {
    width: 80%;
  }
  @media (max-width: 400px) {
    width: 90%;
  }
`;
