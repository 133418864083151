// Path: ./src/app/desktop-src/app/pages/PrivacyPage/index.tsx
import * as React from 'react';
import styled from 'styled-components';
import { TobNavBar } from '../InfoPage/TopNav';
import { useTranslation } from 'react-i18next';
import { FooterInfo } from '../InfoPage/Footer';
import { messages } from 'locales/messages';
import CustomHelmet from 'app/components/Helmet';

export const PrivacyPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <CustomHelmet
        title={`Goflyer - ${t(messages.Privacy_Policy())}`}
        description={t(messages.privacyPolicyDescription())}
        keywords={t(messages.privacyPolicyKeywords())}
      />
      <Container>
        <TobNavBar />
        <ContentWrapper>
          <Title>
            {i18n.language === 'en-US' || i18n.language === 'en'
              ? 'Privacy Policy'
              : '独家优惠 实时特价'}
          </Title>
          <Content>
            {/* TODO: need to remove true from condition when we have chinese Privacy Policy content */}
            {true || i18n.language === 'en-US' || i18n.language === 'en' ? (
              <OrderList>
                <ListItem>
                  Our details as the data controller <LineBreak /> GoFlyer
                  Application (the "App") and services provided by GoFlyer (the
                  "Service") are brought to you by GoFlyer, Inc. (the "Data
                  Controller" of your personal data). Consequently, "We", "Us"
                  and "Ours" refer to the Data Controller.
                </ListItem>
                <ListItem>
                  Information we collect and how we use this information
                  <LineBreak />
                  We collect certain information about you when you provide it
                  directly to us or use our App and Service. We only obtain
                  information necessary to provide you with our services.
                  <LineBreak />
                  SMS Messages and Email : As a shopping assistant, the core
                  functionality of our Product is based on providing you with
                  the most up-to-date pricing information on products. For this
                  reason, GoFlyer asks you to provide access to these data so we
                  can reach out to you whenever a relevant price change has
                  taken place. We also use your email address and/or phone
                  number as a unique identifier of you as a user within our
                  system and allows us to secure your data. Your email address
                  will also be used as a primary means of communication for us
                  on anything related to changes to the App and Service such as
                  Privacy Policy, Terms of Use, or core functionality of our App
                  or Service. You will always have a chance to opt out of our
                  communications at any time. Your SMS messages and email are
                  safe and we do not use it for profiling or targeting.
                  <LineBreak />
                  IP address: Core functionality of our Product is based on
                  connection to the Internet. That is why our App and Service
                  won’t properly function without Internet connection. Your IP
                  address is a unique identifier that lets you connect to the
                  Internet and our service will log connections for security and
                  troubleshooting purposes.
                  <LineBreak />
                  APNS(Apple Push Notification Service)/FCM(Firebase Cloud
                  Messaging) device token: Push notifications allow you to get
                  immediate updates about new price updates or exclusive deals
                  on the GoFlyer platform. You’re free to enable or disable them
                  during initial App setup or later using your device’s system
                  preferences.
                  <LineBreak />
                  App token assigned by us: This token allows us to identify
                  your device in our system and troubleshoot potential issues
                  you might experience.
                  <LineBreak />
                  Device, App version, iOS/Android version information: We need
                  to have this information so the App functions properly on your
                  specific device.
                  <LineBreak />
                  Statistical information with regards to App usage: In order to
                  better understand general app usage patterns, improve the
                  Product and its user experience, GoFlyer collects general
                  statistical information about the usage of the Product.
                  Collecting such data helps us optimize the App in future
                  updates and such usage does not affect your rights and
                  freedoms and does not disclose any personal data of yourself
                  or your contacts.
                  <LineBreak />
                  Logs: We collect this information to prevent fraud and
                  potential unauthorized access to your personal information,
                  ensuring the technical availability and security of the App.
                  The server that hosts the App may record requests your device
                  makes to the server, the details on the device and browser you
                  use, your IP address, date and time of access, city and
                  country, operating system, browser type, mobile network
                  information. This data is used only for technical purposes –
                  that is, to ensure the proper functioning and security of the
                  App and to investigate possible security incidents.
                  <LineBreak />
                  Cookie information: This information is necessary for the
                  GoFlyer website as well as the Web version of GoFlyer App.
                  Cookies allows us to identify you as a member of the team and
                  prevent unauthorized access to your team administration portal
                  by other users. All of this information is stored locally on
                  your device.
                  <LineBreak />
                  Customer Support communication: We save a record of
                  communication including attachments and information you
                  voluntarily decide to share with us for troubleshooting
                  purposes whenever you communicate with our support team.
                  <LineBreak />
                  Website: your browser transfers certain data so that it can
                  access the Website, namely:
                  <LineBreak />
                  <UnOrderList>
                    <UnListItem> the IP address</UnListItem>
                    <UnListItem> the date and time of the request</UnListItem>
                    <UnListItem> the browser type</UnListItem>
                    <UnListItem> the operating system</UnListItem>
                    <UnListItem>
                      {' '}
                      the language and version of the browser software.
                    </UnListItem>
                    <UnListItem>
                      {' '}
                      Cookies: Use of (Further Analyzing) Tools
                    </UnListItem>
                  </UnOrderList>
                  <LineBreak />
                  Cookies are stored on your computer when using the Website.
                  Cookies are small text files that are stored on your hard disk
                  of the computer with which you visit a website and which are
                  allocated to your browser and through which certain
                  information is submitted to the cookie user that sets the
                  cookie (in this case us). Cookies serve to make the website
                  offering more user-friendly and effective overall.
                  <LineBreak />
                  The Website uses cookies to the following extent:
                  <UnOrderList>
                    <UnListItem> Transient / Session cookies</UnListItem>
                    <UnListItem> Persistent / Setting cookies</UnListItem>
                    <UnListItem> Analytics cookies</UnListItem>
                  </UnOrderList>
                  <LineBreak />
                  Transient cookies are automatically deleted when you close
                  your browser. This includes in particular the session cookies.
                  These store a so-called session ID, which identifies user
                  sessions in the browser. Session cookies are deleted when you
                  log out or close your browser.
                  <LineBreak />
                  Persistent cookies help the Website remember your information
                  and settings when you visit them in the future. They are
                  automatically deleted after a specified period, which may
                  differ depending on the cookie.
                  <LineBreak />
                  We also use cookies on our website which enable an analysis of
                  the user's surfing behavior.
                  <LineBreak />
                  You can configure your browser settings according to your
                  wishes and, for example, restrict the use of cookies or refuse
                  them altogether. However, we would like to point out that you
                  may not be able to use all the functions of the Website in
                  this case.
                  <LineBreak />
                  The Website uses Google Analytics, a web analytics service
                  provided by Google, Inc. (“Google”). Google Analytics uses
                  “cookies”, which are text files placed on your computer, to
                  help analyze how you use the Website. The information
                  generated by the cookie about your use of the Website will
                  normally be transmitted to and stored by Google on servers in
                  the United States.
                  <LineBreak />
                  In case IP-anonymization is activated on the Website, your IP
                  address will be truncated within the area of member states of
                  the European Union or within other contracting states to the
                  Agreement on the European Economic Area. Only in exceptional
                  cases the whole IP address will be first transferred to a
                  Google server in the USA and truncated there. Google will use
                  this information on behalf of GoFlyer for the purpose of
                  evaluating your use of the Website, compiling reports on
                  Website activity and providing other services for GoFlyer
                  relating to website activity and internet usage.
                  <LineBreak />
                  The IP address that your browser transfers within the scope of
                  Google Analytics will not be associated with any other data
                  held by Google.
                  <LineBreak />
                  You may refuse the use of cookies by selecting the appropriate
                  settings in your browser, however please note that if you do
                  so you may not be able to use all functions of the Website.
                  You can also opt-out from the storage by Google of the data
                  that is created by the cookie and is related to the use of the
                  Website (including your IP address) and the processing of such
                  data by Google by downloading and installing the Google
                  Analytics opt-out Browser add-on available under
                  https://tools.google.com/dlpage/gaoptout?hl=en.
                  <LineBreak />
                  As an alternative to the browser add-on or within browsers on
                  mobile devices, you can click this link in order to opt-out
                  from being tracked by Google Analytics within this Website in
                  the future (this opt-out option applies only for the browser
                  in which you set it and with regard to the Website). In this
                  case an opt-out cookie is put on your device. In case you
                  delete your cookies, you will have to use the aforementioned
                  link again.
                  <LineBreak />
                  For further information on Google Analytics please refer to:
                  http://www.google.com/analytics/terms/.
                  <LineBreak />
                  Email messages sent by us via third-party services like
                  MailChimp or Campaign Monitor may contain tracking pixels
                  which helps us collect statistics on delivery and opening
                  rates of our correspondence. These pixels do not provide us
                  with any additional personal data about you or your behavior
                  online. You can disable image rendering in your email client
                  which will deactivate this feature, however you will be unable
                  to see any images within other received emails.
                  <LineBreak />
                  If you decide to deactivate (some of) the cookies and tools
                  described above, please note that certain features and
                  functionalities of the Services might not work or might not be
                  accessible to you.
                </ListItem>
                <ListItem>
                  What we do with your personal data
                  <LineBreak />
                  Your personal data is used to provide you our App and
                  Services, and to improve the Product. We encrypt your emails
                  and then store some of your personal data on secure servers
                  that would prevent unauthorized access or destruction. Unless
                  you have asked us not to, We may rarely contact you by email
                  about similar products and services to the App. Whenever We
                  contact you, We would always give you the right to opt out at
                  any time (see the section "Your Rights" below).
                  <LineBreak />
                  As stated in section 2 above, We only process personal data
                  for the purposes strictly necessary to provide you with the
                  service. Some of the purposes for processing the data provided
                  by you include:
                  <LineBreak />
                  <UnOrderList>
                    <UnListItem> Providing you with the services</UnListItem>
                    <UnListItem> Fraud prevention</UnListItem>
                    <UnListItem> Improving our services</UnListItem>
                    <UnListItem>
                      {' '}
                      Notifying you of any changes in our services
                    </UnListItem>
                  </UnOrderList>
                </ListItem>

                <ListItem>
                  How long personal data is stored for
                  <LineBreak />
                  Depending on the type, your personal data is stored either
                  until you delete the App or after a certain period.
                  <LineBreak />
                  <UnOrderList>
                    <UnListItem> Type of information</UnListItem>
                    <UnListItem> Length of storage</UnListItem>
                    <UnListItem>
                      {' '}
                      Email address, communications content, APNS/FCM device
                      token, App token assigned by us, device info
                    </UnListItem>
                    <UnListItem>
                      {' '}
                      3 months after deletion of your account from GoFlyer on
                      all
                    </UnListItem>
                    <UnListItem>devices</UnListItem>
                    <UnListItem> Recent communications received</UnListItem>
                    <UnListItem> 3 months</UnListItem>
                    <UnListItem> IP addresses</UnListItem>
                  </UnOrderList>
                </ListItem>
                <ListItem>
                  Security measures used by Us
                  <LineBreak />
                  Your data is stored on secure servers that we rent and We use
                  the recommended industry practices to keep your data secure.
                  We use appropriate levels of technical and organizational
                  measures to prevent accidental or unlawful destruction, loss,
                  alteration, unauthorized disclosure of or access to personal
                  data transmitted, stored or otherwise processed.
                  <LineBreak />
                  For instance, We ensure that all transmission is secured with
                  HTTPS so that no one else can access your data. Your email and
                  account credentials are stored on secure cloud-based servers
                  using symmetric encryption. We currently use Amazon Web
                  Services (AWS) and Google (the "Hosting providers"). Those
                  Hosting providers are in possession of various international
                  security certificates that ensure safety of your data with
                  them. You can read more on the security measures of Google,
                  for instance, by following the link:
                  https://cloud.google.com/security/compliance/.
                  <LineBreak />
                  We use appropriate levels of technical and organizational
                  measures to prevent accidental or unlawful destruction, loss,
                  alteration, unauthorized disclosure of or access to personal
                  data transmitted, stored or otherwise processed. A
                  non-exhaustive list of such measures include:
                  <LineBreak />
                  <OrderList>
                    <ListItem>
                      Protective measures for physical access control:
                      <LineBreak />
                      Furthermore, an alarm system is installed in the premises,
                      preventing infiltration by unauthorised persons. The alarm
                      system is linked to a locking mechanism for the doors.
                    </ListItem>
                    <ListItem>
                      {' '}
                      Protective measures for system access control:
                      <LineBreak />
                      Each employee has access to the systems/services only via
                      his/her own employee access. The access rights involved
                      are limited to the responsibilities of the respective
                      employee and/or team.
                      <LineBreak />
                      We regulate access to our own systems via password
                      procedures and the use of SSH keys, which strengthen the
                      productive systems against attacks that target weak
                      passwords, as the password-based access to the relevant
                      systems is disabled.
                      <LineBreak />
                      We have, in addition, a regulation for the creation of
                      passwords. This guarantees higher security also for
                      systems that offer password-based access.
                      <LineBreak />
                      Passwords must meet the following requirements:
                      <UnOrderList>
                        <UnListItem> At least 8 characters long</UnListItem>
                        <UnListItem>
                          {' '}
                          At least 1 letter in upper-case
                        </UnListItem>
                        <UnListItem>
                          {' '}
                          At least 1 letter in lower-case
                        </UnListItem>
                        <UnListItem> At least 1 number</UnListItem>
                        <UnListItem>
                          {' '}
                          At least 1 non-alphanumeric character
                        </UnListItem>
                      </UnOrderList>
                      <LineBreak />
                      Our systems are protected by firewalls that reject all
                      incoming connections by default. Only connection types
                      defined by exception are acce
                    </ListItem>
                    <ListItem>
                      {' '}
                      Protective measures for data access control:
                      <LineBreak />
                      All servers and services are subject to continuous
                      monitoring. This includes the logging of personal access
                      in the user interface.
                      <LineBreak />
                      Due to the close proximity of the employees, a visual
                      inspection is possible at any time.
                      <LineBreak />
                      Locking and/or logging off when leaving work is prescribed
                      and is practised.
                    </ListItem>
                    <ListItem>
                      Protective measures for transfer control: The handling of
                      local data storage devices, e.g. USB sticks, is regulated
                      via agreements. Access to the systems from outside the
                      company network is possible only via secure VPN access
                      (provided by third-party services such as ExpressVPN).
                    </ListItem>
                    <ListItem>
                      Protective measures for transfer control:
                      <LineBreak />
                      The handling of local data storage devices, e.g. USB
                      sticks, is regulated via agreements.
                      <LineBreak />
                      Access to the systems from outside the company network is
                      possible only via secure VPN access (provided by
                      third-party services such as ExpressVPN).
                    </ListItem>
                    <ListItem>
                      Protective measures for input control:
                      <LineBreak />
                      Our employees do not work directly at database level, but
                      instead use applications to access the data.
                      <LineBreak />
                      IT employees access the system via individual access and
                      use a common login.
                    </ListItem>
                    <ListItem>
                      Protective measures for availability control:
                      <LineBreak />
                      We ensure the availability of data in several ways. On the
                      one hand, there is regular backup of the entire system.
                      This steps in if the other availability measures fail.
                      <LineBreak />
                      Critical services are operated redundantly in multiple
                      data centres and controlled by a high-availability system.
                      <LineBreak />
                      Our workstations are also protected with the usual
                      measures. For example, virus scanners are installed,
                      laptops are encrypted.
                    </ListItem>
                    <ListItem>
                      Categories of recipients and Data Processors
                      <LineBreak />
                      We do not rent, sell or share your personal data with any
                      third parties, except where We have to comply with Our
                      legal obligation. Some of the data of our users is
                      aggregated for statistical purposes and processed in the
                      legitimate interests as stated in section 2 above.
                      <LineBreak />
                      This does not mean that We blindly follow disclosure
                      orders. We will check each request to ensure it satisfies
                      the relevant safeguards, contains a court order or is
                      issued under a legislative measure for the prevention,
                      investigation, detection or prosecution of criminal
                      offences. If We employ a processor to act on our behalf,
                      We ensure that there are adequate contractual measures to
                      ensure responsibility, security and liability to the same
                      level as expected of Us.
                      <LineBreak />
                      In any case where a third party accesses your data on our
                      behalf or upon our instructions (be it inside or outside
                      Canada), We use the relevant legal basis to comply with
                      the data protection legislation.
                    </ListItem>
                    <ListItem>
                      Your rights
                      <LineBreak />
                      GoFlyer is a subject of various data privacy regulations
                      including the General Data Protection Regulation and the
                      California Consumer Privacy Act. You are entitled to the
                      full spectrum of the rights under those regulations. We
                      will go out of our way to accommodate any valid request.
                      You can either exercise your rights by deleting your
                      account and all information associated with it from your
                      device or by emailing us at support@goflyer.ca.
                      <LineBreak />
                      GoFlyere under no circumstances sells your data and
                      performs only lawful processing of your personal data,
                      please see section 2 and 3 above for details.
                      <LineBreak />
                      You have a wide array of rights that we respect. Among
                      those the right to:
                      <LineBreak />
                      <UnOrderList>
                        <UnListItem>
                          {' '}
                          Require access to your personal data;
                        </UnListItem>
                        <UnListItem>
                          {' '}
                          Require rectification of your personal data (this is
                          less relevant since otherwise we could not provide you
                          with the service);
                        </UnListItem>
                        <UnListItem>
                          {' '}
                          Require erasure of your personal data;
                        </UnListItem>
                        <UnListItem>
                          {' '}
                          Withdraw consent to the processing of your personal
                          data, where applicable, otherwise we could not provide
                          you with the service;
                        </UnListItem>
                        <UnListItem>
                          Lodge a complaint with your local supervisory
                          authority if you believe that your privacy rights have
                          been breached.
                        </UnListItem>
                      </UnOrderList>
                      <LineBreak />
                      The right to data portability is inapplicable with the
                      App. You should contact your email provider directly to
                      request combined access to all of your personal data. If
                      your personal data is erased at your request or in
                      accordance with our data retention policy, We only retain
                      such information that is necessary to protect our
                      legitimate interests or to comply with a legal obligation.
                      <LineBreak />
                    </ListItem>
                    <ListItem>
                      Children's privacy
                      <LineBreak />
                      We never knowingly collect or solicit any information from
                      anyone of 13 years and younger. The App and its content
                      are not directed at nor made look to appeal to such
                      persons. Parents or guardians that believe that We hold
                      information about their children aged 13 and under may
                      contact Us at support@goflyer.ca.
                    </ListItem>
                    <ListItem>
                      Our commitment
                      <LineBreak />
                      <UnOrderList>
                        <UnListItem>
                          {' '}
                          We will only collect and use your data where We have a
                          legal basis to do so;
                        </UnListItem>
                        <UnListItem>
                          We will always be transparent and tell you about how
                          we use your information;
                        </UnListItem>
                        <UnListItem>
                          {' '}
                          When We collect your data for a particular purpose, We
                          will not use it for anything else without your
                          consent, unless other legal basis applies;
                        </UnListItem>
                        <UnListItem>
                          {' '}
                          We will not ask for more data than needed for the
                          purposes of providing our services;
                        </UnListItem>
                        <UnListItem>
                          We will adhere to the data retention policies and
                          ensure that your information is securely disposed of
                          at the end of such retention period;
                        </UnListItem>
                        <UnListItem>
                          We will observe and respect Your rights (in section 8
                          above) by ensuring that queries relating to privacy
                          issues are dealt with promptly and transparently;
                        </UnListItem>
                        <UnListItem>
                          We will keep our staff trained in privacy and security
                          obligations;{' '}
                        </UnListItem>
                        <UnListItem>
                          {' '}
                          We will ensure to have appropriate technological and
                          organizational measures in place to protect your data
                          regardless of where it is held;
                        </UnListItem>
                        <UnListItem>
                          {' '}
                          We will also ensure that all of our data processors
                          have appropriate security measures in place with
                          contractual provisions requiring them to comply with
                          Our commitment;
                        </UnListItem>
                        <UnListItem>
                          {' '}
                          We will obtain your consent and ensure that suitable
                          safeguards are in place before personal data is
                          transferred to other countries.
                        </UnListItem>
                      </UnOrderList>
                    </ListItem>
                    <ListItem>
                      Changes to the privacy policy
                      <LineBreak />
                      We will always notify you via email or otherwise should we
                      update this privacy policy. We will update the "last
                      modified" date at the bottom of this privacy policy to
                      indicate the latest revision, as well as the changes made.
                    </ListItem>
                    <ListItem>
                      Contact Information
                      <LineBreak />
                      Name: GoFlyer Inc.
                      <br />
                      Address: 200 Consumers Rd Suite 308, North York, ON M2J
                      4R4
                      <br />
                      Email: support@goflyer.cae.
                    </ListItem>
                  </OrderList>
                </ListItem>
              </OrderList>
            ) : (
              <p>
                GoFlyer
                团队每天与各个注册商家直接沟通，为你获取独家爆款优惠。商家并可直接通过
                GoFlyer 随时发布极致新鲜货品、实时特价资讯
              </p>
            )}
          </Content>
        </ContentWrapper>
        <FooterInfo />
      </Container>
    </>
  );
};

const Container = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;
const Content = styled.div``;

const OrderList = styled.ol`
  padding: 0px;
  padding-left: 19px;
  margin: 0px;
`;
const ListItem = styled.li`
  line-height: normal;
  font-size: 15px;
  padding-bottom: 15px;
`;

const Title = styled.h1`
  @media (max-width: 985px) {
    font-size: 26px;
  }
`;
const ContentWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin: auto;
  margin-top: 130px;
  @media (max-width: 985px) {
    width: 80%;
  }
  @media (max-width: 400px) {
    width: 90%;
  }
`;

const LineBreak = styled.hr`
  border: 1px solid white;
  border-radius: 0px;
`;

const UnOrderList = styled.ul`
  padding-left: 19px;
  list-style-type: disc;
`;
const UnListItem = styled.li``;
