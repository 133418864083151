// Path: ./src/app/pages/FavouritesStoreListPage/Loadable.ts
/**
 *
 * Asynchronously loads the component for FavouritesStoreListPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const FavouritesStoreListPage = lazyLoad(
  () => import('./index'),
  module => module.FavouritesStoreListPage,
);
