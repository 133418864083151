// Path: ./src/app/components/CardFlyerNew/index.tsx
/**
 *
 * CardFlyerNew
 *
 */
import React, { memo } from 'react';
import { messages } from 'locales/messages';
import { useTranslation } from 'react-i18next';
import { GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import { Link } from 'react-router-dom';
import noimage from '../../assets/No_Image.jpg';
import Imageloader from '../../components/ImageLoader';
import {
  CardDiv,
  Img,
  TopDiv,
  LeftDiv,
  SubLeftDiv,
  Heading,
  SubText,
  BottomDiv,
  SalesBtnDiv,
  MuiButton,
  MuiFavoriteBorderIcon,
  MuiFavoriteIcon,
  MuiFiberManualRecordIcon,
} from './styled';

interface Props {
  startingDate: Date;
  Logo?: string;
  name?: string;
  description?: string;
  distance?: number;
  onClick?: any;
  storeFavList?: string | undefined | GfStoreDto | GfStoreDto[];
  RightImage?: string;
  LeftImage?: string;
  daysLeft?: string;
  price?: number;
  topLink?: {
    storeId: string;
    path?: string;
    /**
     * @deprecated this is not needed anymore
     */
    search?: string;
  };
  bottomLink?: {
    path?: string;
    state?: {
      storeId?: string;
    };
  };

  signature?: string;
}

export const CardFlyerNew: React.FunctionComponent<Props> = memo(
  (props: Props) => {
    const { t } = useTranslation();
    return (
      <CardDiv>
        <Link
          data-testid="card flyer new Link"
          to={{
            pathname: `${props.topLink?.path}/${props.topLink?.storeId}`,
            // search: `${props.topLink?.search}`,
          }}
        >
          <TopDiv>
            <LeftDiv>
              <Img src={props.Logo} alt={props.name} />
              <SubLeftDiv>
                <Heading>
                  {props.name}
                  {props.signature && `, ${props.signature}`}{' '}
                </Heading>
                <SubText data-testid="distance km">
                  {props.description}
                  {'  '}
                  <span>
                    <MuiFiberManualRecordIcon />
                  </span>
                  {'  '}
                  {props?.distance?.toFixed(1) + ` ` + t(messages.km())}
                </SubText>
              </SubLeftDiv>
            </LeftDiv>
            <div onClick={props.onClick}>
              {props.storeFavList === undefined ? (
                <MuiFavoriteBorderIcon data-testid="card flyer new favourite border icon" />
              ) : (
                <MuiFavoriteIcon data-testid="card flyer new favourite icon" />
              )}
            </div>
          </TopDiv>
        </Link>

        <Link
          data-testid="card flyer new"
          to={{
            pathname: `${props.bottomLink?.path}`,
            state: props?.bottomLink?.state?.storeId as string,
          }}
        >
          <BottomDiv>
            <Imageloader
              startingDate={props.startingDate}
              contain={false}
              height={'100%'}
              width="50%"
              url={props.LeftImage ? props.LeftImage : noimage}
            />
            <Imageloader
              startingDate={props.startingDate}
              contain={false}
              height={'100%'}
              width="50%"
              url={props.RightImage ? props.RightImage : props.LeftImage}
            />

            {props?.daysLeft != undefined ? (
              <SalesBtnDiv>
                <MuiButton variant="outlined" data-testid={props.daysLeft}>
                  {props.daysLeft}
                </MuiButton>
              </SalesBtnDiv>
            ) : (
              <></>
            )}
          </BottomDiv>
        </Link>
      </CardDiv>
    );
  },
);
