// Path: ./src/app/assets/svg/FranceFlagSvg.tsx
import * as React from 'react';

export function FranceFlagSvg({ width = 25, height = 25 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={height}
      data-testid="franceflagsvg-test-id"
    >
      <path fill="#fff" d="M0 0h512v512H0z" />
      <path fill="#002654" d="M0 0h170.7v512H0z" />
      <path fill="#ce1126" d="M341.3 0H512v512H341.3z" />
    </svg>
  );
}

export default FranceFlagSvg;
