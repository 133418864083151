// Path: ./src/app/desktop-src/app/pages/AboutPage/index.tsx
import * as React from 'react';
import { TobNavBar } from '../InfoPage/TopNav';
import { useTranslation } from 'react-i18next';
import { FooterInfo } from '../InfoPage/Footer';
import { messages } from 'locales/messages';
import { Container, Content, Title, ContentWrapper } from './styled';
import CustomHelmet from 'app/components/Helmet';

export const AboutPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <CustomHelmet
        title={`Goflyer - ${t(messages.About_Us())}`}
        description={t(messages.aboutDescription())}
        keywords={t(messages.aboutKeywords())}
      />
      <Container>
        <TobNavBar />
        <ContentWrapper>
          <Title>{t(messages.About_Us())}</Title>
          <Content>
            {i18n.language === 'zh' ? (
              <div>
                GoFlyer
                是一款为用户专门设计的提供大多伦多地区各种优惠产品的App，每周提供最快最全面的各类传单及精选独家特价产品，并提供中英文版本方便用户使用。我们同时以用户所在位置为中心，最快速的显示出附近所有特价传单及最适合你的特价产品。并推荐给用户精选的各种优惠。GoFlyer
                团队同时每天搜索各类商家的及时更新特价推荐给用户，并与各类商家直接沟通，获得独家优惠，帮GoFlyer用户每日生活省钱多多！
              </div>
            ) : i18n.language === 'fr' ? (
              <div>
                GoFlyer est l'application qui aide les utilisateurs à économiser
                sur leurs achats quotidiens. Nous mettons davantage l'accent sur
                les magasins asiatiques et ethniques, en livrant des prospectus
                et des offres exceptionnelles à temps. Nous regroupons des
                offres provenant de magasins de toute la région du Grand Toronto
                (GTA) dans l'application GoFlyer. Nous répertorions également
                toutes les promotions et prospectus à proximité sur la carte, ce
                qui vous permet de trouver facilement toutes les offres à
                proximité. Notre équipe contacte directement les commerçants
                pour obtenir des offres exclusives pour nos utilisateurs.
                L'objectif est de vous aider à trouver les meilleures offres et
                promotions pertinentes en quelques clics, à tout moment.
              </div>
            ) : (
              <div>
                GoFlyer is the App helps users save on daily shoppings , we
                focus more on Asian and ethnic stores, delivering flyers and
                super deals on time. We aggregate deals from stores all over GTA
                into the GoFlyer app. We also list all promotions and flyers
                near you on the map. That helps you easily find all deals
                nearby. Our team directly reaches merchants to get exclusive
                deals for our users. The goal is to help you find the best and
                most relevant deals with a few clicks any time.{' '}
              </div>
            )}
          </Content>
        </ContentWrapper>
        <FooterInfo />
      </Container>
    </>
  );
};
