// Path: ./src/app/components/StoreUiFlyer/index.tsx
/**
 *
 * StoreUiFlyer
 *
 */
import React, { memo } from 'react';
import noimage from '../../assets/No_Image.jpg';
import {
  MuiButton,
  FlyerDiv,
  BottomDiv,
  SubBottomDiv,
  SalesBtnDiv,
} from './styled';

interface Props {
  Logo?: string;
  name?: string;
  description?: string;
  distance?: number;
  onClickShoppingListButton?: any;
  onClickFavStoreIcon?: any;

  productName?: string;
  discountPrice?: string | number;
  price?: number;
  onClick?: any;
  RightImage?: string;
  LeftImage?: string;
  daysLeft?: string;
}
export const StoreUiFlyer: React.FunctionComponent<Props> = memo(props => {
  return (
    <FlyerDiv>
      <BottomDiv>
        <SubBottomDiv
          style={{
            backgroundImage: `url(${
              props.RightImage ? props.RightImage : noimage
            })`,
          }}
        ></SubBottomDiv>
        <SubBottomDiv
          style={{
            backgroundImage: `url(${props.LeftImage})`,
          }}
        >
          <SalesBtnDiv>
            {props?.daysLeft ? (
              <MuiButton variant="outlined" data-testid={props.daysLeft}>
                {props.daysLeft}
              </MuiButton>
            ) : (
              <></>
            )}
            {/* <MuiSaleButton
              variant="outlined"
              hidden={props.price === null}
            >
              {t(messages.Save())} ${props.price}
            </MuiSaleButton> */}
          </SalesBtnDiv>
        </SubBottomDiv>
      </BottomDiv>
    </FlyerDiv>
  );
});
