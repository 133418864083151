// Path: ./src/app/desktop-src/app/components/PageHeader/index.tsx
/**
 *
 * Header
 *
 */
import React, { memo } from 'react';
import { LanguageSwitcher } from 'app/mobile-desktop-common-components/LanguageSwitcher';
import { TopNavButton } from '../TopNavButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import { Link, useHistory } from 'react-router-dom';
import * as analytics from 'utils/analytics';
import { useSelector } from 'react-redux';
import { RootState } from 'types';
import { messages } from 'locales/messages';
import { useTranslation } from 'react-i18next';

import {
  MuiFavoriteBorderIcon,
  MuiFavoriteIcon,
  TopNav,
  LanuageWrapper,
  ButtonWrapper,
  BackButton,
  ImageSection,
  Image,
  HeartIconWrapper,
  ButtonsWrapper,
  Title,
  Button,
} from './styled';
import useLocalizedDateFormatter from 'utils/hook/useLocalizedDateFormatter';
interface Props {
  // logo?: string;
  // name?: string;
  // signature?: string;
  endDate?: Date;
  startDate?: Date;
  store?: GfStoreDto;
  storeFavList?: string | undefined | GfStoreDto | GfStoreDto[];
  handleHeartClick?: () => {};
  showBack?: boolean;
  title?: string;
  orderByButtons?: {
    title: string;
    onClick: () => void;
    isSelected: boolean;
  }[];
}

export const PageHeader = memo((props: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const CommonData = useSelector((state: RootState) => state.commonData);

  const TitleElement = <> {props.title && <Title>{props.title}</Title>}</>;
  const validStartDate = useLocalizedDateFormatter(props.startDate, {
    format: {
      month: 'short',
      day: 'numeric',
    },
  });
  const validEndDate = useLocalizedDateFormatter(props.endDate, {
    format: {
      month: 'short',
      day: 'numeric',
    },
  });
  const handleBack = () => {
    analytics.click_back_button();
    /**
     * we need this because when user arrive at the flyer detail page without going to the home page.
     * when they click the back button, it should go back to flyer list page
     */
    if (CommonData.prevLocation === '') {
      history.push('/flyers');
    } else {
      history.goBack();
    }
  };

  return (
    <TopNav data-testid="Page Header" className="TopNav">
      {!props.orderByButtons && TitleElement}
      {props.orderByButtons && (
        <ButtonsWrapper>
          {TitleElement}
          {props.orderByButtons.map(val => {
            return (
              <Button
                onClick={val.isSelected ? () => {} : val.onClick}
                key={val.title}
                isSelected={val.isSelected}
              >
                {val.title}
              </Button>
            );
          })}
        </ButtonsWrapper>
      )}
      <ButtonWrapper>
        {props.showBack && (
          <BackButton
            data-testid="page header handle back button"
            onClick={handleBack}
          >
            <ArrowBackIcon style={{ fontSize: '34px' }} />
          </BackButton>
        )}
        {props.store?.merchant.name && (
          <ImageSection>
            <Link to={`/storedetails/${props.store.id}`}>
              <Image
                className={`pageHeaderImageLogo`}
                src={props.store.merchant.merchantLogo.src}
                data-testid="flyerdetail merchant logo"
                alt="merchant logo"
              />
            </Link>
          </ImageSection>
        )}

        {props.store?.merchant.name && (
          <TopNavButton
            data-testid="page header name"
            fontWeight="700"
            text={props.store?.merchant.name + ','}
          />
        )}
        {props.store?.signature && (
          <TopNavButton fontWeight="700" text={props.store?.signature} />
        )}
        {validStartDate && validEndDate && (
          <TopNavButton
            fontWeight="700"
            text={`${t(messages.Valid())} ${validStartDate} - ${validEndDate}`}
          />
        )}
      </ButtonWrapper>
      <LanuageWrapper>
        <LanguageSwitcher />
        {props?.handleHeartClick ? (
          <HeartIconWrapper
            data-testid="page header heart icon wrapper handle heart click"
            onClick={props.handleHeartClick}
          >
            {props?.storeFavList === undefined ? (
              <MuiFavoriteBorderIcon />
            ) : (
              <MuiFavoriteIcon />
            )}
          </HeartIconWrapper>
        ) : (
          <></>
        )}
      </LanuageWrapper>
    </TopNav>
  );
});
