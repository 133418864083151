// Path: ./src/utils/commonFunctions/AddRemoveShoppingListMobile.tsx
import {
  GfFlyerItemDto,
  GfPromotionDto,
} from '@swagger/typescript-fetch-goflyer';
import { GoFlyerAppContext } from 'app/store/context';
import { setCustomer } from 'app/store/reducer';
import React from 'react';
import { apiService } from 'utils/api';
import ReactGA from 'react-ga';
import * as analytics from '../analytics';

export const useAddRemoveShoppingListMobileFunc = () => {
  const { state, dispatch } = React.useContext(GoFlyerAppContext);

  const clickRemoveFromShoppingListButton = async (
    e,
    promotion: GfPromotionDto,
    showCheckButton,
  ) => {
    try {
      // await apiService.gfStoreFavouriteListControllerAddStore
      ReactGA.event({
        category: '1.2',
        action: `Click  Remove  Button`,
      });
      e.preventDefault();
      if (showCheckButton) {
        const updatedPromotionsAndFlyerItemsByMerchantId = Object.keys(
          state.customer.gfShoppingList.promotionsAndFlyerItemsByMerchantId,
        ).reduce((acc, merchantId) => {
          const currentMerchantData =
            state.customer.gfShoppingList.promotionsAndFlyerItemsByMerchantId[
              merchantId
            ];

          const updatedPromotions = currentMerchantData.promotions?.filter(
            item => item.id !== promotion.id,
          );

          if (
            (currentMerchantData?.flyerItems &&
              currentMerchantData?.flyerItems.length > 0) ||
            (updatedPromotions && updatedPromotions.length > 0)
          ) {
            acc[merchantId] = {
              ...currentMerchantData,
              promotions: updatedPromotions,
            };
          }

          return acc;
        }, {});
        const customer = state.customer;
        customer.gfShoppingList = {
          ...customer.gfShoppingList,
          promotions: customer.gfShoppingList.promotions?.filter(
            val => val.id != promotion.id,
          ),
          promotionsAndFlyerItemsByMerchantId:
            updatedPromotionsAndFlyerItemsByMerchantId,
        };
        await apiService.gfShoppingListControllerRemovePromotion(
          {
            promotionId: promotion.id,
          },
          state.customer.gfShoppingList.id,
        );
        /**
         * I move the dispatch after the remove because it is possible fetch the shopping list before the remove promotion.
         * TODO: but this need to be improved. because if network is slow, when customers click the delete button, it will take time to remove the shopping list item. either add a loader or change the loading shopping list logic
         */
        dispatch(setCustomer(customer));
        analytics.click_remove_deal_from_shopping_list(promotion);
      } else {
        const updatedPromotionsAndFlyerItemsByMerchantId = Object.keys(
          state.customer.gfShoppingList.promotionsAndFlyerItemsByMerchantId,
        ).reduce((acc, merchantId) => {
          const currentMerchantData =
            state.customer.gfShoppingList.promotionsAndFlyerItemsByMerchantId[
              merchantId
            ];
          const updatedPromotions = currentMerchantData?.promotions
            ? [...currentMerchantData?.promotions, promotion]
            : [promotion];

          const updatedFlyerItem = currentMerchantData.flyerItems
            ? [...currentMerchantData.flyerItems]
            : [];
          if (updatedFlyerItem.length > 0 || updatedPromotions.length > 0) {
            acc[merchantId] = {
              ...currentMerchantData,
              flyerItems: updatedFlyerItem,
              promotions: updatedPromotions,
            };
          }
          return acc;
        }, {});

        const customer = state.customer;
        customer.gfShoppingList = {
          ...customer.gfShoppingList,
          promotions: [...customer.gfShoppingList.promotions, promotion],
          promotionsAndFlyerItemsByMerchantId:
            updatedPromotionsAndFlyerItemsByMerchantId,
        };
        await apiService.gfShoppingListControllerAddPromotion(
          {
            promotionId: promotion.id,
          },
          state.customer.gfShoppingList.id,
        );
        dispatch(setCustomer(customer));
        analytics.click_add_to_shopping_list_button(promotion);
      }
    } catch (error) {
      console.error(error);
      const updatedPromotionsAndFlyerItemsByMerchantId = Object.keys(
        state.customer.gfShoppingList.promotionsAndFlyerItemsByMerchantId,
      ).reduce((acc, merchantId) => {
        const currentMerchantData =
          state.customer.gfShoppingList.promotionsAndFlyerItemsByMerchantId[
            merchantId
          ];

        const updatedPromotions = currentMerchantData.promotions?.filter(
          item => item.id !== promotion.id,
        );

        if (
          (currentMerchantData?.flyerItems &&
            currentMerchantData?.flyerItems.length > 0) ||
          (updatedPromotions && updatedPromotions.length > 0)
        ) {
          acc[merchantId] = {
            ...currentMerchantData,
            promotions: updatedPromotions,
          };
        }

        return acc;
      }, {});
      const customer = state.customer;
      customer.gfShoppingList = {
        ...customer.gfShoppingList,
        promotions: customer.gfShoppingList.promotions?.filter(
          val => val.id !== promotion.id,
        ),
        promotionsAndFlyerItemsByMerchantId:
          updatedPromotionsAndFlyerItemsByMerchantId,
      };
      dispatch(setCustomer(customer));
    }
  };

  const addAndRemoveFlyerItemToShoppingList = async (
    e,
    flyerItem: GfFlyerItemDto,
    showCheckButton,
  ) => {
    try {
      e.preventDefault();
      if (showCheckButton) {
        const updatedPromotionsAndFlyerItemsByMerchantId = Object.keys(
          state.customer.gfShoppingList.promotionsAndFlyerItemsByMerchantId,
        ).reduce((acc, merchantId) => {
          const currentMerchantData =
            state.customer.gfShoppingList.promotionsAndFlyerItemsByMerchantId[
              merchantId
            ];

          const updatedFlyerItems = currentMerchantData.flyerItems?.filter(
            item => item.id !== flyerItem.id,
          );

          if (
            (currentMerchantData?.promotions &&
              currentMerchantData?.promotions.length > 0) ||
            (updatedFlyerItems && updatedFlyerItems.length > 0)
          ) {
            acc[merchantId] = {
              ...currentMerchantData,
              flyerItems: updatedFlyerItems,
            };
          }

          return acc;
        }, {});

        const customer = {
          ...state.customer,
          gfShoppingList: {
            ...state.customer.gfShoppingList,
            flyerItems: state.customer.gfShoppingList.flyerItems?.filter(
              val => val.id !== flyerItem.id,
            ),
            promotionsAndFlyerItemsByMerchantId:
              updatedPromotionsAndFlyerItemsByMerchantId,
          },
        };

        await apiService.gfShoppingListControllerRemoveFlyerItem(
          {
            flyerItemId: flyerItem.id,
          },
          customer.gfShoppingList.id,
        );
        dispatch(setCustomer(customer));
        analytics.click_add_to_shopping_list_button(flyerItem);
      } else {
        const updatedPromotionsAndFlyerItemsByMerchantId = Object.keys(
          state.customer.gfShoppingList.promotionsAndFlyerItemsByMerchantId,
        ).reduce((acc, merchantId) => {
          const currentMerchantData =
            state.customer.gfShoppingList.promotionsAndFlyerItemsByMerchantId[
              merchantId
            ];
          const updatedFlyerItems = currentMerchantData?.flyerItems
            ? [...currentMerchantData?.flyerItems, flyerItem]
            : [flyerItem];

          const updatedPromotions = currentMerchantData?.promotions
            ? [...currentMerchantData?.promotions]
            : [];
          if (updatedPromotions.length > 0 || updatedFlyerItems.length > 0) {
            acc[merchantId] = {
              ...currentMerchantData,
              flyerItems: updatedFlyerItems,
              promotions: updatedPromotions,
            };
          }
          return acc;
        }, {});

        const customer = {
          ...state.customer,
          gfShoppingList: {
            ...state.customer.gfShoppingList,
            flyerItems: [
              ...state.customer.gfShoppingList.flyerItems,
              flyerItem,
            ],
            promotionsAndFlyerItemsByMerchantId:
              updatedPromotionsAndFlyerItemsByMerchantId,
          },
        };
        await apiService.gfShoppingListControllerAddFlyerItem(
          {
            flyerItemId: flyerItem.id,
          },
          customer.gfShoppingList.id,
        );
        dispatch(setCustomer(customer));

        analytics.click_remove_deal_from_shopping_list(flyerItem);
      }
    } catch (error) {
      console.error(error);
      const updatedPromotionsAndFlyerItemsByMerchantId = Object.keys(
        state.customer.gfShoppingList.promotionsAndFlyerItemsByMerchantId,
      ).reduce((acc, merchantId) => {
        const currentMerchantData =
          state.customer.gfShoppingList.promotionsAndFlyerItemsByMerchantId[
            merchantId
          ];

        const updatedFlyerItems = currentMerchantData.flyerItems?.filter(
          item => item.id !== flyerItem.id,
        );

        if (
          (currentMerchantData?.promotions &&
            currentMerchantData?.promotions.length > 0) ||
          (updatedFlyerItems && updatedFlyerItems.length > 0)
        ) {
          acc[merchantId] = {
            ...currentMerchantData,
            flyerItems: updatedFlyerItems,
          };
        }

        return acc;
      }, {});
      const customer = {
        ...state.customer,
        gfShoppingList: {
          ...state.customer.gfShoppingList,
          flyerItems: state.customer.gfShoppingList.flyerItems?.filter(
            val => val.id != flyerItem.id,
          ),
          promotionsAndFlyerItemsByMerchantId:
            updatedPromotionsAndFlyerItemsByMerchantId,
        },
      };
      dispatch(setCustomer(customer));
    }
  };

  return {
    clickRemoveFromShoppingListButton,
    addAndRemoveFlyerItemToShoppingList,
  };
};
