// Path: ./src/app/desktop-src/app/components/TopNavButton/index.tsx
/**
 *
 * TopNavButton
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
interface Props {
  Icon?: any;
  text?: string;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  fontWeight?: string;
  fontSize?: string;
  badgeValue?: string | number;
}

export const TopNavButton = memo((props: Props) => {
  return (
    <Button
      data-testid={props.text}
      onClick={props.handleClick}
      style={{
        fontSize: props.fontSize ? props.fontSize : '15px',
        fontWeight: props.fontWeight ? props.fontWeight : 'bold',
      }}
    >
      {props.badgeValue ? <Badge>{props.badgeValue}</Badge> : <></>}
      {props.Icon && <ButtonIcon>{props.Icon}</ButtonIcon>}
      <ButtonText>{props.text}</ButtonText>
    </Button>
  );
});

const Button = styled.button`
  background: none;
  outline: none;
  border: 0px;
  display: flex;
  align-items: center;
  position: relative;
`;
const ButtonIcon = styled.div`
  color: #f58220;
  margin-right: 7px;
`;
const ButtonText = styled.div`
  text-align: left;
  letter-spacing: 0px;
  color: #0b0b0b;
  opacity: 1;
  cursor: pointer;
`;

const Badge = styled.div`
  color: white;
  background-color: #f58220;
  width: 18px;
  height: 18px;
  border-radius: 15px;
  position: absolute;
  top: -8px;
  left: 20px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;
