// Path: ./src/app/components/SearchInputField/SearchInputField/index.tsx
/**
 *
 * SearchInputField
 *
 */
import React, { memo, useEffect } from 'react';
import { styled as stlyedMUI } from '@mui/material/styles';

import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/lab/Autocomplete';
import styled from 'styled-components/macro';
import { messages } from 'locales/messages';
import { localstorageSet } from 'utils/localstorage';
import { InputBase } from '@mui/material';

interface Props {
  searchCallback: (text: string) => Promise<void>;
  setInputValue: (text: string) => void;
  inputValue: string;
}

export const SearchInputField: React.FunctionComponent<Props> = memo(props => {
  const [cancelIcon, setCancelIcon] = React.useState(false);

  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const search = async (text: string) => {
    // TODO: will use throttle later
    // throttle(searchPromotions, 500, [text]);
    props?.searchCallback(text);
  };

  useEffect(() => {
    const searchedValue = localStorage.getItem('SearchedValue');
    if (searchedValue) {
      props?.setInputValue(searchedValue);
    }
  }, []);
  const HandleClose = () => {
    setCancelIcon(false);
    // setOpen(false);
    search('');
    props.setInputValue('');
    localstorageSet('SelectedSearch', JSON.stringify(''));
  };

  /**
   * when user type only space
   * @param str
   * @returns
   */
  function onlySpaces(str) {
    return /^\s*$/.test(str);
  }
  return (
    <div>
      <Autocomplete
        fullWidth
        open={open}
        onOpen={() => {
          setOpen(true);
          setCancelIcon(true);
        }}
        onClose={() => {
          setOpen(false);
          setCancelIcon(false);
        }}
        inputValue={props.inputValue}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            search(props.inputValue);
          }
        }}
        onInputChange={(event, newInputValue) => {
          if (!onlySpaces(newInputValue)) {
            props?.setInputValue(newInputValue);
          } else if (newInputValue === '') {
            props?.setInputValue('');
          }
          setCancelIcon(true);
        }}
        options={[]}
        freeSolo={true}
        renderInput={params => (
          <SearchDiv>
            <MuiFormControl
              fullWidth
              style={{ marginRight: `${cancelIcon ? '10px' : '20px'}` }}
              variant="standard"
            >
              <MuiOutlinedInput
                data-testid="Search"
                // type="search"
                {...params}
                size="small"
                placeholder={t(messages.Search_Flyers_or_Items())}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon className={'SearchInputIcon'} />
                  </InputAdornment>
                }
                endAdornment={
                  cancelIcon && (
                    <InputAdornment position="end">
                      <CancelIcon
                        data-testid="search input field cancel icon button"
                        className={'SearchInputIcon'}
                        onClick={HandleClose}
                      />
                    </InputAdornment>
                  )
                }
              />
            </MuiFormControl>
            {cancelIcon && (
              <Cancel
                data-testid="search input field cancel button"
                onClick={() => {
                  search(props.inputValue);
                }}
              >
                Search
              </Cancel>
            )}
          </SearchDiv>
        )}
      />
    </div>
  );
});

const SearchDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Cancel = styled.span`
  text-align: left;
  font-size: 18px;
  letter-spacing: -0.43px;
  color: #000000;
  width: 100px;
`;

const MuiFormControl = stlyedMUI(FormControl)(() => ({
  background: '#7676801F 0% 0% no-repeat padding-box',
  borderRadius: ' 10px',
  color: '#3C3C4399',
  marginLeft: '20px ',
  marginRight: '20px ',
  border: '0px !important',
}));

const MuiOutlinedInput = stlyedMUI(InputBase)(() => ({
  border: '0px !important',
  borderRadius: ' 10px',
  padding: '5px',
  paddingLeft: '10px',
  paddingRight: '10px',
  alignItems: 'center',
  '.MuiInputBase-input': {
    padding: '0px',
  },
}));
