// Path: ./src/app/pages/PromotionListPage/Loadable.ts
/**
 *
 * Asynchronously loads the component for PromotionListPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const PromotionListPage = lazyLoad(
  () => import('./index'),
  module => module.PromotionListPage,
);
