// Path: ./src/app/desktop-src/store/RemoteConfigSlice/index.ts
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { RemoteConfigStateProps } from './types';

export const initialState: RemoteConfigStateProps = {
  getSmallImagesCondition: 'false',
};

const slice = createSlice({
  name: 'remoteConfig',
  initialState,
  reducers: {
    updateRemoteConfig(state, action: PayloadAction<any>) {
      return {
        ...state,
        getSmallImagesCondition:
          action.payload.remoteConfig.getSmallImagesCondition,
      };
    },
  },
});

export const { actions: remoteConfigActions } = slice;
export const UserReducer = slice.reducer;
export const useRemoteConfigSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer as any });

  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useRemoteConfigSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
