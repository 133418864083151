import styled from 'styled-components';

export const StandardLoginButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  border: 1px solid #000000;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  width: 280px;
  height: 44px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const StandardButtonLogo = styled.span`
  width: 24px;
  margin-right: 8px;
`;

export const StandardButtonText = styled.span`
  display: inline-block;
`;
