import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { messages } from 'locales/messages';

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  description?: string;
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 800,
  backgroundColor: '#fff',
  border: 'none',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  padding: '24px',
  borderRadius: '8px',
};

const actionStyle = {
  marginTop: '24px',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px',
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  title = 'Confirm Delete',
  description = 'Are you sure you want to delete this item?',
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      data-testid="confirmationmodal-test-id"
    >
      <Box sx={modalStyle}>
        <Typography
          id="modal-title"
          variant="h6"
          component="h2"
          fontWeight={600}
        >
          {title}
        </Typography>
        <Typography
          id="modal-description"
          sx={{ mt: 2 }}
          dangerouslySetInnerHTML={{ __html: description }}
        ></Typography>
        <Box sx={actionStyle}>
          <Button onClick={onClose} variant="contained" color="info">
            {t(messages.Cancel())}
          </Button>
          <Button onClick={onConfirm} variant="contained" color="error">
            {t(messages.Confirm())}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
