// Path: ./src/utils/date/isSameDate.ts
/**
 * check whether two Date objects in TypeScript represent the same calendar date, regardless of their time of the day.
 * a function that compares the year, month, and day parts of each date.
 * @param date1
 * @param date2
 * @returns
 */
export function isSameDate(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
