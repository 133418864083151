// Path: ./src/utils/theme.ts
import { createTheme } from '@mui/material/styles';

// if you want to add more, refer to here: https://material-ui.com/customization/palette/
declare module '@mui/material/styles/createTheme' {
  interface Theme {}
  interface ThemeOptions {}
}
declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    title1?: { fontSize: number; fontWeight: number };
  }
}
declare module '@mui/material/styles/createPalette' {
  interface Palette {}
  interface PaletteOptions {}
  interface TypeBackground {
    primary: string;
    primaryOpacity: string;
    secondary: string;
    shadow: string;
  }
  interface TypeText {
    gray?: string;
    white: string;
  }

  interface SimplePaletteColorOptions {
    gray?: string;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'sans-serif',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 28,
      fontWeight: 900,
    },
    h2: { fontSize: 27, fontWeight: 900 },
    h3: { fontSize: 27, fontWeight: 500 },
    h4: { fontSize: 25, fontWeight: 900 },
    h5: { fontSize: 16, fontWeight: 500 },
    h6: { fontSize: 14, fontWeight: 900 },
    subtitle1: { fontSize: 11, fontWeight: 900 },
    subtitle2: { fontSize: 11, fontWeight: 500 },
    body1: { fontSize: 17, fontWeight: 500 },
    body2: { fontSize: 12, fontWeight: 500 },
    title1: { fontSize: 17, fontWeight: 900 },
    // button: { fontSize: 12, fontWeight: 500 },
    // caption: { fontSize: 12, fontWeight: 500 },
    // overline: { fontSize: 12, fontWeight: 500 },
  },
  palette: {
    primary: {
      main: '#FF9500',
      light: '#BDBDC3',
      gray: '#828282',
    },
    text: {
      primary: '#000000',
      white: '#FFFFFF',
      secondary: '#757575',
      gray: '#8E8E93',
    },
    background: {
      primary: '#FFFFFF',
      secondary: '#1A1A1A',
      shadow: '#00000033',
      primaryOpacity: 'rgba(255, 149, 0, 0.2)',
    },
  },
});
