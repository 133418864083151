// Path: ./src/app/pages/FlyerViewMenu/index.tsx
/**
 *
 * FlyerViewMenu
 *
 */
import React, { memo } from 'react';
import { GfMenuDto } from '@swagger/typescript-fetch-goflyer';
import { apiService } from '../../../utils/api';
import {} from 'utils/getQueryPara';
import { GoFlyerAppContext } from 'app/store/context';
import { Spinner } from 'app/components/Spinner';
import { useParams } from 'react-router-dom';
import * as analytics from '../../../utils/analytics';

import { FlyerDetailView } from 'app/components/FlyerDetailView/FlyerDetailView';
import { Share } from '@capacitor/share';
export interface IParams {
  id: string;
}
export const FlyerViewMenu = memo(() => {
  const Params = useParams<IParams>();
  const idx = Params.id.split('-');
  const flyerId = `${idx[0]}-${idx[1]}-${idx[2]}-${idx[3]}-${idx[4]}`;
  const [menu, setMenu] = React.useState<GfMenuDto>();
  const { state } = React.useContext(GoFlyerAppContext);

  const handleShare = async (menu: GfMenuDto) => {
    analytics.share_menu(menu);
    await Share.share({
      title: menu?.stores[0]?.merchant?.name,
      text: `${menu?.stores[0]?.merchant?.name} Menu`,
      url: `https://goflyer.ca/restaurantview/${menu.id}-${encodeURIComponent(
        menu.stores[0].merchant.name?.toLowerCase(),
      )}-menu`, // TODO: will make goflyer.ca as an env variable later
      dialogTitle: 'Share with others',
    });
  };

  React.useEffect(() => {
    getMenu();
  }, [state.showBanner]);

  const getMenu = async () => {
    const response = await apiService.gfMenuControllerFindOne(flyerId);
    analytics.view_menu_page(response);
    setMenu(response);
  };
  if (menu === undefined || menu.gfImages === undefined) {
    return <Spinner></Spinner>;
  }

  return (
    <FlyerDetailView
      store={menu.stores[0]}
      handleShare={() => {
        return handleShare(menu);
      }}
      gfImages={menu.gfImages}
    ></FlyerDetailView>
  );
});
