// Path: ./src/app/mobile-desktop-common-components/CacheRequest/index.tsx
import { Position } from '@capacitor/geolocation';
import React, { useEffect } from 'react';
import { cacheRequest } from '../../../utils/cacheRequest';
import { GetUserLocation } from '../../../utils/LocationCheck';

/**
 * use this component to create cache request in the backend. so that when users load other pages, it will be faster
 * @returns
 */
export default function CacheRequest() {
  useEffect(() => {
    (async function () {
      const position = (await GetUserLocation()) as Position;
      console.log(`calling cache`, position);
      if (position) {
        setTimeout(() => {
          cacheRequest(
            position?.coords.longitude.changeDecimal(3),
            position?.coords.latitude.changeDecimal(3),
          );
        }, 5000); //I use this because I don't want to block the actual request for that page
      }
    })();
  }, []);
  return <div data-testid="cacherequest-test-id"></div>;
}
