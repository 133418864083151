// Path: ./src/app/index.tsx
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Capacitor } from '@capacitor/core';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { MapStorePage } from './pages/MapStorePage/Loadable';
import { FlyerListPage } from './pages/FlyerListPage/Loadable';
import { PromotionListPage } from './pages/PromotionListPage/Loadable';

import { LoginPage } from './pages/LoginPage/Loadable';
import { ShoppingListPage } from './pages/ShoppingListPage/Loadable';
import { UserProfilePage } from './pages/UserProfilePage/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { AboutPage } from './pages/AboutPage';
import { MobileLayout } from './pages/MobileLayout';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/styles';
import { theme } from '../utils/theme';
import { FavouritesStoreListPage } from './pages/FavouritesStoreListPage/Loadable';
import { Spinner } from './components/Spinner';
import { GoFlyerAppContext } from 'app/store/context';
import {
  goflyerAppReducer,
  setBestDealsScrollPosition,
  setCustomer,
  setExploreScrollPosition,
  setFlyerTagListPosition,
  setLocation,
  setLatestFlyerPosition,
  setUpComingFlyerPosition,
  setFavoriteFlyerPosition,
  setSearchScrollPosition,
  setSearchDataNumber,
  setSearchDataList,
} from 'app/store/reducer';
import { initialGoFlyerState } from 'app/store/state';
import { getPosition } from 'utils/getUserLocation';
import { localstorageGet, localstorageSet } from 'utils/localstorage';
import { apiService } from 'utils/api';
import {
  GfPromotionFavouriteListDto,
  GfStoreFavouriteListDto,
  GfShoppingListDto,
} from '@swagger/typescript-fetch-goflyer';
import { v4 as uuidv4 } from 'uuid';
import { SearchPage } from './pages/SearchPage';
import ReactGA from 'react-ga';
import { FlyerView } from './pages/FlyerView/index_v1';
import { NewStoreUi } from './pages/NewStoreUi';
import { NewPromotionDetailPage } from './pages/NewPromotionDetailPage';
import { Position } from '@capacitor/geolocation';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import LogRocket from 'logrocket';
import { FlyerTagListPage } from './pages/FlyerTagListPage';
import { InfoPage } from './pages/InfoPage';
import { PrivacyPage } from './pages/PrivacyPage/Loadable';
import { TermsPage } from './pages/TermsPage/Loadable';
import { FlyerViewMenu } from './pages/FlyerViewMenu';
import { firebaseRemoteConfig } from 'utils/fireBaseRemoteConfig';
import { handleDeviceRedirect } from 'utils/handleDeviceRedirect';
import { FlyerLatestPage } from './pages/FlyerLatestPage';
import { FlyerUpComingPage } from './pages/FlyerUpComingPage';
import { SigninPage } from './pages/SigninPage';
import { GfCustomerDtoWithLogin } from 'app/store/state';
import { SigninPageMobile } from './pages/SigninPageMobile';
import * as analytics from '../utils/analytics';
import { CheckUserLocationChange, GetUserLocation } from 'utils/LocationCheck';

declare global {
  interface Window {
    opera: any;
    MSStream: any;
  }
}

function usePageViews() {
  const location = useLocation();
  React.useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);
}

export function App() {
  const [redirectWait, setRedirectWait] = React.useState<boolean>(false);
  const history = useHistory();

  localstorageSet('SelectedSearch', JSON.stringify(''));
  const localSearch = localstorageGet('localSearch');
  if (localSearch) {
    localstorageSet('localSearch', JSON.stringify(localSearch));
  } else {
    localstorageSet('localSearch', JSON.stringify([]));
  }
  React.useEffect(() => {
    if (window.config.REACT_APP_IS_DEV === `false`) {
      LogRocket.init(window.config.REACT_APP_LOGROCKET);
    }
    FireBaseRemoteConfig();
  }, []);

  usePageViews();
  const { i18n } = useTranslation();
  const [state, dispatch] = React.useReducer(
    goflyerAppReducer,
    initialGoFlyerState,
  );
  React.useEffect(() => {
    analytics.visit_site();
    initData();
  }, []);
  const FireBaseRemoteConfig = async () => {
    firebaseRemoteConfig(dispatch);
  };

  const registerNotifications = async () => {
    let permissionStatus = await PushNotifications.checkPermissions();
    console.info('Registration permissionStatus: ', permissionStatus);
    if (permissionStatus.receive === 'prompt') {
      permissionStatus = await PushNotifications.requestPermissions();
      console.log(`notification requestPermissions granted`, permissionStatus);
      analytics.push_notification_granted(permissionStatus);
    }

    if (permissionStatus.receive !== 'granted') {
      console.log(
        `notification push_notification_not_granted`,
        permissionStatus,
      );
      analytics.push_notification_not_granted(permissionStatus);
    }

    await PushNotifications.register();
  };

  /**
   * 1. check if user granted notification permission, if not, will ask for permission, only in mobile device.
   * 2. if granted, will update customer with a notification token in the backend
   * 3. notifications are triggered from the backend. it will find all customer with notification token and send notification to those customers
   */
  const initPushNotification = async () => {
    const customer = localstorageGet('customer') as GfCustomerDtoWithLogin;
    /**
     * This will be called on app startup. If this is the user first time open the mobile app, there will be a pop up asking for notification permission. If a user granted permission in the past, this function will still be called, the result.receive will be granted automatically.
     */
    await registerNotifications();

    await PushNotifications.addListener(
      'registration',
      async (token: Token) => {
        console.log(`notification registration`, token);
        analytics.push_notification_registered(token);
        /**
         * So this event listener also be called every time user start up the app. It will get back the notification token.
         */
        if (customer) {
          await apiService.gfCustomerControllerUpdate(
            {
              notificationToken: token.value,
            },
            customer.id,
          );
          dispatch(
            setCustomer({
              ...customer,
              notificationToken: token.value,
            }),
          );
        }
      },
    );

    await PushNotifications.addListener('registrationError', (error: any) => {
      analytics.push_notification_registered_error(error);
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    await PushNotifications.addListener(
      'pushNotificationReceived',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (notification: PushNotificationSchema) => {
        console.log(`pushNotificationReceived`, notification);
        analytics.push_notification_received(notification);
        // alert('Push received: ' + JSON.stringify(notification));
      },
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log(
          `notification`,
          notification,
          `notification.notification`,
          notification.notification,
          `notification.notification.data`,
          notification.notification.data,
          `notification.notification.data.body`,
          notification.notification.data.body,
        );

        if (
          notification &&
          notification.notification &&
          notification.notification.data &&
          notification.notification.data.body
        ) {
          analytics.open_push_notification(notification.notification.data.body);
          history.push(`${notification.notification.data.body}`);
        }
      },
    );
  };

  const initData = async () => {
    CheckUserLocationChange();
    const position: Position = await GetUserLocation();
    await handleDeviceRedirect(history, dispatch);
    setRedirectWait(true);
    // init location data

    // let language = localstorageGet('language');
    // cacheRequest(position.coords.longitude, position.coords.latitude);
    // addLocation(position, dispatch);
    dispatch(setLocation(position));
    /**
     * "When a user opens the app for the first time, the app will send a request to the backend to create a customer object. This object is then saved in local storage. The next time the user opens the app, the app checks if there is a customer object in local storage. If one is found, the app recognizes the user as a returning customer and uses the saved object from local storage."
     */
    let customer = localstorageGet('customer') as GfCustomerDtoWithLogin;

    /**
     * we don't this anymore. maybe we can use it in the future. At the beginning, we can fav a deal but not we fav the store
     */
    let gf_promotion_favourite_list: GfPromotionFavouriteListDto;
    /**
     * "When the user clicks the heart button on the flyer card, the corresponding store is added to the gf_store_favourite_list. Later, when the user visits the fav tab, the app displays flyers from the stores that were added to their favorites list."
     */
    let gf_store_favourite_list: GfStoreFavouriteListDto;
    let gf_shopping_list: GfShoppingListDto;
    const customer_anonymous_uuid = uuidv4();
    if (
      !customer ||
      !customer.gfStoreFavouriteList ||
      !customer.gfShoppingList
    ) {
      gf_promotion_favourite_list =
        await apiService.gfPromotionFavouriteListControllerCreate({});
      gf_store_favourite_list =
        await apiService.gfStoreFavouriteListControllerCreate({});
      gf_shopping_list = await apiService.gfShoppingListControllerCreate({});

      const new_customer = await apiService.gfCustomerControllerCreate({
        userName: `anonymous${customer_anonymous_uuid}`,
        // @ts-ignore
        gfPromotionFavouriteList: { id: gf_promotion_favourite_list?.id },
        gfStoreFavouriteList: { id: gf_store_favourite_list.id },
        gfShoppingList: { id: gf_shopping_list.id },
        passwordHash: customer_anonymous_uuid,
      });

      dispatch(
        // @ts-ignore
        setCustomer({
          ...new_customer,
        }),
      );
      // @ts-ignore
      customer = new_customer;

      /**
       * "We send this information to the backend because we require users' last location to send notifications effectively. For instance, when we want to send a notification about a flyer, we only want to target users who are near the store. This ensures that users who are far away from the store do not receive irrelevant notifications. Therefore, we select users located near the store and send the notification to them only."
       */
      await apiService.gfCustomerControllerUpdate(
        {
          lastLocation: {
            type: 'Point',
            coordinates: [
              position.coords.latitude.changeDecimal(3),
              position.coords.longitude.changeDecimal(3),
            ],
          },
        },
        customer.id,
      );

      dispatch(
        setCustomer({
          ...customer,
          lastLocation: {
            type: 'Point',
            coordinates: [
              position.coords.latitude.changeDecimal(3),
              position.coords.longitude.changeDecimal(3),
            ],
          },
        }),
      );

      /**
       * Enable notification for the mobile app
       */
      const isPushNotificationsAvailable =
        Capacitor.isPluginAvailable('PushNotifications');
      if (isPushNotificationsAvailable) {
        initPushNotification();
      }
    } else {
      /**
       * if customer object is found in local storage
       */
      const remoteCustomer = (await apiService.gfCustomerControllerFindOne(
        customer.id,
      )) as GfCustomerDtoWithLogin;

      if (!remoteCustomer) {
        console.log('mobile customer location.reload');
        localStorage.removeItem('customer');
        window.location.reload();
      } else {
        /**
         * "We use this to check if a user is logged in. The access_token is generated when the user logs in for the first time, and we store it in the customer object in local storage."
         */
        let loggedInCustomer = await apiService.appControllerGetRequestUser({
          headers: {
            access_token: customer?.access_token,
          },
        });

        /**
         * if login success, it will update customer state and local storage
         */
        if (loggedInCustomer) {
          dispatch(
            setCustomer({
              ...loggedInCustomer,
              isLogin: true,
              access_token: customer?.access_token,
            }),
          );
        } else {
          dispatch(setCustomer(customer));
        }
        const isPushNotificationsAvailable =
          Capacitor.isPluginAvailable('PushNotifications');
        if (isPushNotificationsAvailable) {
          initPushNotification();
        }
        await apiService.gfCustomerControllerUpdate(
          {
            lastLocation: {
              type: 'Point',
              coordinates: [
                position.coords.latitude.changeDecimal(3),
                position.coords.longitude.changeDecimal(3),
              ],
            },
          },
          customer.id,
        );

        // dispatch(
        //   setCustomer({
        //     ...customer,
        //     gfFeedBack: customer?.gfFeedBack,
        //   }),
        // );
      }
    }
    try {
      //TODO: we need to migrate to GA4 since the universal google analytics will stop supported from google
      if (window.config.REACT_APP_IS_DEV === `false`) {
        ReactGA.initialize(window.config.REACT_APP_OLD_GOOGLE_ANALYTICS, {
          debug: false,
          gaOptions: {
            siteSpeedSampleRate: 100,
            userId: customer.id,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }

    /**
     * Native function to make the SplashScreen disappear
     */
    SplashScreen.hide();
  };
  React.useEffect(() => {
    /**
     * "When the user scrolls down, clicks into a detail flyer view, and then clicks 'go back', the app should return to the original position on the page."
     */
    let ScrollPosition: any = () => {
      if (window !== undefined && document !== null) {
        window.addEventListener('scroll', () => {
          const scrollY: number = window?.scrollY;
          switch (window.location.pathname) {
            case '/flyers':
              if (scrollY > 1) {
                dispatch(setExploreScrollPosition(scrollY));
              }
              break;
            case '/latest':
              if (scrollY > 1) {
                dispatch(setLatestFlyerPosition(scrollY));
              }

              break;

            case '/favourites':
              if (scrollY > 1) {
                dispatch(setFavoriteFlyerPosition(scrollY));
              }

              break;
            case '/upcoming':
              if (scrollY > 1) {
                dispatch(setUpComingFlyerPosition(scrollY));
              }

              break;
            case '/promotionlist':
              if (scrollY > 1) {
                dispatch(setBestDealsScrollPosition(scrollY));
              }
              break;

            default:
              break;
          }
          if (window?.location?.pathname.includes('/search/')) {
            if (scrollY > 1) {
              dispatch(setSearchScrollPosition(scrollY));
            }
          }

          Object.entries(state.flyerTagList).map(item => {
            if (window.location.pathname === `/flyer/${item[0]}`) {
              if (scrollY > 1) {
                dispatch(
                  setFlyerTagListPosition({
                    type: `${item[0]}`,
                    position: scrollY,
                  }),
                );
              }
            }
          });
        });
      }
    };
    ScrollPosition();

    return () => {
      ScrollPosition = null;
      if (window !== undefined) {
        window.removeEventListener('scroll', () => {});
      }
    };
  }, []);

  React.useEffect(() => {
    if (
      !history.location.pathname?.includes('/flyerview/') &&
      !history.location.pathname?.includes('/restaurantview/') &&
      !history.location.pathname?.includes('/storedetails/') &&
      !history.location.pathname?.includes('/search') &&
      !history.location.pathname?.includes('/promotiondetail')
    ) {
      if (state.searchDataList?.length || state.searchScrollPosition > 0) {
        dispatch(setSearchScrollPosition(0));
        dispatch(
          setSearchDataList({
            noMerge: true,
            result: [],
          }),
        );
        dispatch(setSearchDataNumber(0));
      }
    }
  }, [history?.location?.pathname]);
  const ready =
    state.customer.id !== undefined &&
    state.customer.gfPromotionFavouriteList.id !== undefined;

  if (!ready) {
    return <Spinner data-testid="app-test-id"></Spinner>;
  }
  /**
   * @Syed, TODO: please add comment
   */
  if (!redirectWait) {
    return <Spinner data-testid="app-test-id"></Spinner>;
  }

  return (
    <GoFlyerAppContext.Provider
      value={{ state, dispatch }}
      data-testid="app-test-id"
    >
      <ThemeProvider theme={theme}>
        <Helmet
          titleTemplate="GoFlyer - %s"
          defaultTitle="GoFlyer"
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta name="description" content="A GoFlyer application" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
          />
          <meta name="apple-mobile-web-app-capable" content="yes" />
        </Helmet>

        <div className="root_app">
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                return <InfoPage data-testid="app-test-id" />;
              }}
            />
            <Route
              exact
              path="/privacy-policy"
              render={() => {
                return <PrivacyPage data-testid="app-test-id privacy-policy" />;
              }}
            />
            <Route
              exact
              path="/terms-of-use"
              render={() => {
                return <TermsPage data-testid="app-test-id terms-of-use" />;
              }}
            />
            <Route>
              <MobileLayout>
                <Switch>
                  <Route
                    exact
                    path="/flyers"
                    render={() => {
                      return <FlyerListPage data-testid="app-test-id flyers" />;
                    }}
                  />
                  <Route
                    exact
                    path="/flyer/:tag"
                    render={() => {
                      return (
                        <FlyerTagListPage data-testid="app-test-id flyer tag" />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/latest"
                    render={() => {
                      return (
                        <FlyerLatestPage data-testid="app-test-id latest" />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/upcoming"
                    render={() => {
                      return (
                        <FlyerUpComingPage data-testid="app-test-id upcoming" />
                      );
                    }}
                  />
                  {/*
                  we keep this as a legacy since we have some url on google that looks like: http://localhost:3001/storedetails/77f8d3e6-a0c2-490f-8d47-dbefe72b2121 so when you use storeId, use the new path: http://localhost:3001/storedetails/77f8d3e6-a0c2-490f-8d47-dbefe72b2121 
                   */}
                  <Route
                    path="/storedetails/:id"
                    render={() => {
                      return (
                        <div data-testid="app-test-id storedetails id">
                          <NewStoreUi />
                        </div>
                      );
                    }}
                  />
                  {/* 
                    don't use below route anymore
                  */}
                  <Route
                    path="/storedetails"
                    render={() => {
                      return (
                        <div data-testid="app-test-id storedetails">
                          <NewStoreUi />
                        </div>
                      );
                    }}
                  />

                  <Route
                    path="/mapstore"
                    render={() => {
                      return (
                        <div data-testid="app-test-id mapstore">
                          <MapStorePage />
                        </div>
                      );
                    }}
                  />
                  <Route
                    path="/promotionlist"
                    render={() => {
                      return (
                        <PromotionListPage data-testid="app-test-id promotionlist" />
                      );
                    }}
                  />
                  <Route
                    path="/promotiondetail"
                    render={() => {
                      return (
                        <div data-testid="app-test-id promotiondetail">
                          <NewPromotionDetailPage />
                        </div>
                      );
                    }}
                  />
                  <Route
                    path="/flyerview/:id"
                    render={() => {
                      return (
                        <div data-testid="app-test-id flyerview id">
                          <FlyerView />
                        </div>
                      );
                    }}
                  />
                  <Route
                    path="/restaurantview/:id"
                    render={() => {
                      return (
                        <div data-testid="app-test-id restaurantview id">
                          <FlyerViewMenu />
                        </div>
                      );
                    }}
                  />

                  <Route
                    path="/shoppinglist"
                    render={() => {
                      return (
                        <div data-testid="app-test-id shoppinglist">
                          <ShoppingListPage />
                        </div>
                      );
                    }}
                  />

                  <Route
                    path="/favourites"
                    render={() => {
                      return (
                        <FavouritesStoreListPage data-testid="app-test-id favourites" />
                      );
                    }}
                  />
                  <Route
                    path="/search/:term"
                    render={() => {
                      return (
                        <div data-testid="app-test-id search term">
                          <SearchPage />
                        </div>
                      );
                    }}
                  />
                  {/* 
                  path="/search" is a legacy, should be replaced by path="/search/:term"
                   */}
                  <Route
                    path="/search"
                    render={() => {
                      return (
                        <div data-testid="app-test-id search">
                          <SearchPage />
                        </div>
                      );
                    }}
                  />

                  <Route
                    path="/about"
                    render={() => {
                      return (
                        <div data-testid="app-test-id about">
                          <AboutPage />
                        </div>
                      );
                    }}
                  />
                  <Route
                    path="/signin"
                    render={() => {
                      return (
                        <div data-testid="app-test-id signin">
                          <SigninPageMobile />
                        </div>
                      );
                    }}
                  />

                  <Route
                    render={() => {
                      return (
                        <div data-testid="app-test-id">
                          <NotFoundPage />
                        </div>
                      );
                    }}
                  />
                </Switch>
              </MobileLayout>
            </Route>
            <Route
              path="/login"
              render={() => {
                return <LoginPage data-testid="app-test-id login" />;
              }}
            />
            <Route
              path="/userprofile"
              render={() => {
                return (
                  <UserProfilePage data-testid="app-test-id userprofile" />
                );
              }}
            />
          </Switch>
        </div>

        <GlobalStyle />
      </ThemeProvider>
    </GoFlyerAppContext.Provider>
  );
}
