// Path: ./src/utils/fireBaseRemoteConfig.ts
import { initializeApp } from '@firebase/app';
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from '@firebase/remote-config';
import { setRemoteConfig } from 'app/store/reducer';
import { getAuth } from 'firebase/auth';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuthApp = getAuth(firebaseApp);

/**
* This code utilizes Firebase Remote Config as a feature gatekeeper.
* By setting a value in the remote config, we can control whether a feature is available to users.
* For instance, if we add a new feature but don't want to release it publicly yet, we can set
the corresponding value in Firebase Remote Config. Once we are ready to release the feature,
we can update the value in Firebase, and users will then be able to access it.
*/
export const firebaseRemoteConfig = async (dispatch: any) => {
  try {
    // TODO: this initializeApp is called also in the sign in page, not sure if we can refactor this part
    initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig();
    remoteConfig.settings.minimumFetchIntervalMillis = 300000;
    await fetchAndActivate(remoteConfig);
    const getSmallImagesCondition: any = getValue(
      remoteConfig,
      'getSmallImages',
    );
    dispatch(
      setRemoteConfig({
        remoteConfig: {
          getSmallImagesCondition: getSmallImagesCondition._value,
        },
      }),
    );
  } catch (error) {
    console.log('getSmallImagesCondition error', error);
  }
};
