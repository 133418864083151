// Path: ./src/app/components/FlyerDetailView/TopDivStyle.ts
import styled from 'styled-components/macro';
import { styled as stlyedMUI } from '@mui/material/styles';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FavoriteIcon from '@mui/icons-material/Favorite';

export const Img = styled.img`
  height: 60px !important;
  width: 60px !important;
  margin-left: -7px;
  object-fit: contain;
`;
export const TopDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 16px;
`;
export const LeftDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;
export const SubLeftDiv = styled.div`
  margin-left: 8px;
`;
export const Heading = styled.h4`
  margin: 0px;
  text-align: left;
  font: normal normal 600 17px/22px SFProText;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: -4px;
`;
export const SubText = styled.span`
  text-align: left;
  font: normal normal normal 13px/20px SFProText;
  letter-spacing: 0px;
  color: #757575;
  opacity: 1;
`;

export const MuiFiberManualRecordIcon = stlyedMUI(FiberManualRecordIcon)(
  () => ({
    color: '#999999',
    fontSize: '3px',
  }),
);
export const MuiFavoriteBorderIcon = stlyedMUI(FavoriteBorderIcon)(() => ({
  color: ' #999999 ',
  fontSize: '28px',
}));
export const MuiFavoriteIcon = stlyedMUI(FavoriteIcon)(() => ({
  color: '#FF453A',
  fontSize: '28px',
}));
