// Path: ./src/app/components/StoreUiFlyer/styled.ts
import styled from 'styled-components/macro';
import { styled as stlyedMUI } from '@mui/material/styles';
import { Button } from '@mui/material';

export const MuiButton = stlyedMUI(Button)(() => ({
  font: 'normal normal 600 16px/22px SFProText',
  textTransform: 'capitalize',
  letterSpacing: '0px',
  color: '#000000',
  opacity: '1',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 3px 12px #00000040',
  borderRadius: '7px',
  border: '0px',
  MinHeight: '29px',
  marginBottom: '10px',
  '@media (max-width:320px)': {
    width: '110px !important',
  },
}));
// const MuiSaleButton = stlyedMUI(Button)(() => ({
//   background: '#FF9500 0% 0% no-repeat padding-box',
//   boxShadow: '0px 3px 12px #00000040',
//   borderRadius: '7px',
//   border: '0px',
//   height: '29px',
//   textTransform: 'capitalize',
//   font: 'normal normal 600 16px/22px SFProText',
//   letterSpacing: '0px',
//   color: '#FFFFFF',
//   textTransfrm: 'capitalize',
//   '@media (max-width:320px)': {
//     width: '110px !important',
//   },
// }));
export const FlyerDiv = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000040;
  border-radius: 20px;
  opacity: 1;
  height: 212px;
  max-width: 365px;
  margin: auto;

  margin-top: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BottomDiv = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
`;
export const SubBottomDiv = styled.div`
  width: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 0px;
`;

export const SalesBtnDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 19px;
  justify-content: end;
  align-items: flex-end;
`;
