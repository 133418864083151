// Path: ./src/app/desktop-src/app/pages/InfoPage/index.tsx
/**
 *
 * InfoPage
 *
 */
import { TobNavBar } from './TopNav';
import * as React from 'react';
import styled from 'styled-components/macro';
import { TopInfoContent } from './TopContent';
import { FiftInfoContent } from './FifthContent';
import { BottomInfoContent } from './BottomContent';
import { FooterInfo } from './Footer';
import SideImage from '../../assets/SiteMain1.png';
import SideImage2 from '../../assets/FirstInfoimage1.png';

import { CommonInfoContent } from './CommanContent';
import i18n from 'i18next';
import { messages } from 'locales/messages';
import { useHistory } from 'react-router-dom';
import { handleDeviceRedirect } from 'utils/handleDeviceRedirect';
import { useDispatch } from 'react-redux';
import CustomHelmet from 'app/components/Helmet';

interface Props {}

export function InfoPage(props: Props) {
  const history = useHistory();
  const { t, language } = i18n;

  const dispatch = useDispatch();
  handleDeviceRedirect(history, dispatch);
  const getVideo = () => {
    if (language === 'en') {
      return 'https://d280crdu6dot2n.cloudfront.net/website_assets/1493_1718922494_goflyer_info_page_English.mp4';
    } else if (language === 'zh') {
      return 'https://d280crdu6dot2n.cloudfront.net/website_assets/1494_1718922498_goflyer_info_page_Chinese.mp4';
    }
  };
  const InfoList = [
    {
      Id: 'TopInfoContent',
      right: false,
      bgColor: '#f3ece2',
      title: t(messages.Info_TopContent_Title()),
      subtitle: `${t(messages.Info_TopContent_Subtitle())}`,
      image: SideImage,
      buttonText: t(messages.Start_Saving_Today()),
      testid: 'Start Saving Today',
      Link: '/flyers',
    },
    {
      Id: 'SecondInfoContent',
      right: true,
      bgColor: '#FFFFFF',
      title: t(messages.Info_ContentOne_Title()),
      subtitle: t(messages.Info_ContentOne_Subtitle()),
      image: SideImage2,
      video: getVideo(),
      buttonText: t(messages.Check_Out_The_latest_Flyers()),
      testid: 'Check Out The latest Flyers',
      Link: '/flyers',
    },
    {
      Id: 'ThirdInfoContent',
      right: false,
      bgColor: '#f3ece2',
      title: t(messages.Info_ContentOne_Title()),
      subtitle: t(messages.Info_ContentOne_Subtitle()),
      image: `${window.config.REACT_APP_CLOUD_FRONT_PROD}/images/SecondInfoImage.webp`,
      buttonText: t(messages.Browse_Exclusive_Deals()),
      testid: 'Browse Exclusive Deals',
      Link: '/promotionlist',
    },
    {
      Id: 'FourthInfoContent',
      right: true,
      bgColor: '#FFFFFF ',
      title: t(messages.Info_ContentThree_Title()),
      subtitle: t(messages.Info_ContentThree_Subtitle()),
      image: `${window.config.REACT_APP_CLOUD_FRONT_PROD}/images/ThirdInfoImage.webp`,
      buttonText: t(messages.See_Available_Stores()),
      testid: 'See Available Stores',
      Link: '/flyers',
    },
    {
      Id: 'FifthInfoContent',
      right: false,
      bgColor: '#f3ece2',
      title: t(messages.Info_ContentFourth_Title()),
      subtitle: t(messages.Info_ContentFourth_Subtitle()),
      image: `${window.config.REACT_APP_CLOUD_FRONT_PROD}/images/FourthInfoImage1.png`,
      video:
        'https://goflyer-app-image.s3.ca-central-1.amazonaws.com/images/file.mp4',
      buttonText: t(messages.Start_Browsing_Deals()),
      testid: 'Start Browsing Deals',
      Link: '/promotionlist',
    },
  ];
  return (
    <Div data-testid="infopage-test-id">
      <CustomHelmet
        title={`Goflyer `}
        description={t(messages.homeDescription())}
        keywords={t(messages.homeKeywords())}
      />
      <TobNavBar />
      {InfoList.map(value => {
        if (value.Id === 'TopInfoContent') {
          return (
            <TopInfoContent
              testid={value.testid}
              Id={value.Id}
              key={value.Id}
              title={value.title}
              subtitle={value.subtitle}
              image={value.image}
              buttonText={value.buttonText}
              backgroudColor={value.bgColor}
              Link={value.Link}
              data-testid="infopage-test-id"
            />
          );
        } else {
          return (
            <CommonInfoContent
              Id={value.Id}
              key={value.Id}
              title={value.title}
              subtitle={value.subtitle}
              image={value.image}
              buttonText={value.buttonText}
              backgroudColor={value.bgColor}
              rightSide={value.right}
              Link={value.Link}
              video={value.video}
              data-testid="infopage-test-id"
            />
          );
        }
      })}
      <FiftInfoContent />
      <BottomInfoContent />
      <FooterInfo />
    </Div>
  );
}

const Div = styled.div`
  overflow-x: hidden;
`;
