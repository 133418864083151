// Path: ./src/app/desktop-src/app/pages/FlyerDetailPage/Loadable.ts
/**
 *
 * Asynchronously loads the component for FlyerDetailPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const FlyerDetailPage = lazyLoad(
  () => import('./index'),
  module => module.FlyerDetailPage,
);
