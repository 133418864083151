// Path: ./src/index.tsx
/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import reportWebVitals from 'reportWebVitals';
// Initialize languages
import './locales/i18n';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'utils/globalPrototype';
import { Provider } from 'react-redux';
import { configureAppStore } from 'app/desktop-src/store/configureStore';
import { DeskTopApp } from 'app/desktop-src/app';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem) && window.innerWidth < 800;
  });
}
try {
  if (window.location.host === `goflyer.ca`) {
    Sentry.init({
      dsn: window.config.REACT_APP_SENTRY,
      integrations: [new BrowserTracing(), new Sentry.Replay()],
      /**
       * add this because we didn't pay sentry. we want to see if error is still happening. Now, it stop capturing error due to usage limit
       */
      sampleRate: 0.1,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.3,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
    });
  }
} catch (error) {
  console.error(error);
}

if (detectMob()) {
  ReactDOM.render(
    <HelmetProvider>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </HelmetProvider>,
    MOUNT_NODE,
  );
} else {
  const store = configureAppStore();
  if (MOUNT_NODE.hasChildNodes()) {
    ReactDOM.hydrate(
      <Provider store={store}>
        <HelmetProvider>
          <React.StrictMode>
            <DeskTopApp />
          </React.StrictMode>
        </HelmetProvider>
      </Provider>,
      MOUNT_NODE,
    );
  } else {
    ReactDOM.render(
      <Provider store={store}>
        <HelmetProvider>
          <React.StrictMode>
            <DeskTopApp />
          </React.StrictMode>
        </HelmetProvider>
      </Provider>,
      MOUNT_NODE,
    );
  }
}

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
