// Path: ./src/app/components/FlyerViewImgeList/index.tsx
import { GfImageDto } from '@swagger/typescript-fetch-goflyer';
import React from 'react';

/**
 * for ios, if image is too large or too long, it will become blurry. So we cut all image to half and load split image.
 * @param gfImages
 * @param onImageLoad this is to make sure all image finish loading, then display the flyer.
 * otherwise, users will start zooming or moving flyers before it finish loading, zooming and moving will be wrong
 * @returns
 */
export const FlyerViewImageList = (
  gfImages: Array<GfImageDto> | undefined,
  onImageLoad: () => void,
) => {
  return gfImages?.map(value => {
    let ImageView;
    if (value.gfSplitImages && value.gfSplitImages.length > 0) {
      ImageView = value.gfSplitImages.map(splitImageValue => {
        return (
          <img
            crossOrigin="anonymous"
            key={splitImageValue.id}
            onLoad={onImageLoad}
            style={{ width: '100%' }}
            className={`Flyer-Image`}
            alt="tst"
            src={
              splitImageValue.src + '?createdAt=' + splitImageValue.createdAt
            }
            data-testid="flyerviewimagelist-test-id"
          />
        );
      });
    } else {
      ImageView = (
        <img
          crossOrigin="anonymous"
          key={value.id}
          onLoad={onImageLoad}
          style={{ width: '100%' }}
          className={`Flyer-Image`}
          alt="tst"
          src={value.src}
        />
      );
    }
    return ImageView;
  });
};

/**
 * we are not using this for now. We might use it in the future.
 * the idea is that we split image into grid so that we can load those image faster.
 * later, we realize the bottle neck is not about downloading the image. but the api query to fetch the flyer. we optimize the query and now it is fast enough even with high resolution flyer image
 * @param gfImages
 * @param onImageLoad
 * @returns
 */
export const GridSplitFlyerViewImageList = (
  gfImages: Array<GfImageDto> | undefined,
  onImageLoad: () => void,
) => {
  return gfImages?.map(gfImage => {
    let ImageView;
    if (gfImage.gfSplitImages && gfImage.gfSplitImages.length > 0) {
      ImageView = gfImage.gfSplitImages.map(splitImageValue => {
        return (
          <img
            crossOrigin="anonymous"
            key={splitImageValue.id}
            onLoad={onImageLoad}
            style={{ width: '100%' }}
            className={`Flyer-Image`}
            alt="tst"
            src={splitImageValue.src}
            data-testid="gridsplitflyerviewimagelist-test-id"
          />
        );
      });
    } else {
      ImageView = (
        <img
          crossOrigin="anonymous"
          key={gfImage.id}
          onLoad={onImageLoad}
          style={{ width: '100%' }}
          className={`Flyer-Image`}
          alt="tst"
          src={gfImage.src}
        />
      );
    }
    return (
      <div
        style={{
          width: '100%',
          display: 'grid',
          gridTemplateRows: `repeat(${gfImage.splitRows}, 1fr)`,
          gridTemplateColumns: `repeat(${gfImage.splitCols}, 1fr)`,
        }}
        data-testid="gridsplitflyerviewimagelist-test-id"
      >
        {ImageView}
      </div>
    );
  });
};

/**
 * we need to get total number of image because we only apply hammerjs after the image finish loading.
 * because hammerjs need to calculate the initial width and height in order to apply zoom in and out translate
 * @param gfImages
 * @returns
 */
export const getTotalSplitedImageLength = (gfImages: Array<GfImageDto>) => {
  let count = 0;
  gfImages?.map(value => {
    if (value.gfSplitImages && value.gfSplitImages.length > 0) {
      count += value.gfSplitImages.length;
    } else {
      count += 1;
    }
  });
  return count;
};
