// Path: ./src/app/components/SearchSuggest/Loadable.ts
/**
 *
 * Asynchronously loads the component for SearchSuggest
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SearchSuggest = lazyLoad(
  () => import('./index'),
  module => module.SearchSuggest,
);
