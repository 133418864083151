// Path: ./src/app/store/context.ts
// reference: https://github.com/hellomuthu23/react-context-example
import React from 'react';
import { GoFlyerAppState, initialGoFlyerState } from './state';
import { GoFlyerAppActions } from './actions';

export const GoFlyerAppContext = React.createContext<{
  state: GoFlyerAppState;
  dispatch: React.Dispatch<GoFlyerAppActions>;
}>({
  state: initialGoFlyerState,
  dispatch: () => undefined,
});
