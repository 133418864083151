// Path: ./src/app/desktop-src/app/pages/PromotionDetailRedirect/Loadable.ts
/**
 *
 * Asynchronously loads the component for PromotionDetailRedirect
 *
 */

import { lazyLoad } from 'utils/loadable';

export const PromotionDetailRedirect = lazyLoad(
  () => import('./index'),
  module => module.PromotionDetailRedirect,
);
