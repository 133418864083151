// Path: ./src/app/components/ShoppingListViewModal/index.tsx
/**
 *
 * ShoppingListViewModal
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { styled as stlyedMUI } from '@mui/material/styles';

import Modal from '@mui/material/Modal';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { messages } from 'locales/messages';

const MuiGrid = stlyedMUI(Grid)(() => ({
  flexGrow: 1,
  '@media (max-width:375px)': {
    justifyContent: 'center',
  },
}));

const MuiModal = stlyedMUI(Modal)(() => ({
  backgroundColor: 'transparent !important',
}));
const MuiButton = stlyedMUI(Button)(() => ({
  background: '#cce4ff 0% 0% no-repeat padding-box',
  borderRadius: '13px',
  height: '27px',
  color: '#007aff',
  textAlign: 'left',
  font: 'normal normal  16px/22px SFProText',
  letterSpacing: '0px',
  opacity: '1 !important',
}));

interface Props {}
const ImageData = [
  {
    id: 1,
    src: 'https://images.pexels.com/photos/90946/pexels-photo-90946.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
  },
  {
    id: 2,
    src: 'https://images.pexels.com/photos/90946/pexels-photo-90946.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
  },
  {
    id: 3,
    src: 'https://images.pexels.com/photos/90946/pexels-photo-90946.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
  },
];
export const ShoppingListViewModal: React.FunctionComponent<Props> = memo(
  () => {
    const { t } = useTranslation();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [open, setOpen] = React.useState(false);

    // const handleOpen = () => {
    //   setOpen(true);
    // };

    const handleClose = () => {
      setOpen(false);
    };
    return (
      <Div>
        <MuiModal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalWrapper>
            <ModalBody>
              <TopDiv>
                <Heading>{t(messages.Shopping_List())}</Heading>
                <MuiButton>View</MuiButton>
              </TopDiv>
              <BottomDiv>
                <MuiGrid container spacing={2}>
                  {ImageData?.map((value, key) => {
                    return (
                      <Grid key={key} item>
                        <Image
                          key={key}
                          style={{ backgroundImage: `url(${value.src})` }}
                        />
                      </Grid>
                    );
                  })}
                </MuiGrid>
              </BottomDiv>
            </ModalBody>
          </ModalWrapper>
        </MuiModal>
      </Div>
    );
  },
);

const Div = styled.div``;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const ModalBody = styled.div`
  border: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000033;
  border-radius: 20px;
  opacity: 1;
  min-height: 142px;
  margin: auto;
  min-width: 365px;
  bottom: 38px;
  padding: 11px;
  padding-left: 18px;
  padding-right: 18px;
  position: 'absolute';
`;

const TopDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Heading = styled.div`
  margin: 0px;
  font: normal normal 600 16px/22px SFProText;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;
const BottomDiv = styled.div`
  border-top: 1px solid #80808012;
  display: flex;
  margin-top: 7px;
  padding-top: 11px;
`;
const Image = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  height: 74px;
  width: 74px;
`;
