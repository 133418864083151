// Path: ./src/app/components/HeaderA/index.tsx
/**
 *
 * HeaderA
 *
 */
import React, { memo } from 'react';

import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/GoFlyer_Logo_2022Feb-10.png';

import { Box, Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useLocation, useHistory } from 'react-router-dom';
import { messages } from 'locales/messages';
import { DownloadBanner } from '../DownloadBanner';
import styled from 'styled-components';
import { CategoryList } from '../CategoryList';
import { Capacitor } from '@capacitor/core';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { setMenuSideBar, setShowBanner } from 'app/store/reducer';
import { GoFlyerAppContext } from 'app/store/context';
import * as analytics from '../../../utils/analytics';
import { styled as stlyedMUI } from '@mui/material/styles';
import { LanguageSwitcher } from 'app/mobile-desktop-common-components/LanguageSwitcher';

interface Props {}

export const HeaderA: React.FunctionComponent<Props> = memo(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { state, dispatch } = React.useContext(GoFlyerAppContext);
  const [prevLocation, setPrevLocation] = React.useState('');
  const handleCloseBanner = () => {
    analytics.click_close_download_banner_mobile();
    dispatch(setShowBanner(false));
  };

  React.useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      dispatch(setShowBanner(false));
    } else {
      dispatch(setShowBanner(true));
    }
  }, []);

  const handleSideMenu = () => {
    analytics.click_side_bar_menu();
    dispatch(setMenuSideBar(true));
  };

  history.listen(nextLocation => {
    setPrevLocation(nextLocation.pathname);
  });
  return (
    <>
      <NavWrapper className="HeaderANavWrapper">
        {state.showBanner && <DownloadBanner handleClose={handleCloseBanner} />}
        <NavBar
          style={{
            marginTop: state.showBanner ? 0 : 'env(safe-area-inset-top)',
          }}
          data-testid="Top Header"
        >
          {location.pathname === '/flyerview' ||
          location.pathname === '/promotiondetail' ||
          location.pathname === '/storedetails' ||
          location.pathname.includes('flyerview') ||
          location.pathname.includes('restaurantview') ? (
            <Box>
              <MuiButton
                data-testid="Back"
                onClick={() => {
                  analytics.click_back_button();
                  if (prevLocation === '') {
                    history.push('/flyers');
                  } else {
                    history.goBack();
                  }
                }}
                startIcon={<MuiChevronLeftIcon />}
              >
                {t(messages.Back())}
              </MuiButton>
            </Box>
          ) : (
            <MuiIconButton
              data-testid="head A handle side menu button"
              onClick={handleSideMenu}
            >
              <MenuIcon fontSize="large" />
            </MuiIconButton>
          )}

          <Toolbar>
            {/* <Typography variant={'h3'} className={`${classes.title} title`}>
            GoFlyer
          </Typography> */}
            <img
              src={logo}
              alt="logo"
              className="logo"
              style={{ height: '47px' }}
            />
          </Toolbar>
          {location.pathname !== '/flyerview' ? (
            <LanguageContainer>
              <LanguageSwitcher></LanguageSwitcher>
            </LanguageContainer>
          ) : (
            <LangDiv />
          )}
        </NavBar>
        {(location.pathname === '/flyers' ||
          location.pathname === '/promotionlist' ||
          location.pathname === '/favourites' ||
          location.pathname === '/latest' ||
          location.pathname === '/restaurant' ||
          location.pathname === '/upcoming' ||
          location.pathname.includes('flyer/restaurant') ||
          location.pathname.includes('grocery') ||
          location.pathname.includes('chinesesupermarket') ||
          location.pathname.includes('electricalAppliances')) && (
          <CategoryList showBanner={state.showBanner}></CategoryList>
        )}
      </NavWrapper>
      {state.showBanner && <NavBottom />}
    </>
  );
});

const MuiButton = stlyedMUI(Button)(() => ({
  color: '#007AFF',
  textAlign: 'left',
  fontSize: '17px',
  fontWeight: 400,
  letterSpacing: '-0.41px',
  opacity: '1',
  fontFamily: 'SFProText',
  textTransform: 'capitalize',
}));

const MuiChevronLeftIcon = stlyedMUI(ChevronLeftIcon)(() => ({
  fontSize: '39px !important',
  marginRight: '-16px !important',
}));

const MuiIconButton = stlyedMUI(IconButton)(() => ({
  color: '#ff9500',
  marginLeft: '5px',
}));
const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  background-color: white !important;
  z-index: 99;
  position: fixed;
`;

const NavBottom = styled.div`
  height: 90px;
`;

const LanguageContainer = styled.div`
  margin-right: 12px;
`;

const LangDiv = styled.div`
  width: 82px;
`;
