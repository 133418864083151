// Path: ./src/utils/cookieUtil.ts

/**
 * Retrieves the value of a specified cookie.
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string | null} - The cookie's value if found, otherwise `null`.
 */
export function getCookieValue(name: string): string | null {
  const match = document.cookie.match(
    new RegExp('(^|;)\\s*' + name + '=([^;]*)'),
  );
  return match ? decodeURIComponent(match[2]) : null;
}

/**
 * Sets a cookie with a specified expiration time.
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value to store in the cookie.
 * @param {number} expirationMs - The expiration time in milliseconds from the current time.
 * @param {string} [path='/'] - The path attribute of the cookie.
 * @param {string} [domain] - The domain attribute of the cookie, if applicable.
 */
export function setCookie(
  name: string,
  value: string,
  expirationMs: number,
  path: string = '/',
  domain?: string,
) {
  const expires = new Date(Date.now() + expirationMs).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value,
  )}; expires=${expires}; path=${path}; ${domain ? `domain=${domain};` : ''}`;
}

/**
 * Deletes a specified cookie by setting its expiration date in the past.
 * @param {string} name - The name of the cookie to delete.
 * @param {string} [path='/'] - The path attribute of the cookie.
 * @param {string} [domain] - The domain attribute of the cookie, if applicable.
 */
export function deleteCookie(
  name: string,
  path: string = '/',
  domain?: string,
) {
  try {
    document.cookie =
      name +
      '=; ' +
      'expires=Thu, 01 Jan 1970 00:00:01 GMT; ' +
      'path=' +
      path +
      '; ' +
      (domain ? 'domain=' + domain + '; ' : '');
  } catch (error) {
    console.error(error);
  }
}
