// Path: ./src/app/components/NewStoreUiMoveAbleComp/index.tsx
/**
 *
 * NewStoreUiMoveAbleComp
 *
 */
import React, { memo, useState } from 'react';
import styled from 'styled-components/macro';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { styled as stlyedMUI } from '@mui/material/styles';

import FavoriteIcon from '@mui/icons-material/Favorite';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import { messages } from 'locales/messages';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import Hammer from 'react-hammerjs';
import './style.css';
import PhoneIcon from '@mui/icons-material/Phone';
import { EmptyStoreUi } from '../EmptyStoreUi/Loadable';
interface Props {
  Logo?: string;
  Name?: string;
  Description?: string;
  onClickFav?: any;
  StoreInFavList?: GfStoreDto;
  GoogleStar?: number;
  Distance?: string;
  OpenTime?: string;
  CloseTime?: string;
  StoreFlyerCardList?: JSX.Element[];
  StorePromotionCardList?: JSX.Element[];
  openStatus?: string;
  PhoneNumber: string | undefined;
  address?: string;
  googleMapUrl?: string;
}

export const NewStoreUiMoveAbleComp: React.FunctionComponent<Props> = memo(
  props => {
    const [swipeCheck, setSwipeCheck] = useState<number>(0);
    const [cardCss, setCardCss] = React.useState<{
      y: number;
      overflow: string;
    }>({
      y: 400,
      overflow: 'hidden',
    });

    const { t } = useTranslation();
    const onSwipe = (e: HammerInput) => {
      if (e && e.deltaY < 0 && swipeCheck === 0) {
        setSwipeCheck(1);
        setCardCss({
          y: 100,
          overflow: 'scroll',
        });
      }
      if (e && e.deltaY > 0 && swipeCheck === 1) {
        setSwipeCheck(0);
        setCardCss({
          y: 400,
          overflow: 'scroll',
        });
      }

      if (e && e.deltaY > 0 && swipeCheck === 0) {
        setSwipeCheck(-1);
        setCardCss({
          y: 600,
          overflow: 'scroll',
        });
      }
      if (e && e.deltaY < 0 && swipeCheck === -1) {
        setSwipeCheck(0);
        setCardCss({
          y: 400,
          overflow: 'scroll',
        });
      }
    };

    return (
      <>
        <CardDiv
          className={`CardDiv`}
          style={{
            transition: `transform 0.3s`,
            transform: `translate3d(0px, ${cardCss.y}px, 0px)`,
          }}
        >
          <Hammer
            direction={`DIRECTION_VERTICAL`}
            onSwipe={onSwipe}
            options={{
              recognizers: {
                pan: { enable: false },
                pinch: { enable: false },
                rotate: { enable: false },
              },
            }}
          >
            <div>
              <TopLine></TopLine>
              <TopDiv
                data-testid="new store ui moveable comp top div"
                className={`newStoreUITopDiv`}
              >
                <LeftDiv
                  data-testid="new store ui moveable comp left div"
                  className={`newStoreUILeftdiv`}
                >
                  <Img
                    data-testid="new store ui moveable comp left image"
                    src={props.Logo}
                    alt={props.Name}
                  />
                  <SubLeftDiv
                    data-testid="new store ui moveable comp sub left div"
                    className={`newStoreUISubLeftDiv`}
                  >
                    <Heading data-testid="new store ui moveable comp heading">
                      {props.Name}
                    </Heading>
                    <SubText data-testid="new store ui moveable comp sub text">
                      {props.Description}
                    </SubText>
                    <SubTextAddress
                      data-testid="new store ui moveable component sub address"
                      onClick={() => {
                        if (props.googleMapUrl) {
                          window.open(props.googleMapUrl);
                        }
                      }}
                    >
                      {props?.address}
                    </SubTextAddress>
                  </SubLeftDiv>
                </LeftDiv>
                <div className={`newStoreUIIconButtonDiv`}>
                  <IconButton
                    data-testid="new store ui moveable component click fav icon button"
                    onClick={props.onClickFav}
                  >
                    {props.StoreInFavList === undefined ? (
                      <MuiFavoriteBorderIcon />
                    ) : (
                      <MuiFavoriteIcon />
                    )}
                  </IconButton>
                </div>
              </TopDiv>
              <DetailDiv data-testid="new store ui moveable comp detail div">
                <a href={`tel:${props.PhoneNumber}`}>
                  <DetailSubDiv>
                    <DetailTitle data-testid="new store ui moveable comp detail title">
                      <PhoneIcon style={{ color: '#EF8200' }} />
                      <PhoneDot>...</PhoneDot>
                      <span data-testid="Call Us">{t(messages.Call_Us())}</span>
                    </DetailTitle>
                    <DetailSubTitle data-testid="Contact Store">
                      {t(messages.Contact_Store())}
                    </DetailSubTitle>
                  </DetailSubDiv>
                </a>
                <DetailSubDiv>
                  {props.Distance && (
                    <DetailTitle>
                      <LocalTaxiIcon style={{ color: '#EF8200' }} />
                      <span> {props.Distance + t(messages.km())}</span>
                    </DetailTitle>
                  )}
                  <DetailSubTitle data-testid="Distance">
                    {t(messages.Distance())}
                  </DetailSubTitle>
                </DetailSubDiv>
                <DetailSubDiv style={{ borderRight: '1px solid #7070705e ' }}>
                  <DetailTitle>
                    <AccessAlarmsIcon style={{ color: '#EF8200' }} />{' '}
                    <span> {props.openStatus}</span>
                  </DetailTitle>
                  <DetailSubTitle>
                    {props.OpenTime} - {props.CloseTime}
                  </DetailSubTitle>
                </DetailSubDiv>
              </DetailDiv>
            </div>
          </Hammer>
          <Scrolldiv>
            {props.StoreFlyerCardList?.length === 0 &&
              props.StorePromotionCardList?.length === 0 && (
                <EmptyDiv>
                  <EmptyStoreUi />
                </EmptyDiv>
              )}

            {props.StoreFlyerCardList}
            <div style={{ paddingBottom: 40 }}>
              {props.StorePromotionCardList}
            </div>
          </Scrolldiv>
        </CardDiv>
      </>
    );
  },
);

const EmptyDiv = styled.div`
  margin-top: 20px;
`;

const TopLine = styled.div`
  height: 5px;
  width: 80px;
  background-color: #9999997a;
  border-radius: 20px;
  margin: auto;
  margin-top: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const CardDiv: any = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000040;
  border-radius: 20px;
  opacity: 1;
  min-height: 650px;
  margin-top: -13px;
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  max-height: 83vh;
  position: relative;
  padding-bottom: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

const Scrolldiv: any = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  min-height: 193px;
  margin-top: -13px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  max-height: 83vh;
  position: relative;
  padding-bottom: 90px;
  margin-top: 10px;
`;

const Img = styled.img`
  height: 84px !important;
  width: 84px !important;
  // border-radius: 27px !important;
  border-radius: 44px !important;
  /* border: 1px solid #7070703d; */
`;
const TopDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 48px;
`;
const LeftDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;
const SubLeftDiv = styled.div`
  margin-left: 8px;
`;
const Heading = styled.h4`
  margin: 0px;
  text-align: left;
  font: normal normal 600 SFProDisplay;
  font-size: 22px/41px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: -4px;
  padding-bottom: 3px;
`;
const SubText = styled.span`
  text-align: left;
  font: normal normal 16px/19px SFProText;
  letter-spacing: 0px;
  color: #8a898e;
  opacity: 1;
`;
const SubTextAddress = styled.span`
  overflow: hidden;
  text-align: left;
  font: normal normal 12px SFProText;
  letter-spacing: 0px;
  color: #8a898e;
  opacity: 1;
`;
const DetailDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 47px;
  margin-bottom: -21px;
`;
const DetailSubDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-left: 1px solid #7070705e;
  height: 41px;
  margin-bottom: 32px;
  width: 127px;
`;
const DetailTitle = styled.h4`
  margin: 0px;
  font: normal normal 500 14px/18px SFProText;
  letter-spacing: 0px;
  color: #8e8d92;
  opacity: 1;
  display: flex;
  align-items: center;
`;
const DetailSubTitle = styled.h5`
  margin: 0px;
  color: var(--grey-system-grey-3-light);
  text-align: center;
  font: normal normal bold 11px/13px SFProText;
  letter-spacing: 0px;
  color: #c7c7cc;
  opacity: 1;
  margin-top: 3px;
`;

const PhoneDot = styled.span`
  position: relative;
  top: -8px;
  left: -14px;
  color: #ef8200;
  font-size: 18px;
  margin-right: -11px;
`;

const MuiFavoriteBorderIcon = stlyedMUI(FavoriteBorderIcon)(() => ({
  color: ' #999999 ',
  fontSize: '28px',
}));
const MuiFavoriteIcon = stlyedMUI(FavoriteIcon)(() => ({
  color: '#FF453A',
  fontSize: '28px',
}));
