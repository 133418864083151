// Path: ./src/app/pages/FlyerLatestPage/index.tsx
/**
 *
 * FlyerListPage
 *
 */
import React, { memo, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { apiService } from '../../../utils/api';
import { GfFlyerDto } from '@swagger/typescript-fetch-goflyer';
import { Spinner } from 'app/components/Spinner';
import { GoFlyerAppContext } from 'app/store/context';
import {
  checkIfStoreInFavList,
  getMyDistanceToStore,
  getFlyerStatusString,
  instanceOfFlyer,
  getSmallImageIfExist,
} from 'utils/dtoUtility';
import InfiniteScroll from 'react-infinite-scroller';
import { CardFlyerNew } from 'app/components/CardFlyerNew';
import { setLatestFlyerNumber, setLocation } from 'app/store/reducer';

import { setLatestFlyer } from 'app/store/reducer';
import { DownloadBanner } from 'app/components/DownloadBanner';
import { Capacitor } from '@capacitor/core';
import * as analytics from '../../../utils/analytics';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { useClickFavMobileFunc } from 'utils/commonFunctions/ClickFavButtonMobile';
import { sortFlyersFunc } from 'utils/sortFlyer';
import { Position } from '@capacitor/geolocation';
import { getPosition } from 'utils/getUserLocation';
import { GetUserLocation } from 'utils/LocationCheck';

export const FlyerLatestPage = memo(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();

  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const { state, dispatch } = React.useContext(GoFlyerAppContext);
  const [showBanner, setShowBanner] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    if (window !== undefined) {
      setTimeout(() => {
        window.scrollTo({ top: state.latestFlyerScrollPosition });
      }, 1);
    }
    if (Capacitor.isNativePlatform()) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, []);

  const fetchFlyers = async page => {
    const flyerLimit = 10;

    const FlyerResultList: any = await apiService.gfFlyerControllerFindAll(
      page,
      flyerLimit,
      JSON.stringify({
        orderBy: {
          updatedAt: 'DESC',
        },
      }),
      undefined,
    );
    return FlyerResultList;
  };

  const handleRefresh = async () => {
    setRefresh(true);
    setHasMore(false);
    const position: Position = await GetUserLocation(true);
    console.log(`pull to refresh new location`, position);
    dispatch(setLocation(position));
    const fetchResult = await fetchFlyers(1);
    if (fetchResult?.results?.length !== 0) {
      setHasMore(true);
    }
    const FlyerResponse: GfFlyerDto[] = sortFlyersFunc(fetchResult.results);
    dispatch(setLatestFlyerNumber(1));
    dispatch(
      setLatestFlyer({
        noMerge: true,
        result: FlyerResponse,
      }),
    );
    setRefresh(false);
  };

  const getFlyers = async page => {
    setHasMore(false);
    const FlyerResultList = await fetchFlyers(page);
    if (FlyerResultList?.results?.length !== 0) {
      setHasMore(true);
    }

    const FlyerResponse: GfFlyerDto[] = sortFlyersFunc(FlyerResultList.results);

    dispatch(
      setLatestFlyer({
        noMerge: false,
        result: FlyerResponse,
      }),
    );
  };

  async function loadFunc(_page) {
    if (!refresh) {
      dispatch(setLatestFlyerNumber(_page));
      getFlyers(_page);
    }
  }

  const clickFavButton = useClickFavMobileFunc(
    analytics.click_fav_button_in_flyer_card,
    true,
  );

  const FlyerList = state.latestFlyer?.map((latestFlyer, key) => {
    if (instanceOfFlyer(latestFlyer)) {
      const store_in_fav_list = checkIfStoreInFavList(
        latestFlyer.stores[0],
        state.customer.gfStoreFavouriteList,
      );

      return (
        <React.Fragment key={latestFlyer?.id}>
          <CardFlyerNew
            data-testid="flyer list page card flyer view"
            startingDate={latestFlyer.validStartDate}
            Logo={
              latestFlyer?.stores[0].merchant.merchantMapLargeLogo
                ? latestFlyer?.stores[0].merchant.merchantMapLargeLogo.src
                : latestFlyer?.stores[0].merchant.merchantLogo.src
            }
            distance={getMyDistanceToStore(
              latestFlyer.stores[0],
              state.location,
            )}
            signature={latestFlyer.stores[0].signature}
            name={latestFlyer.stores[0].merchant.name}
            description={latestFlyer.stores[0].merchant.description}
            storeFavList={store_in_fav_list}
            LeftImage={getSmallImageIfExist(
              latestFlyer.gfImages?.[0],
              state.remoteConfig.getSmallImagesCondition,
            )}
            RightImage={getSmallImageIfExist(
              latestFlyer.gfImages?.[1],
              state.remoteConfig.getSmallImagesCondition,
            )}
            daysLeft={getFlyerStatusString(latestFlyer)}
            price={latestFlyer.totalSave}
            onClick={e => {
              clickFavButton(
                e,
                latestFlyer.stores[0],
                store_in_fav_list !== undefined,
              );
            }}
            bottomLink={{
              path: `/flyerview/${latestFlyer.id}`,
              state: {
                storeId: latestFlyer.stores[0].id,
              },
            }}
            topLink={{
              path: `/storedetails`,
              search: `storeId=${latestFlyer.stores[0].id}`,
              storeId: `${latestFlyer.stores[0].id}`,
            }}
          />
        </React.Fragment>
      );
    }
  });

  if (FlyerList !== undefined && showBanner === true) {
    for (let i = 1; i < FlyerList.length; i++) {
      if (i % 10 === 0) {
        FlyerList?.splice(i, 0, <DownloadBanner inFlyer={true} />);
      }
    }
  }

  const spinner = <Spinner></Spinner>;
  return (
    <>
      <FlyerListContainer id="FlyerListPage">
        <PullToRefresh
          onRefresh={handleRefresh}
          pullingContent={<></>}
          className="PullRefreshCss"
        >
          {!refresh ? (
            <InfiniteScroll
              key={'scrolLkey'}
              css={{
                marginTop: '-95px',
                paddingBottom: '20px',
              }}
              threshold={500}
              pageStart={state.latestFlyerNumber}
              loadMore={loadFunc.bind(this)}
              hasMore={hasMore}
              loader={
                <div
                  style={{ textAlign: 'center', paddingBottom: '20px' }}
                  key={0}
                >
                  Loading ...
                </div>
              }
            >
              {FlyerList?.length === 0 ? (
                spinner
              ) : (
                <FlyerListWrapper>{FlyerList}</FlyerListWrapper>
              )}
            </InfiniteScroll>
          ) : (
            spinner
          )}
        </PullToRefresh>
      </FlyerListContainer>
    </>
  );
});

const FlyerListContainer = styled.div`
  margin-top: 95px;
  margin-bottom: 70px;
  padding-bottom: 92px;
`;
const FlyerListWrapper = styled.div`
  margin-top: 123px;
`;
