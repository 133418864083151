// Path: ./src/utils/localstorage.ts
const localstorageSet = function (key, value) {
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  localStorage.setItem(key, value);
};

const localstorageGet = function (key): any {
  // TODO: need check, try catch
  try {
    let result = localStorage.getItem(key) as string;
    result = JSON.parse(result) as any;
    return result;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export { localstorageGet, localstorageSet };
