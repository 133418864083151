// Path: ./src/utils/sortFlyer.ts
import { GfFlyerDto } from '@swagger/typescript-fetch-goflyer';

export const sortFlyersFunc = (FlyerResultList: GfFlyerDto[]) => {
  const result = FlyerResultList?.sort((a: GfFlyerDto, b: GfFlyerDto) => {
    let valueOfA = 1000;
    let valueOfB = 1000;
    if (a?.rank) {
      valueOfA = a?.rank;
    }
    if (b?.rank) {
      valueOfB = b?.rank;
    }
    return valueOfA - valueOfB;
  });

  return result;
};
