// Path: ./src/app/components/FlyerDetailView/TopDiv.tsx
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import { GoFlyerAppContext } from 'app/store/context';
import { checkIfStoreInFavList, getMyDistanceToStore } from 'utils/dtoUtility';
import { messages } from 'locales/messages';
import { Link } from 'react-router-dom';
import * as analytics from '../../../utils/analytics';
import { useClickFavMobileFunc } from 'utils/commonFunctions/ClickFavButtonMobile';
import IosShareIcon from '@mui/icons-material/IosShare';
import IconButton from '@mui/material/IconButton';
import { Device } from '@capacitor/device';
import {
  Img,
  TopDiv,
  LeftDiv,
  SubLeftDiv,
  Heading,
  SubText,
  MuiFiberManualRecordIcon,
  MuiFavoriteBorderIcon,
  MuiFavoriteIcon,
} from './TopDivStyle';
import useLocalizedDateFormatter from 'utils/hook/useLocalizedDateFormatter';
interface TopDivViewInterface {
  store: GfStoreDto;
  marginTop: number;
  validStartDate?: Date;
  validEndDate?: Date;
  handleShare?: (params: any) => Promise<void>;
}
export const TopDivView = (props: TopDivViewInterface) => {
  const { state } = React.useContext(GoFlyerAppContext);
  const [shareState, setShareState] = useState(false);
  const clickFavButton = useClickFavMobileFunc(
    analytics.click_fav_button_in_flyer_detail_page,
  );
  const { t } = useTranslation();
  const validStartDate = useLocalizedDateFormatter(props.validStartDate, {
    format: {
      month: 'short',
      day: 'numeric',
    },
  });
  const validEndDate = useLocalizedDateFormatter(props.validEndDate, {
    format: {
      month: 'short',
      day: 'numeric',
    },
  });

  const handleDeviceType = async () => {
    const info = await Device.getInfo();
    if ((info && info.platform === 'android') || info.platform === 'ios') {
      setShareState(true);
    } else {
      setShareState(false);
    }
  };

  useEffect(() => {
    handleDeviceType();
  }, []);

  return (
    <TopDiv
      style={{
        marginTop: props.marginTop,
      }}
      className="flyerViewTopDiv"
      data-testid="topdivview-test-id"
    >
      <LeftDiv>
        <Link
          data-testid="Flyer View Store Detail Link"
          to={{
            pathname: `/storedetails`,
            search: `storeId=${props.store.id}`,
          }}
        >
          <Img
            src={
              props.store.merchant.merchantMapLargeLogo
                ? props.store.merchant.merchantMapLargeLogo.src
                : props.store.merchant.merchantLogo.src
            }
            alt={props.store.merchant.name}
          />
        </Link>
        <SubLeftDiv>
          <Heading data-testid="Flyer View Store Merchant Name">
            {props.store.merchant.name}
          </Heading>
          <SubText>
            {/* {flyer?.placement.product.description} */}
            {'  '}
            <span>
              <MuiFiberManualRecordIcon />
            </span>
            {'  '}
            {getMyDistanceToStore(props.store, state.location)?.toFixed(1) +
              ` ` +
              t(messages.km())}
          </SubText>{' '}
          {validStartDate && validEndDate && (
            <SubText data-testid="Flyer View Valid Date">{` ${validStartDate} - ${validEndDate}`}</SubText>
          )}
        </SubLeftDiv>
      </LeftDiv>
      {shareState ? (
        <IconButton onClick={props.handleShare}>
          <IosShareIcon style={{ color: '#999999' }} />
        </IconButton>
      ) : (
        <></>
      )}
      <div
        data-testid="flyer view click fav button"
        onClick={e => {
          clickFavButton(
            e,
            props.store,
            checkIfStoreInFavList(
              props.store,
              state.customer.gfStoreFavouriteList,
            ) !== undefined,
          );
        }}
      >
        {checkIfStoreInFavList(
          props.store,
          state.customer.gfStoreFavouriteList,
        ) === undefined ? (
          <MuiFavoriteBorderIcon />
        ) : (
          <MuiFavoriteIcon />
        )}
      </div>
    </TopDiv>
  );
};
