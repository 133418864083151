// Path: ./src/app/components/TextInputPhoneNumberOrEmail/index.tsx
/**
 *
 * TextInputPhoneNumber
 *
 */
import { TextField } from '@mui/material';
import { styled as stlyedMUI } from '@mui/material/styles';

import { messages } from 'locales/messages';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  helperText?: string;
  error?: boolean;
  value?: string;
  setNumber?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
}

const MuiTextField = stlyedMUI(TextField)(() => ({
  '& input': { fontSize: 17 },
  '& label': { fontSize: 17, color: '#FF9500 !important' },
  '& .MuiInputBase-root': {
    borderRadius: 10,
  },
  '& fieldset': { borderColor: '#FF9500 !important' },
}));
export const TextInputPhoneNumberOrEmail: React.FunctionComponent<Props> = memo(
  props => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t, i18n } = useTranslation();

    return (
      <div>
        <MuiTextField
          data-testid="Email"
          onChange={props.setNumber}
          autoFocus
          type="email"
          error={props.error ? props.error : false}
          margin="dense"
          fullWidth
          className={` TextInputPhoneNumber`}
          id="outlined-basic"
          label={t(messages.Email())}
          variant="outlined"
          helperText={props.helperText}
          size="small"
        />
      </div>
    );
  },
);
