// Path: ./src/styles/global-styles.ts
import { createGlobalStyle } from 'styled-components';
import '@fontsource/roboto';
import { normalize } from 'styled-normalize';
// following this to use custom fons: https://blog.greenroots.info/3-quick-ways-to-add-fonts-to-your-react-app-ckjo6jbgd084w8ls1d7xp05qj

export const GlobalStyle = createGlobalStyle`

  ${normalize}
  html,

  body {
    /* height: 100%; */
    width: 100%;
    overscroll-behavior: none;
    touch-action: pan-y;
  }

  a {
    color: #0060B6;
    text-decoration: none;
  }

  a:hover {
    color:#00A0C6;
    text-decoration:none;
    cursor:pointer;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  

  #root {
   min-height: 100%;
    min-width: 100%;

  }

  p,
  label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }



  @media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  #root{

  }


  /* big landscape tablets, laptops, and desktops */
}

`;
