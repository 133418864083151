// Path: ./src/utils/hook/useLocalizedDateFormatter/index.ts
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Custom React hook for formatting a given date according to the user's locale(language) and specified options.
 *
 * @param {Date | undefined} date - The date to be formatted.
 * @param {Object} options - Additional formatting options.
 * @param {Intl.DateTimeFormatOptions} options.format - Date format options as defined in the ECMAScript Internationalization API.
 *   For more details, see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {string} options.fallbackLanguage - Fallback language in case the translation language is not available. Defaults to 'en' (English).
 *
 * @returns {string} - Formatted date string.
 */
const useLocalizedDateFormatter = (
  date?: Date,
  options: {
    /**
     * Date format options as defined in the ECMAScript Internationalization API.
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
     */
    format?: Intl.DateTimeFormatOptions;

    /**
     * Fallback language in case the translation language is not available.
     * Defaults to 'en' (English).
     */
    fallbackLanguage?: string;
  } = {},
): string => {
  const { i18n } = useTranslation();
  const {
    format = { month: 'short', day: 'numeric', year: 'numeric' },
    fallbackLanguage,
  } = options;
  const [formattedDate, setFormattedDate] = useState<string>('');

  useEffect(() => {
    if (date) {
      const isDate = new Date(date);

      if (isNaN(isDate.getTime())) {
        console.log('Invalid date provided.');
        return;
      }

      const languageToUse = i18n.language || fallbackLanguage || 'en'; // Fallback to English if no language is set
      const formatter = new Intl.DateTimeFormat(languageToUse, format);

      setFormattedDate(formatter.format(isDate));
    }
  }, [date, i18n.language, format, fallbackLanguage]);

  return formattedDate;
};

export default useLocalizedDateFormatter;
