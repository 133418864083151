// Path: ./src/utils/date/isDateGreaterOrEqualToDayAfterTomorrow.ts
export function isDateGreaterOrEqualToDayAfterTomorrow(
  inputDate: Date,
): boolean {
  // Get the current date
  const currentDate = new Date();

  // Set the time of the current date to 00:00:00 to only compare dates
  currentDate.setHours(0, 0, 0, 0);

  // Calculate the day after tomorrow by adding 2 days to the current date
  const dayAfterTomorrow = new Date(currentDate);
  dayAfterTomorrow.setDate(currentDate.getDate() + 2);

  // Set the time of the input date to 00:00:00 to only compare dates
  const comparisonDate = new Date(inputDate);
  comparisonDate.setHours(0, 0, 0, 0);

  // Compare the input date with the day after tomorrow
  return comparisonDate >= dayAfterTomorrow;
}
