// Path: ./src/app/pages/InfoPage/BottomContent.tsx
/**
 *
 * BottomInfoContent
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { messages } from 'locales/messages';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {}

export function BottomInfoContent(props: Props) {
  const { t } = useTranslation();
  const CardDetailsList = [
    {
      id: 1,
      src: `${window.config.REACT_APP_CLOUD_FRONT_PROD}/images/iPhone-13-Starlight.webp`,
      buttonText: t(messages.On_Your_iPhone()),
      Link: '/flyers',
      height: '460px',
    },
    {
      id: 2,
      src: `${window.config.REACT_APP_CLOUD_FRONT_PROD}/images/MacBookPro1.png`,
      buttonText: t(messages.On_Your_Computer()),
      Link: '/flyers',
      height: '246px',
    },
    {
      id: 3,
      src: `${window.config.REACT_APP_CLOUD_FRONT_PROD}/images/SiteMock_2x.webp`,
      buttonText: t(messages.On_Your_Android_Phone()),
      Link: '/flyers',
      height: '460px',
    },
  ];
  return (
    <TopContent id="BottomInfoContent" data-testid="bottominfocontent-test-id">
      <Wrapper>
        <Title>{t(messages.BROWSE_GoFlyer_START_SAVING_NOW())}</Title>

        <CardWrapper>
          {CardDetailsList.map(value => {
            return (
              <Card
                key={value.id}
                height={value.height}
                data-testid="bottominfocontent-test-id"
              >
                <CardImage src={value.src} height={value.height}></CardImage>
                <CardButton to={value.Link}>{value.buttonText}</CardButton>
              </Card>
            );
          })}
        </CardWrapper>
      </Wrapper>
    </TopContent>
  );
}

interface StyleProps {
  height?: string;
}
const TopContent = styled.div`
  width: 100%;
  background-color: #f3ece2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const Wrapper = styled.div`
  max-width: 1400px;
  height: 772px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 1112px) {
    height: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1112px) {
    flex-direction: column;
  }
`;
const Card = styled.div`
  position: relative;
  width: 316px;
  height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 60px;
  @media (max-width: 564px) {
    height: ${(props: StyleProps) => {
      return `${props.height}`;
    }};
  }
`;
const Title = styled.div`
  text-align: center;
  font: normal normal bold 53px/61px Arial;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  @media (max-width: 976px) {
    margin: 20px;
  }
  @media (max-width: 707px) {
    font-size: 30px;
    line-height: 36px;
  }
  @media (max-width: 404px) {
    font-size: 25px;
    line-height: 36px;
  }
`;

const CardImage = styled.img`
  @media (max-width: 564px) {
    width: 100%;
    height: ${(props: StyleProps) => {
      return `${props.height}`;
    }};
    object-fit: contain;
    object-position: center;
  }
`;
const CardButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ef8200 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 10px;
  font: normal normal bold 20px Arial;
  letter-spacing: 0px;
  color: #ffffff;
  position: absolute;
  bottom: -63px;
  cursor: pointer;
  transition: all 0.5s;
  text-decoration: none;
  outline: none;
  &:hover {
    background-color: #ffffff;
    border: 1px solid #ef8200;
    color: #ef8200;
  }
  @media (max-width: 707px) {
    font-size: 16px;
  }
`;
