// Path: ./src/app/desktop-src/app/pages/InfoPage/TopNav.tsx
/**
 *
 * TobNavBar
 *
 */
import { GoFlyerLogo } from 'app/desktop-src/app/components/GoFlyerLogo/Loadable';
import { LanguageSwitcher } from 'app/mobile-desktop-common-components/LanguageSwitcher';
import * as React from 'react';
import styled from 'styled-components/macro';
import { t } from 'i18next';
import Header from './Header';
import './styles.css';
import { messages } from 'locales/messages';
import { Link, useHistory } from 'react-router-dom';
interface Props {}

export function TobNavBar(props: Props) {
  const history = useHistory();
  const handleFlyer = (value: string) => {
    if (history.location.pathname === '/') {
      const element: any = document?.getElementById(value);
      const pos = element?.offsetTop;
      window?.scrollTo({
        top: pos - 110,
        left: 0,
        behavior: 'auto',
      });
    } else {
      history.push('/');

      setTimeout(() => {
        const element: any = document?.getElementById(value);
        const pos = element?.offsetTop;
        window?.scrollTo({
          top: pos - 110,
          left: 0,
          behavior: 'auto',
        });
      }, 500);
    }
  };

  const TopMenuList = [
    {
      id: 'SecondInfoContent',
      text: t(messages.Flyer_Deals()),
    },
    {
      id: 'FourthInfoContent',
      text: t(messages.InfoStores()),
    },
    {
      id: 'SixthInfoContent',
      text: t(messages.Reviews()),
    },
    {
      id: 'BottomInfoContent',
      text: t(messages.Mobile_APP()),
    },
  ];

  return (
    <TopNavBar data-testid="tobnavbar-test-id">
      <TopNavContent>
        <GoFlyerLogo height="61px" width="169px" />
        <MenuContent>
          {TopMenuList.map(value => {
            return (
              <TopMenu
                data-testid="info page top nav bar menu"
                key={value.id}
                onClick={() => {
                  handleFlyer(value.id);
                }}
              >
                {value.text}
              </TopMenu>
            );
          })}
        </MenuContent>
        <LanguageWrapper>
          <LanguageSwitcher />
        </LanguageWrapper>
        <StartButton data-testid="start saving" to="/flyers">
          {t(messages.Start_Saving())}
        </StartButton>
        <MenuIconWrapper>
          <Header></Header>
        </MenuIconWrapper>
      </TopNavContent>
    </TopNavBar>
  );
}

const LanguageWrapper = styled.div`
  @media (max-width: 1059px) {
    display: none;
  }
`;
const MenuIconWrapper = styled.div`
  display: block;
  @media (min-width: 1059px) {
    display: none;
  }
`;

const TopNavContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1400px;
  @media (max-width: 1400px) {
    margin-left: 40px;
    margin-right: 40px;
  }
`;
const TopNavBar = styled.div`
  display: flex;
  margin: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 88px;
  box-shadow: 0px -2px 12px #00000047;
  border-bottom: 1px solid #0000002e;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 999;
`;
const MenuContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 537px;
  @media (max-width: 1059px) {
    display: none;
  }
`;

const TopMenu = styled.div`
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  font-weight: 530;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
`;

const StartButton = styled(Link)`
  width: 147px;
  height: 45px;
  background: #ef8200 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  @media (max-width: 1059px) {
    display: none;
  }
`;
