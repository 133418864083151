// Path: ./src/app/pages/ShoppingListPage/Loadable.ts
/**
 *
 * Asynchronously loads the component for ShoppingListPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ShoppingListPage = lazyLoad(
  () => import('./index'),
  module => module.ShoppingListPage,
);
