// Path: ./src/app/components/ListHorizontalScroll/index.tsx
/**
 *
 * ListHorizontalScroll
 *
 */
import React, { memo } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled as stlyedMUI } from '@mui/material/styles';

// reference: https://material-ui.com/components/tabs/

interface Props {
  children?: any[];
  position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
  index?: number;
  top?: string;
  bottom?: string | number;
  className?: string;
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

// export const ListHorizontalScroll = function (props: Props) {
export const ListHorizontalScroll: React.FunctionComponent<Props> = memo(
  (props: Props) => {
    const [value, setValue] = React.useState(0);
    React.useEffect(() => {
      setValue(props.index ? props.index : 0);
    }, [props.index]);

    const storeList = props.children?.map(function (store, index) {
      return (
        <MuiTab
          className={` Tab  ${props?.className && props?.className}`}
          key={index}
          label={<React.Fragment>{store}</React.Fragment>}
          {...a11yProps(1)}
          style={{ minWidth: 'fit-content' }}
        />
      );
    });
    return (
      <MuiDiv className={`ListHorizontalScroll `}>
        <MuiAppBar
          position={props.position ? props.position : 'fixed'}
          color="default"
          className={` appBar`}
          style={{
            top: props.top ? props.top : 'auto',
            bottom: props.bottom ? props.bottom : 80,
          }}
        >
          <Tabs
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
            className={`Tabs `}
            value={value}
            // onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            {storeList}
          </Tabs>
        </MuiAppBar>
      </MuiDiv>
    );
  },
);

const MuiAppBar = stlyedMUI(AppBar)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'inherit',
}));

const MuiTab = stlyedMUI(Tab)(() => ({
  maxWidth: 'inherit',
  padding: 0,
  textTransform: 'inherit',
  textAlign: 'inherit',
}));

const MuiDiv = stlyedMUI('div')(() => ({
  flexGrow: 1,
  width: '100%',
  backgroundColor: 'transparent',
}));
