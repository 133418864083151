// Path: ./src/app/components/Helmet/index.tsx
import i18next from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface CustomHelmetProps {
  title: string;
  image?: string;
  keywords: string;
  description: string;
}

/**
 * CustomHelmet Component
 *
 * @description Reusable React component for managing document head metadata using React Helmet.
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title of the document.
 * @param {string} [props.image] - The URL of the image to be used for social sharing (optional).
 * @param {string} props.description - The meta description of the document.
 * @param {string} props.keywords - The meta keywords of the document.
 * @returns {JSX.Element} React component with Helmet metadata.
 */
export const CustomHelmet = ({
  title,
  image,
  description,
  keywords,
}: CustomHelmetProps) => {
  let imageSrc =
    image ||
    `${window.config.REACT_APP_CLOUD_FRONT_PROD}/images/MacBookPro1.png`;

  const href = window.location.pathname;
  let Language = i18next.language;
  const validLanguages = new Set(['en', 'zh', 'fr']);
  Language = validLanguages.has(Language) ? Language : 'en';

  return (
    <Helmet
      titleTemplate="%s "
      defaultTitle="GoFlyer"
      htmlAttributes={{ lang: Language }}
      data-testid="customhelmet-test-id"
    >
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:image" content={imageSrc} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={imageSrc} />
      <meta name="twitter:card" content={imageSrc} />
      <meta charSet="UTF-8" />
      <meta name="robots" content="index, follow" />
      <meta http-equiv="content-language" content={Language} />
      <link rel="canonical" href={href} />
      <link rel="alternate" hrefLang="en" href={href + `?lang=en`} />
      <link rel="alternate" hrefLang="zh" href={href + `?lang=zh`} />
      <link rel="alternate" hrefLang="fr" href={href + `?lang=fr`} />
      <meta property="og:url" content={href} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default CustomHelmet;
