import React from 'react';
import { Modal, Typography, Button, Paper, Slide } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { messages } from 'locales/messages';

interface SuccessModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
}
const AccountDeletedModal = ({
  open,
  onClose,
  title = 'Account Successfully Deleted',
  description = 'Your account has been successfully deleted. Thank you for using our service.',
}: SuccessModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '0px',
        outline: 'none',
      }}
      data-testid="accountdeletedmodal-test-id"
    >
      <Slide direction="up" in={open}>
        <Paper
          elevation={3}
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '20px',
            minWidth: '300px',
            maxWidth: '800px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <CheckCircleOutline style={{ fontSize: 80, color: '#4CAF50' }} />
          <Typography variant="h6" gutterBottom fontWeight={600}>
            {title}
          </Typography>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <Button variant="contained" color="primary" onClick={onClose}>
            {t(messages.Close())}
          </Button>
        </Paper>
      </Slide>
    </Modal>
  );
};

export default AccountDeletedModal;
