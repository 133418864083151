// Path: ./src/app/desktop-src/app/common/ClickFavButtonFunc/index.tsx
import { GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomerSlice } from 'app/desktop-src/store/CustomerSlice';
import { RootState } from 'types';
import { apiService } from 'utils/api';
import ReactGA from 'react-ga';
import * as analytics from 'utils/analytics';

export const useClickFavFunc = () => {
  const { actions } = useCustomerSlice();
  const { customer }: RootState = useSelector<RootState, RootState>(
    state => state,
  );
  const dispatch = useDispatch();
  const clickFavButton = async (e, store: GfStoreDto, showUnlikeIcon) => {
    ReactGA.event({
      category: '1.2',
      action: `Click Fav Flyer ${store.merchant.name} Button`,
    });
    analytics.click_fav_button_in_flyer_card(store, showUnlikeIcon);
    e.preventDefault();

    if (showUnlikeIcon) {
      dispatch(
        actions.updateCustomer({
          ...customer,
          gfStoreFavouriteList: {
            ...customer.gfStoreFavouriteList,
            stores: customer.gfStoreFavouriteList.stores?.filter(
              val => val.id != store.id,
            ),
          },
        }),
      );
      await apiService.gfStoreFavouriteListControllerRemoveStore(
        {
          storeId: store.id,
        },
        customer.gfStoreFavouriteList.id,
      );
    } else {
      dispatch(
        actions.updateCustomer({
          ...customer,
          gfStoreFavouriteList: {
            ...customer.gfStoreFavouriteList,
            stores: [...(customer.gfStoreFavouriteList.stores ?? []), store],
          },
        }),
      );
      await apiService.gfStoreFavouriteListControllerAddStore(
        {
          storeId: store.id,
        },
        customer.gfStoreFavouriteList.id,
      );
    }
  };

  return clickFavButton;
};
