// Path: ./src/app/desktop-src/app/pages/InfoPage/FifthContent.tsx
/**
 *
 * FiftInfoContent
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import QuoteSvg from './QuoteSvg.svg';
import { t } from 'i18next';
import { messages } from 'locales/messages';

interface Props {}

export function FiftInfoContent(props: Props) {
  const CardDetailsList = [
    {
      id: 1,
      text: t(messages.I_simply_cant_shop_without_the_GoFlyer_app_anymore()),
      name: 'Claire D. ',
      city: t(messages.Downtown_Toronto()),
    },
    {
      id: 2,
      text: t(messages.My_wife_calculated_that_weve_saved_34550_so_far()),
      name: 'Carter G.',
      city: t(messages.North_York()),
    },
    {
      id: 3,
      text: t(messages.The_app_made_me_discover_my_new_goto_stores()),
      name: 'Cameron B, ',
      city: t(messages.Etobicoke()),
    },
  ];
  return (
    <TopContent id="SixthInfoContent" data-testid="fiftinfocontent-test-id">
      <Wrapper>
        <Title data-testid="SEE WHAT OTHERS HAVE TO SAY">
          {t(messages.SEE_WHAT_OTHERS_HAVE_TO_SAY())}
        </Title>

        <CardWrapper>
          {CardDetailsList.map(value => {
            return (
              <Card key={value.id} data-testid="fiftinfocontent-test-id">
                <CardTitle>{value.text}</CardTitle>
                <CardSubTitle>
                  {value.name}
                  <br /> {value.city}
                </CardSubTitle>
                <QuoteIcons src={QuoteSvg} />
              </Card>
            );
          })}
        </CardWrapper>
      </Wrapper>
    </TopContent>
  );
}

const TopContent = styled.div`
  height: 772px;
  max-width: 1654px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  @media (max-width: 976px) {
    height: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  @media (max-width: 1227px) {
    justify-content: space-between;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    /* flex-direction: column; */
  }
  @media (max-width: 976px) {
    flex-direction: column;
  }
`;
const Card = styled.div`
  position: relative;
  width: 316px;
  height: 316px;
  display: flex;
  flex-direction: column;
  background: #f7f3ec 0% 0% no-repeat padding-box;
  border-radius: 40px;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 976px) {
    padding: 10px;
    margin: 20px;
  }
  @media (max-width: 707px) {
    width: 277px;
    height: 277px;
  }
`;
const Title = styled.div`
  text-align: center;
  font: normal normal bold 53px/61px Arial;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  @media (max-width: 976px) {
    margin: 20px;
  }
  @media (max-width: 707px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

const CardTitle = styled.div`
  text-align: center;
  font: italic normal bold 26px Arial;
  letter-spacing: 0px;
  color: #ef8200;
  padding: 10px;
  @media (max-width: 707px) {
    font-size: 22px;
  }
`;
const CardSubTitle = styled.div`
  text-align: center;
  font: normal normal normal 18px/21px Arial;
  letter-spacing: 0px;
  color: #707070;
`;

const QuoteIcons = styled.img`
  position: absolute;
  bottom: -35px;
`;
