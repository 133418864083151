// Path: ./src/app/desktop-src/app/pages/FlyerTagsPage/Loadable.ts
/**
 *
 * Asynchronously loads the component for FlyerTagsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const FlyerTagsPage = lazyLoad(
  () => import('./index'),
  module => module.FlyerTagsPage,
);
