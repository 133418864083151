// Path: ./src/utils/globalPrototype.ts
declare global {
  interface Number {
    changeDecimal: (digit: number) => number;
  }
}

Number.prototype.changeDecimal = function (digit: number): number {
  return parseFloat(this.toFixed(digit));
};

export {};
