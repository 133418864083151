// Path: ./src/utils/dtoUtility.ts
import { Position } from '@capacitor/geolocation';
import {
  GfFlyerDto,
  GfFlyerItem,
  GfFlyerItemDto,
  GfImageDto,
  GfPromotionDto,
  GfPromotionFavouriteListDto,
  GfShoppingListDto,
  GfStoreDto,
  GfStoreFavouriteListDto,
} from '@swagger/typescript-fetch-goflyer';
import { t } from 'i18next';
import { messages } from 'locales/messages';
import moment from 'moment';
import { isDateGreaterOrEqualToDayAfterTomorrow } from './date/isDateGreaterOrEqualToDayAfterTomorrow';
import { isSameDate } from './date/isSameDate';

// GfPromotionDto
export const checkIfPromotionInFavList = function (
  a_promotion: GfPromotionDto,
  favList: GfPromotionFavouriteListDto,
): GfPromotionDto | undefined {
  const promo = favList.promotions.find(
    promotion_in_list => promotion_in_list.id === a_promotion.id,
  );
  return promo;
};
export const instanceOfDeal = function (data: any): data is GfPromotionDto {
  return 'placement' in data;
};
export const instanceOfFlyerItem = function (
  data: any,
): data is GfFlyerItemDto {
  return 'flyerItemList' in data;
};
export const checkIfPromotionInShoppingList = function (
  a_promotion: GfPromotionDto,
  shoppingList: GfShoppingListDto,
): GfPromotionDto | undefined {
  const promo = shoppingList?.promotions?.find(
    promotion_in_list => promotion_in_list.id === a_promotion.id,
  );
  return promo;
};

export const checkIfFlyerItemInShoppingList = function (
  flyerItem: GfFlyerItemDto,
  shoppingList: GfShoppingListDto,
): GfFlyerItemDto | undefined {
  const foundFlyerItem = shoppingList?.flyerItems?.find(
    promotionInList => promotionInList.id === flyerItem.id,
  );
  return foundFlyerItem;
};

export const checkIfInShoppingList = function (
  id: string,
  shoppingList: GfShoppingListDto,
  entity: 'flyerItems' | 'promotions',
): GfFlyerItemDto | GfPromotionDto | undefined {
  return (shoppingList[entity] as (GfFlyerItemDto | GfPromotionDto)[]).find(
    inList => inList.id === id,
  );
};

// GfStoreDto
export const checkIfStoreInFavList = function (
  a_store: GfStoreDto,
  favList: GfStoreFavouriteListDto,
): GfStoreDto | undefined {
  const store = favList.stores?.find(
    store_in_list => store_in_list.id === a_store.id,
  );
  return store;
};

export const getPromotionDiscount = function (
  promotion: GfPromotionDto,
): number {
  return promotion?.placement?.price
    ? -Math.floor(
        ((promotion.placement.price - promotion.price) /
          promotion.placement.price) *
          100,
      )
    : 0;
};

// GfStoreDto
export const getStoreIndexByStoreArray = function (
  a_store: GfStoreDto,
  storeArr: GfStoreDto[],
): number {
  const index = storeArr.findIndex(store => {
    return store.id === a_store.id;
  });
  return index;
};

export const storeOpenNow = function (store: GfStoreDto): boolean {
  function timeStringToFloat(time) {
    const hoursMinutes = time.split(/[.:]/);
    const hours = parseInt(hoursMinutes[0], 10);
    const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }

  const current_hour = timeStringToFloat(new Date().toTimeString());
  if (current_hour >= store.openTime && current_hour < store.closeTime) {
    return true;
  } else {
    return false;
  }
};

export const getMyDistanceToStore = function (
  store: GfStoreDto,
  myPosition: Position,
): number | undefined {
  if (!store?.location?.coordinates[1] || !store?.location?.coordinates[0]) {
    return undefined;
  }
  return distance(
    store?.location?.coordinates[1],
    store?.location?.coordinates[0],
    myPosition?.coords?.latitude,
    myPosition?.coords?.longitude,
    'K',
  );
};

export const distance = function (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit?,
): number {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist = dist * 1.609344;
  }
  if (unit === 'N') {
    dist = dist * 0.8684;
  }
  return dist;
};

/**
 * Retrieves the localized status string for a flyer based on its valid start and end dates.
 *
 * This function takes a GfFlyerDto object with valid start and end dates, and calculates the remaining days until the flyer's expiration.
 * The resulting string indicates the flyer's status, such as "Starts Tomorrow," "Starting On {Date}," "Expires today," "3 days left," or "Invalid" in case of an invalid date scenario.
 *
 * @param flyer - GfFlyerDto object with valid start and end dates.
 * @returns {string | undefined} The possible return values include:
 * - Strings indicating when the flyer starts Tomorrow (e.g., "Starts Tomorrow").
 * - Strings indicating when the flyer starts the day after tomorrow (e.g., "Starting On {Date}").
 * - When it expires today (e.g., "Expires today").
 * - The number of days left until expiration (e.g., "3 days left").
 * - An indication of an invalid date scenario ("Invalid").
 */
export const getFlyerStatusString = function (
  flyer: GfFlyerDto,
): string | undefined {
  const currentDate = new Date();
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  const validStart = new Date(flyer.validStartDate);
  const validEnd: Date = new Date(flyer.validEndDate);
  if (!flyer?.validStartDate && !flyer.validEndDate) {
    return undefined; // returning undefined as GfMenuDto(restaurant) doesn't have validStart and validEnd Date.
  } else if (isSameDate(validStart, tomorrow)) {
    return t(messages.Starts_Tomorrow());
  } else if (isDateGreaterOrEqualToDayAfterTomorrow(validStart)) {
    return getStartingOnString(flyer.validStartDate);
  } else if (isValidDate(validEnd) && isSameDay(validEnd, currentDate)) {
    return t(messages.Expire_Today());
  } else if (isValidDate(validEnd) && validEnd > currentDate) {
    const daysLeft = Math.ceil(
      (validEnd.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),
    );
    if (daysLeft >= 60) {
      return ``;
    }
    return `${daysLeft} ${t(messages.Day_Left())}`;
  } else {
    return 'Invalid';
  }
};

/**
 * Generates a string indicating the starting date with localization.
 * @param date - The starting date.
 * @returns A formatted string indicating the starting date.
 */
function getStartingOnString(date: Date) {
  return `${t(messages.Starting_On())} ${moment(date)
    .format('ll')
    .substring(0, moment(date).format('ll').lastIndexOf(','))}`;
}

/**
 * Checks if a given date is a valid Date object.
 * @param date - The date to be validated.
 * @returns True if the date is a valid Date object; otherwise, false.
 */
function isValidDate(date: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

/**
 * Checks if two dates represent the same day.
 * @param date1 - The first date.
 * @param date2 - The second date.
 * @returns True if both dates represent the same day; otherwise, false.
 */
function isSameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export const getDaysLeftOfPromotion = function (
  promotion: GfPromotionDto,
): number {
  const Difference_In_Time =
    new Date(promotion.validEndDate).getTime() - new Date().getTime();
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Math.trunc(Difference_In_Days);
};

export const getDaysLeftOfFlyerItem = function (
  promotion: GfFlyerItem,
): number {
  const Difference_In_Time =
    new Date(promotion?.flyerItemList.validEndDate).getTime() -
    new Date().getTime();
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Math.trunc(Difference_In_Days);
};
export const instanceOfFlyer = function (data: any): data is GfFlyerDto {
  return 'stores' in data;
};

export const instanceOfPromotion = function (
  data: any,
): data is GfPromotionDto {
  return 'placement' in data;
};

export const getSmallImageIfExist = function (
  image: GfImageDto | undefined,
  remoteCondition: boolean | string,
): string | undefined {
  if (image?.srcSmall && remoteCondition === 'true') {
    return image?.srcSmall;
  } else if (image?.srcSmall) {
    return image?.srcSmall;
  } else {
    return image?.src;
  }
};

export function detectCurrencyString(input: string): string | boolean {
  // Step 1: Check for invalid characters (anything other than digits, '.', and '$')
  if (/[^0-9$.]/.test(input)) {
    return true;
  }

  // Step 2: Check if input is purely numeric (digits with optional decimal point)
  if (/^\d+(\.\d+)?$/.test(input)) {
    return false;
  }

  // Step 3: Check if input is a valid currency string with '$' sign
  if (/^\$\d+(\.\d+)?$/.test(input)) {
    return false;
  }

  return true;
}

export function extractCurrencyValue(input: string): string | null {
  // Regular expression to match a valid currency format
  const match = input.match(/\$\d+(\.\d{1,2})?/);
  if (match) {
    // Return the numeric part without the '$' sign
    return match[0].substring(1);
  } else {
    return null;
  }
}
