// Path: ./src/app/desktop-src/app/components/PageHeader/styled.ts
import styled from 'styled-components';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { styled as stlyedMUI } from '@mui/material/styles';

export const MuiFavoriteBorderIcon = stlyedMUI(FavoriteBorderIcon)(() => ({
  color: ' #999999 ',
  fontSize: '28px',
}));
export const MuiFavoriteIcon = stlyedMUI(FavoriteIcon)(() => ({
  color: '#FF453A',
  fontSize: '28px',
}));
export const TopNav = styled.div`
  top: 0;
  right: 0;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #707070;
  @media screen and (min-width: 790px) {
    width: 100%;
  }
`;

export const LanuageWrapper = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 11px;
  min-width: 40%;
  justify-content: space-evenly;
`;

export const BackButton = styled.button`
  color: #f58220;
  margin-right: 10px;
  margin-left: 10px;
  background: none;
  cursor: pointer;
  outline: none;
  border: 0px;
`;

export const ImageSection = styled.div`
  width: 107px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  max-width: ${(props: any) => (props.width ? props.width : '107px')};
  max-height: ${(props: any) => (props.height ? props.height : '57px')};
`;

export const HeartIconWrapper = styled.div`
  margin-left: 25px;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
`;
export const Title = styled.div`
  margin-left: 46px;
`;

interface buttonProps {
  isSelected?: boolean;
}

export const Button = styled.div<buttonProps>`
  margin-left: 25px;
  cursor: pointer;
  display: inline-block;
  line-height: 48px;
  font-size: 16px;
  color: ${props => (props.isSelected ? '#f58220' : 'black')};
  font-weight: ${props => (props.isSelected ? 'bold' : 'normal')};

  border-bottom: ${props =>
    props.isSelected ? '5px solid #f58220' : '5px solid rgba(31,40,51,0)'};
  margin: 0 0 0 15px;
  padding: 10px 4px 5px;
  text-align: center;
  transition: border-bottom 0.1s;

  &:hover {
    border-bottom: ${props =>
      props.isSelected ? '5px solid #f58220' : '5px solid #f58320a2'};
  }
`;
