// Path: ./src/app/components/TextWithBackgroundColor/index.tsx
/**
 *
 * TextWithBackgroundColor
 *
 */
import { Box, Chip } from '@mui/material';
import { styled as stlyedMUI } from '@mui/material/styles';

import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  content?: string;
  width?: number;
}

export function TextWithBackgroundColor(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();

  return (
    <Box data-testid="textwithbackgroundcolor-test-id">
      <MuiChip
        className={`Chip`}
        color="primary"
        label={props.content}
        style={{
          borderRadius: '8px',
          color: 'white',
          font: 'normal bold  24px/29px SFProText',
          width: props.width ? props.width : 108,
        }}
      />
    </Box>
  );
}

const MuiChip = stlyedMUI(Chip)(() => ({
  backgroundColor: '#FF9500',
  '&>.MuiChip-label': {
    padding: 0,
    backgroundColor: '#FF9500',
  },
}));
