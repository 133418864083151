// Path: ./src/app/pages/SigninPageMobile/loggedInUI.tsx
/**
 *
 * SigninPageIosMobile
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { apiService } from 'utils/api';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Snackbar,
} from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { messages } from 'locales/messages';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import ConfirmationModal from 'app/mobile-desktop-common-components/ConfirmationModal';
import { GfCustomerDtoWithLogin } from 'app/store/state';
import { localstorageSet } from 'utils/localstorage';
import AccountDeletedModal from '../ConfirmationModal/AccountDeletedModal';
import { Spinner } from 'app/components/Spinner';

interface Props {
  customer: GfCustomerDtoWithLogin;
  onLogoutSuccessfulCallBack?: () => void;
}

export const LoggedInProfileUI: React.FunctionComponent<Props> = ({
  customer,
  onLogoutSuccessfulCallBack,
}) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleCancel = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    await apiService.gfCustomerControllerRemove(customer.id, {
      headers: { access_token: customer.access_token },
    });

    setOpenToast(true);
    setIsLoading(false);
  };

  const handleCloseSuccessfulDelete = () => {
    setDialogOpen(false);
    setOpenToast(false);
    localstorageSet('customer', {});
    window.location.reload();
  };

  const handleLogout = async () => {
    /**
     * Before clear local storage, we need to unset the logged in user's notification token, otherwise, this mobile device will receive 2 notification
     */
    await apiService.gfCustomerControllerUpdate(
      {
        notificationToken: undefined,
      },
      customer.id,
    );
    localStorage.removeItem('customer');
    FirebaseAuthentication.signOut();

    onLogoutSuccessfulCallBack && onLogoutSuccessfulCallBack();
    /**
     * when reload, the new customer will get the current device notification token.
     */
    window.location.reload();
  };

  return (
    <Div data-testid="loggedinprofileui-test-id">
      <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <nav aria-label="main mailbox folders">
          <List>
            {customer.firstName && (
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={`Hi, ${customer.firstName}`} />
                </ListItemButton>
              </ListItem>
            )}
            {customer.email && (
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={customer.email}
                    data-test="Login Profile Email"
                  />
                </ListItemButton>
              </ListItem>
            )}
            {customer.phoneNumber && (
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <LocalPhoneIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={customer.phoneNumber}
                    data-test="Login Profile PhoneNumber"
                  />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={t(messages.Logout())} />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  setDialogOpen(true);
                }}
              >
                <ListItemIcon>
                  <DeleteSweepIcon />
                </ListItemIcon>
                <ListItemText primary={t(messages.Delete_Account())} />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
      <ConfirmationModal
        open={dialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={`${t(messages.confirmAccountDeletionTitle())}`}
        description={`${t(messages.confirmAccountDeletionDescription())}`}
      />
      <AccountDeletedModal
        open={openToast}
        onClose={handleCloseSuccessfulDelete}
        title={`${t(messages.accountSuccessfullyDeleted())}`}
        description={`${t(messages.accountDeletionSuccess())}`}
      />
      {isLoading ? (
        <Modal
          open={isLoading}
          closeAfterTransition
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '0px',
            outline: 'none',
          }}
        >
          <Spinner />
        </Modal>
      ) : (
        <></>
      )}
    </Div>
  );
};

const Div = styled.div`
  margin: 100px 20px;
  line-height: 30px;
`;

export default LoggedInProfileUI;
