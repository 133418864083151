import React, { useState } from 'react';
import { Button, Typography, TextField, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { messages } from 'locales/messages';
import { CloseButton, ModalBox, StyledModal } from './styled';

interface OTPModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (otp: string) => void;
}

const OTPModal: React.FC<OTPModalProps> = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
    setError(false);
  };

  const handleSubmit = () => {
    if (otp.length !== 6) {
      setError(true);
    } else {
      onSubmit(otp);
      onClose();
    }
  };

  return (
    <StyledModal open={open} onClose={onClose}>
      <ModalBox data-testid="OTP Modal">
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>

        <Typography
          variant="h6"
          style={{ marginBottom: '16px', fontWeight: 'bold' }}
        >
          {t(messages.otpVerification())}
        </Typography>

        <Typography variant="body2" style={{ marginBottom: '16px' }}>
          {t(messages.enterOtp())}
        </Typography>

        <TextField
          variant="outlined"
          fullWidth
          value={otp}
          onChange={handleChange}
          placeholder={t(messages.enterOtpPlaceholder())}
          error={error}
          helperText={error ? t(messages.otpError()) : ''}
          inputProps={{
            maxLength: 6,
            style: { textAlign: 'center', letterSpacing: '5px' },
          }}
          InputProps={{
            style: {
              color: '#f58220',
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#f58220',
              },
              '&:hover fieldset': {
                borderColor: '#f58220',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#f58220',
              },
            },
            marginBottom: '24px',
          }}
          data-testid="OTP input"
        />

        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              fullWidth
              style={{
                backgroundColor: '#f58220',
                color: '#fff',
              }}
              data-testid="OTP Verify Button"
            >
              {t(messages.verify())}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              onClick={onClose}
              variant="outlined"
              fullWidth
              style={{
                borderColor: '#ccc',
                color: '#555',
              }}
              data-testid="OTP Cancel Button"
            >
              {t(messages.Cancel())}
            </Button>
          </Grid>
        </Grid>
      </ModalBox>
    </StyledModal>
  );
};

export default OTPModal;
