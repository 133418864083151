// Path: ./src/app/components/LanguageSwitcherParams/index.tsx
import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { localstorageSet } from 'utils/localstorage';

/**
 * LanguageSwitcherParams - React component for handling language switching based on URL parameters.
 *
 * This component uses React Router's useHistory, React-i18next for translation,
 *
 * @returns {JSX.Element} - React JSX element, does not render any visible content.
 */
export const LanguageSwitcherParams = () => {
  const { replace, location } = useHistory();
  const { i18n } = useTranslation();

  const handleLanguageChange = (langCode: string, stateValue: string) => {
    i18n.changeLanguage(langCode);
    localstorageSet('Lang', langCode);
    replace({ state: stateValue });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const lang = searchParams.get('lang');
    if (lang === 'en' || lang === 'zh' || lang === 'fr') {
      switch (lang) {
        case 'en':
          handleLanguageChange('en', 'english');
          break;
        case 'zh':
          handleLanguageChange('zh', 'chinese');
          break;
        case 'fr':
          handleLanguageChange('fr', 'french');
          break;
        default:
          break;
      }
    }
  }, [location]);

  return <></>;
};
