// Path: ./src/app/components/DownloadBanner/index.tsx
/**
 *
 * DownloadBanner
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { styled as stlyedMUI } from '@mui/material/styles';

import { Button } from '@mui/material';
import logo from '../../assets/icon.png';
import StarIcon from '@mui/icons-material/Star';
import { messages } from 'locales/messages';
import * as analytics from '../../../utils/analytics';
interface Props {
  handleClose?: () => void;
  inFlyer?: boolean;
}

export const DownloadBanner: React.FunctionComponent<Props> = memo(
  (props: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t, i18n } = useTranslation();
    const handleGetApp = () => {
      const userAgent: any =
        navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        analytics.click_get_mobile_app('Andriod');
        window.location.href =
          'https://play.google.com/store/apps/details?id=io.ionic.goflyermobile';
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        analytics.click_get_mobile_app('ios');
        window.location.href =
          'https://apps.apple.com/ca/app/goflyer/id1580987749';
      }
    };
    return (
      <Div inFlyer={props.inFlyer}>
        <BottomDiv>
          <div>
            <BannerLogo src={logo}></BannerLogo>
          </div>
          <ContentContainer>
            <BannerTitle
              data-testid={
                "Find the best deals on everything you're looking for. Try watch list on GoFlyer."
              }
            >
              {t(messages.Banner_Text())}
            </BannerTitle>
            <InnerView>
              <RatingSection>
                <RatingInnerView
                  style={{
                    visibility: 'hidden',
                  }}
                >
                  <MuiStarIcon />
                  <MuiStarIcon />
                  <MuiStarIcon />
                  <MuiStarIcon />
                  <MuiStarIcon />
                  <Reviews>888k</Reviews>
                </RatingInnerView>
              </RatingSection>{' '}
              <MuiButton
                data-testid="Get App"
                onClick={handleGetApp}
                variant="contained"
                style={{
                  backgroundColor: '#FF9500',
                  border: '2px solid #FF9500',
                  boxShadow: 'none',
                }}
              >
                {t(messages.Get_App())}
              </MuiButton>
            </InnerView>
          </ContentContainer>
          {props.inFlyer ? (
            <></>
          ) : (
            <div>
              <Cancel
                data-testid="download banner handle close X button"
                onClick={props.handleClose}
              >
                X
              </Cancel>
            </div>
          )}
        </BottomDiv>
      </Div>
    );
  },
);

const Div = styled.div`
  position: relative;
  min-height: 95px;
  background: #a3eaf3 0% 0% no-repeat padding-box;
  opacity: 1;
  ${(props: { inFlyer?: boolean }) =>
    props.inFlyer ? `margin: 21px !important;` : ``};
  ${(props: { inFlyer?: boolean }) =>
    props.inFlyer ? `border-radius: 10px !important;` : ``};
`;

const BottomDiv = styled.div`
  display: flex;
  width: 100%;
  // margin-left:10p
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #80808012;
`;
const BannerLogo = styled.img`
  width: 68px;
  height: 68px;
  background-position: left;
  background-size: 283% 83%;
  background-repeat: no-repeat;
  background-color: white;
  opacity: 1;
  border-radius: 25px;
`;

const ContentContainer = styled.div`
  height: 100%;
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`;

const BannerTitle = styled.h3`
  text-align: left;
  font: normal normal normal 13px/15px Arial;
  letter-spacing: 0px;
  color: #005581;
  opacity: 1;
  width: 233px;
`;
const RatingSection = styled.span`
  margin: 0px;
  text-align: left;
  font: normal normal bold 18px/16px SFProText;
  letter-spacing: 0px;
  color: #ff9500;
  opacity: 1;
  margin-right: 7px;
`;
const Reviews = styled.span`
  margin: 0px;
  margin-left: 5px;
  text-align: left;
  font: normal normal medium 11px/12px;
  font-size: 11px;
  font-family: SFProText;
  letter-spacing: 0px;
  color: #005581;
  opacity: 1;
  font-weight: 500;
`;

const RatingInnerView = styled.span`
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: inherit;
`;

const Cancel = styled.div`
  font-size: 22px;
  margin-right: 10px;
  font-weight: 500;
  cursor: pointer;
`;

const InnerView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const MuiButton = stlyedMUI(Button)(() => ({
  whiteSpace: 'nowrap',
  background: '#FF9500 0% 0% no-repeat padding-box',
  borderRadius: '10px',
  opacity: '1',
  padding: '10px',
  textAlign: 'left',
  font: 'normal normal medium 12px/14px ',
  fontFamily: 'SFProText',
  fontSize: '12px !important',
  letterSpacing: '-0.54px',
  color: '#FFFFFF ',
  textTransform: 'capitalize',
  height: '30px',
  fontWeight: 'bold',
  minWidth: '80px',
}));
const MuiStarIcon = stlyedMUI(StarIcon)(() => ({
  fontSize: '14px',
}));
