// Path: ./src/app/components/SearchHistory/index.tsx
/**
 *
 * SearchHistory
 *
 */
import React, { memo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { messages } from 'locales/messages';
import {
  BtnWrapper,
  EditButton,
  MainDiv,
  MuiButton,
  SearchTitle,
  TitleWrapper,
} from './styled';
interface Props<T extends { text: string }> {
  BtnData?: T[];
  searchCallback: (text: string) => Promise<void>;
  Title?: string;
  canBeEdited?: boolean;
  onRemoveSearch?: (searchValue: string) => void;
  dataTestId?: string;
}

/**
 * SearchHistory Component
 *
 * Displays a list of search history items with options for editing and removing.
 *
 * @component
 * @param {Object} Props - Component properties.
 * @param {Array} Props.BtnData - An array of objects with 'text' property representing search history items.
 * @param {Function} Props.searchCallback - A callback function triggered when a search history item is clicked.
 * @param {string} Props.Title - Optional title for the search history section.
 * @param {boolean} Props.canBeEdited - Flag indicating whether the search history can be edited.
 * @param {Function} Props.onRemoveSearch - A callback function triggered when removing a search history item.
 *
 * @returns {JSX.Element} - The rendered SearchHistory component.
 */
export const SearchHistory: React.FC<Props<{ text: string }>> = memo(
  ({
    BtnData = [],
    searchCallback,
    Title,
    canBeEdited = false,
    onRemoveSearch,
    dataTestId,
  }: Props<{ text: string }>) => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);

    const Search = async (text: string) => {
      searchCallback?.(text);
    };

    return (
      <MainDiv data-testid={dataTestId}>
        <TitleWrapper>
          <SearchTitle>{Title}</SearchTitle>
          {canBeEdited && BtnData.length > 0 && (
            <EditButton
              onClick={() => setEdit(!edit)}
              data-testid="search history edit button"
            >
              {edit ? t(messages.Done()) : t(messages.Edit())}
            </EditButton>
          )}
        </TitleWrapper>
        <BtnWrapper>
          {BtnData?.map((value, key) => (
            <MuiButton
              data-testid="search history search button"
              onClick={() =>
                edit && canBeEdited
                  ? onRemoveSearch?.(value.text)
                  : Search(value.text)
              }
              key={key}
              endIcon={edit && <CloseIcon style={{ fontSize: 15 }} />}
            >
              {value.text}
            </MuiButton>
          ))}
        </BtnWrapper>
      </MainDiv>
    );
  },
);
