// Path: ./src/app/desktop-src/store/CustomerSlice/index.ts
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { CustomerState } from './types';
import { GfCustomerDto } from '@swagger/typescript-fetch-goflyer';
import { localstorageSet } from 'utils/localstorage';
export interface GfCustomerDtoWithLogin extends GfCustomerDto {
  isLogin: boolean;
  access_token: string;
}
export const initialState: CustomerState = {
  isLogin: false,
  access_token: '',
} as GfCustomerDtoWithLogin;

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    updateCustomer(state, action: PayloadAction<any>) {
      localstorageSet('customer', { ...action.payload });
      return { ...action.payload };
    },
  },
});

export const { actions: customerActions } = slice;
export const UserReducer = slice.reducer;
export const useCustomerSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer as any });

  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCustomerSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
