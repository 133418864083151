// Path: ./src/app/components/CardFlyerNew/styled.ts
import styled from 'styled-components/macro';
import Button from '@mui/material/Button';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { styled as stlyedMUI } from '@mui/material/styles';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const CardDiv = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000040;
  border-radius: 20px;
  opacity: 1;
  height: 268px;
  margin-left: 13px;
  margin-right: 13px;
  margin-top: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Img = styled.img`
  height: 60px !important;
  width: 60px !important;
  border-radius: 32px !important;
  margin-left: -7px;
  object-fit: contain;
`;
export const TopDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 11px;
`;
export const LeftDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;
export const SubLeftDiv = styled.div`
  margin-left: 8px;
`;
export const Heading = styled.h4`
  margin: 0px;
  text-align: left;
  font: normal normal 600 14px/22px SFProText;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: -3px;
`;
export const SubText = styled.span`
  text-align: left;
  font: normal normal normal 13px/20px SFProText;
  letter-spacing: 0px;
  color: #757575;
  opacity: 1;
`;

export const BottomDiv = styled.div`
  height: 212px;
  display: flex;
  width: 100%;
`;

export const SalesBtnDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 19px;
  padding-top: 10px;
  justify-content: end;
  align-items: flex-end;
  position: absolute;
  right: 0;
`;

export const MuiButton = stlyedMUI(Button)(() => ({
  font: 'normal normal 600 16px/22px SFProText',
  textTransform: 'capitalize',
  letterSpacing: '0px',
  color: '#000000',
  opacity: '1',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 3px 12px #00000040',
  borderRadius: '7px',
  border: '0px',
  MinHeight: '29px',
  marginBottom: '10px',
  '@media (max-width:320px)': {
    width: '110px !important',
  },
}));
export const MuiFavoriteBorderIcon = stlyedMUI(FavoriteBorderIcon)(() => ({
  color: ' #999999 ',
  fontSize: '28px',
}));
export const MuiFavoriteIcon = stlyedMUI(FavoriteIcon)(() => ({
  color: '#FF453A',
  fontSize: '28px',
}));
export const MuiFiberManualRecordIcon = stlyedMUI(FiberManualRecordIcon)(
  () => ({
    color: '#999999',
    fontSize: '3px',
  }),
);
