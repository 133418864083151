// Path: ./src/app/components/SideNavMenuLink/Loadable.ts
/**
 *
 * Asynchronously loads the component for SideNavMenuLink
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SideNavMenuLink = lazyLoad(
  () => import('./index'),
  module => module.SideNavMenuLink,
);
