// Path: ./src/app/desktop-src/app/pages/FavouritesPage/Loadable.ts
/**
 *
 * Asynchronously loads the component for FavouritesPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const FavouritesPage = lazyLoad(
  () => import('./index'),
  module => module.FavouritesPage,
);
