// Path: ./src/app/desktop-src/store/FlyerList/index.ts
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { FlyerListStateProps } from './types';
export const initialState: FlyerListStateProps = {
  All: {
    count: 0,
    currentPage: 1,
    itemsPerPage: 1,
    results: [],
    scrollPosition: 0,
  },
  grocery: {
    count: 0,
    currentPage: 1,
    itemsPerPage: 1,
    results: [],
    scrollPosition: 0,
  },
  restaurant: {
    count: 0,
    currentPage: 1,
    itemsPerPage: 1,
    results: [],
    scrollPosition: 0,
  },
  chinesesupermarket: {
    count: 0,
    currentPage: 1,
    itemsPerPage: 1,
    results: [],
    scrollPosition: 0,
  },
  electricalAppliances: {
    count: 0,
    currentPage: 1,
    itemsPerPage: 1,
    results: [],
    scrollPosition: 0,
  },
  Latest: {
    count: 0,
    currentPage: 1,
    itemsPerPage: 1,
    results: [],
    scrollPosition: 0,
  },
  upComing: {
    count: 0,
    currentPage: 1,
    itemsPerPage: 1,
    results: [],
    scrollPosition: 0,
  },
  searchPage: {
    count: 0,
    currentPage: 1,
    itemsPerPage: 1,
    scrollPosition: 0,
    resultsFlyer: [],
    resultsPromotion: [],
    resultsFlyerItem: [],
  },
};

const slice = createSlice({
  name: 'flyerList',
  initialState,
  reducers: {
    addFlyerList(state, action: PayloadAction<any>) {
      switch (action.payload.type) {
        case 'All':
          if (action?.payload?.condition === 'PageNumer') {
            return {
              ...state,
              All: { ...state.All, currentPage: action.payload.data },
            };
          } else if (action?.payload?.condition === 'scrollPosition') {
            return {
              ...state,
              All: { ...state.All, scrollPosition: action.payload.data },
            };
          } else {
            return { ...state, All: { ...state.All, ...action.payload.data } };
          }
        case 'Latest':
          if (action?.payload?.condition === 'PageNumer') {
            return {
              ...state,

              Latest: { ...state.Latest, currentPage: action.payload.data },
            };
          } else if (action?.payload?.condition === 'scrollPosition') {
            return {
              ...state,

              Latest: { ...state.Latest, scrollPosition: action.payload.data },
            };
          } else {
            return {
              ...state,

              Latest: { ...state.Latest, ...action.payload.data },
            };
          }
        case 'grocery':
          if (action?.payload?.condition === 'PageNumer') {
            return {
              ...state,
              grocery: { ...state.grocery, currentPage: action.payload.data },
            };
          } else if (action?.payload?.condition === 'scrollPosition') {
            return {
              ...state,
              grocery: {
                ...state.grocery,
                scrollPosition: action.payload.data,
              },
            };
          } else {
            return {
              ...state,
              grocery: { ...state.grocery, ...action.payload.data },
            };
          }

        case 'restaurant':
          if (action?.payload?.condition === 'PageNumer') {
            return {
              ...state,
              restaurant: {
                ...state.restaurant,
                currentPage: action.payload.data,
              },
            };
          } else if (action?.payload?.condition === 'scrollPosition') {
            return {
              ...state,
              restaurant: {
                ...state.restaurant,
                scrollPosition: action.payload.data,
              },
            };
          } else {
            return {
              ...state,
              restaurant: { ...state.restaurant, ...action.payload.data },
            };
          }

        case 'chinesesupermarket':
          if (action?.payload?.condition === 'PageNumer') {
            return {
              ...state,
              chinesesupermarket: {
                ...state.chinesesupermarket,
                currentPage: action.payload.data,
              },
            };
          } else if (action?.payload?.condition === 'scrollPosition') {
            return {
              ...state,
              chinesesupermarket: {
                ...state.chinesesupermarket,
                scrollPosition: action.payload.data,
              },
            };
          } else {
            return {
              ...state,
              chinesesupermarket: {
                ...state.chinesesupermarket,
                ...action.payload.data,
              },
            };
          }
        /**
         * Too much duplicate code here, need to refractor code here
         */
        case 'electricalAppliances':
          if (action?.payload?.condition === 'PageNumer') {
            return {
              ...state,
              electricalAppliances: {
                ...state.electricalAppliances,
                currentPage: action.payload.data,
              },
            };
          } else if (action?.payload?.condition === 'scrollPosition') {
            return {
              ...state,
              electricalAppliances: {
                ...state.electricalAppliances,
                scrollPosition: action.payload.data,
              },
            };
          } else {
            return {
              ...state,
              electricalAppliances: {
                ...state.electricalAppliances,
                ...action.payload.data,
              },
            };
          }
        case 'upcoming':
          if (action?.payload?.condition === 'PageNumer') {
            return {
              ...state,
              upComing: {
                ...state.upComing,
                currentPage: action.payload.data,
              },
            };
          } else if (action?.payload?.condition === 'scrollPosition') {
            return {
              ...state,
              upComing: {
                ...state.upComing,
                scrollPosition: action.payload.data,
              },
            };
          } else {
            return {
              ...state,
              upComing: { ...state.upComing, ...action.payload.data },
            };
          }
        case 'searchPage':
          if (action?.payload?.condition === 'PageNumer') {
            return {
              ...state,
              searchPage: {
                ...state.searchPage,
                currentPage: action.payload.data,
              },
            };
          } else if (action?.payload?.condition === 'scrollPosition') {
            return {
              ...state,
              searchPage: {
                ...state.searchPage,
                scrollPosition: action.payload.data,
              },
            };
          } else {
            return {
              ...state,
              searchPage: { ...state.searchPage, ...action.payload.data },
            };
          }
        default:
          break;
      }
    },
  },
});

export const { actions: customerActions } = slice;
export const UserReducer = slice.reducer;
export const useFlyerListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer as any });

  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useFlyerSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
