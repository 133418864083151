import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const NoStoreSubmitForm: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="start"
      textAlign="center"
      sx={{
        width: '100%',
        minHeight: '300px',
        padding: '20px',
        maxWidth: '1024px',
        margin: 'auto',
      }}
    >
      <Box
        display="flex"
        alignItems="start"
        flexDirection={'column'}
        mb={1}
        sx={{
          width: '100%',
        }}
      >
        <ErrorOutlineIcon
          sx={{
            fontSize: { xs: '60px', sm: '78px' },
            color: '#f58220',
          }}
        />
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            color: '#707070',
            fontSize: { xs: '32px', sm: '48px' },
            opacity: 0.8,
            fontFamily: '"Alegreya", sans-serif',
            textAlign: 'left',
            lineHeight: { xs: '32px', sm: '48px' },
          }}
        >
          Sorry...
          <br /> There are no results
        </Typography>
      </Box>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        columnGap={{ sm: '10px' }}
        rowGap={{ xs: '10px' }}
        alignItems="center"
        flexWrap={'wrap'}
        sx={{
          width: '100%',
          mb: { xs: 3, sm: 2 },
          alignItems: { xs: 'start', sm: 'center' },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: '#000000',
            fontSize: { xs: '23px', sm: '34px' },
            fontWeight: 'bold',
            textAlign: 'left',
          }}
        >
          Let us know what you want to see
        </Typography>
        <Button
          variant="outlined"
          sx={{
            color: '#52B4CC',
            fontWeight: 'bold',
            textTransform: 'none',
            fontSize: { xs: '20px', sm: '20px' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: 2,
            py: 0,
            gap: '4px',
            '&:hover': {
              backgroundColor: '#E0F7FA',
            },
          }}
          onClick={() =>
            window.open(
              'https://us12.list-manage.com/contact-form?u=2f9c94f750f3246158dc196fb&form_id=064b057a83f8a9f183029f1d66a16753',
              '_blank',
            )
          }
        >
          Click here
          <OpenInNewIcon fontSize="small" />{' '}
        </Button>
      </Stack>
    </Box>
  );
};

export default NoStoreSubmitForm;
