// Path: ./src/utils/handleDeviceRedirect.ts
import { Device } from '@capacitor/device';
import { App as CapacitorApp } from '@capacitor/app';
import { getPosition } from './getUserLocation';
import { setLocation } from 'app/store/reducer';
import { apiService } from './api';

/**
 * When on ios or android, we don't need to show the info page, this function will check the device platform and redirect to flyer list page directly
 * @param history
 * @param dispatch
 */
export async function handleDeviceRedirect(history, dispatch) {
  try {
    const info = await Device.getInfo();

    if ((info && info.platform === 'android') || info.platform === 'ios') {
      history.push(`/flyers`);
      CapacitorApp.addListener('appStateChange', ({ isActive }) => {
        if (isActive === true) {
          getPosition().then(position => {
            dispatch(setLocation(position));
          });
        }
      });
      const appInfo = await CapacitorApp.getInfo();
      const appInfoResponse =
        await apiService.gfMiscellaneousControllerDeviceGetInfo(
          JSON.stringify(appInfo),
        );
      if (appInfoResponse && appInfoResponse.shouldUpdate) {
        // eslint-disable-next-line no-restricted-globals
        const r = confirm(appInfoResponse.message);
        if (r === true) {
          if (info.platform === 'android') {
            window.location.href = appInfoResponse.androidUrl;
          }
          if (info.platform === 'ios') {
            window.location.href = appInfoResponse.iosUrl;
          }
        } else {
        }
      }
    } else {
    }

    apiService.gfMiscellaneousControllerDeviceGetInfo(JSON.stringify(info));
  } catch (error) {
    console.error(error);
  }
}
