// Path: ./src/utils/cacheRequest.ts
import { apiService } from './api';
import { FlyerTagListId } from 'utils/FlyerTagListId';

const gfStoreControllerFindMenusByNearbyStores = (
  page,
  limit,
  longitude,
  latitude,
) => {
  return apiService.gfStoreControllerFindMenusByNearbyStores(
    page,
    limit,
    longitude.changeDecimal(3),
    latitude.changeDecimal(3),
  );
};

const gfStoreControllerFindFlyersByNearbyStores = (
  page,
  longitude,
  latitude,
  limit,
  tagId,
) => {
  return apiService.gfStoreControllerFindFlyersByNearbyStores(
    page,
    limit,
    {},
    tagId,
    longitude.changeDecimal(3),
    latitude.changeDecimal(3),
    undefined,
  );
};

const gfStoreControllerFindAllByRange = (
  longitude: number,
  latitude: number,
  range = 5,
) => {
  return apiService.gfStoreControllerFindAllByRange(
    1,
    100,
    {},
    undefined,
    longitude.changeDecimal(3),
    latitude.changeDecimal(3),
    range,
  );
};

const gfFlyerControllerFindAllFlyersAndPromotions = (
  page,
  longitude,
  latitude,
  flyerLimit,
  promotionLimit,
) => {
  return apiService.gfFlyerControllerFindAllFlyersAndPromotions(
    page,
    undefined,
    {},
    undefined,
    longitude.changeDecimal(3),
    latitude.changeDecimal(3),
    undefined,
    undefined,
    flyerLimit,
    promotionLimit,
    undefined,
  );
};

/**
 * This is used to trigged a backend request. Backend will cache those request.
 * so when user navigate to the page actually using those request, it will come from the cache
 * @param longitude
 * @param latitude
 */
export const cacheRequest = function (longitude: number, latitude) {
  gfStoreControllerFindMenusByNearbyStores(1, 20, longitude, latitude);
  gfFlyerControllerFindAllFlyersAndPromotions(1, longitude, latitude, 10, 20);
  gfStoreControllerFindFlyersByNearbyStores(
    1,
    longitude.changeDecimal(3),
    latitude.changeDecimal(3),
    20,
    FlyerTagListId.Grocery.Id,
  );
  gfStoreControllerFindFlyersByNearbyStores(
    1,
    longitude.changeDecimal(3),
    latitude.changeDecimal(3),
    20,
    FlyerTagListId.ChineseSuperMarket.Id,
  );
  apiService.gfPromotionControllerFindAll(
    1,
    20,
    {},
    undefined,
    longitude.changeDecimal(3),
    latitude.changeDecimal(3),
    undefined,
  );
  apiService.gfPromotionControllerFindAll(
    1,
    undefined,
    {},
    undefined,
    longitude.changeDecimal(3),
    latitude.changeDecimal(3),
  );

  gfStoreControllerFindAllByRange(longitude, latitude);
};

export {
  gfStoreControllerFindAllByRange,
  gfFlyerControllerFindAllFlyersAndPromotions,
  gfStoreControllerFindFlyersByNearbyStores,
  gfStoreControllerFindMenusByNearbyStores,
};
