// Path: ./src/utils/analytics.ts
/**
 * this is the libs for push events to Amplitude
 */
import {
  GfFlyerDto,
  GfFlyerItemDto,
  GfMenuDto,
  GfPromotionDto,
  GfStoreDto,
} from '@swagger/typescript-fetch-goflyer';

/**
 *
 * @param event event name
 * @param eventProperties event property
 */
function callAmplitude(event: string, eventProperties?: any) {
  try {
    window?.amplitude?.getInstance().logEvent(event, eventProperties);
  } catch (error) {
    console.error(error);
  }
}
/**
 *
 * @param eventName you can pass this to override default event name
 * @param property pass key value pair
 */
export const view_flyer_list_page = function (eventProperties?: any) {
  callAmplitude('view_flyer_list_page', eventProperties);
};

/**
 * call this whenever users open goflyer web app.
 * @param eventProperties
 */
export const visit_site = function (eventProperties?: any) {
  callAmplitude('visit_site', eventProperties);
};

export const share_location = function (eventProperties?: any) {
  callAmplitude('share_location', eventProperties);
};

export const not_share_location = function (eventProperties?: any) {
  callAmplitude('not_share_location', eventProperties);
};

/**
 * call whenever users view a flyer detail page
 * @param eventName you can pass this to override default event name
 * @param property pass key value pair
 */
export const view_flyer_page = function (flyerDto: GfFlyerDto) {
  callAmplitude('view_flyer_page', {
    merchant: flyerDto?.stores?.[0]?.merchant,
    flyerDto,
  });
};

/**
 * call this when a user view restaurant menu
 * @param menuDto
 */
export const view_menu_page = function (menuDto: GfMenuDto) {
  callAmplitude('view_menu_page', {
    merchant: menuDto?.stores[0].merchant,
    menuDto,
  });
};

/**
 * when a user click the share button
 * @param flyerDto
 */
export const share_flyer = function (flyerDto: GfFlyerDto) {
  callAmplitude('share_flyer', {
    flyerDto,
  });
};

/**
 * call whenever click a hear button in flyer card
 * @param eventName you can pass this to override default event name
 * @param property pass key value pair
 */
export const click_fav_button_in_flyer_card = function (
  storeDto: GfStoreDto,
  showUnlikeIcon,
) {
  callAmplitude('click_fav_button_in_flyer_card', { storeDto, showUnlikeIcon });
};

/**
 * on desktop, there is shift left button in the flyer detail page
 */
export const click_flyer_shift_left_button = function () {
  callAmplitude('click_flyer_shift_left_button');
};

/**
 * on desktop, there is shift right button in the flyer detail page
 */
export const click_flyer_shift_right_button = function () {
  callAmplitude('click_flyer_shift_right_button');
};

/**
 * on desktop, when users click the zoom out button
 */
export const click_flyer_shrink_image_button = function () {
  callAmplitude('click_flyer_shrink_image_button');
};

/**
 * on desktop, when users click the zoom in button
 */
export const click_flyer_enlarge_image_button = function () {
  callAmplitude('click_flyer_enlarge_image_button');
};

/**
 * desktop, bottom right, user can click and show the download app view
 * @param showDownloadBanner
 */
export const click_show_banner_button = function (showDownloadBanner) {
  callAmplitude('click_show_banner_button', { showDownloadBanner });
};

/**
 * "When the user types something in the search box, for example, if they search for 'a', the app sends an event. If the user then types 'b' after 'a', the app also sends this event."
 * @param term
 */
export const search_item = function (term: string) {
  callAmplitude('search_item', { term });
};
export const click_back_button = function () {
  callAmplitude('click_back_button');
};
export const click_chinese_language = function () {
  callAmplitude('click_chinese_language');
};
export const click_english_language = function () {
  callAmplitude('click_english_language');
};
export function click_search_suggest_close_button() {
  callAmplitude('click_search_suggest_close_button');
}
export function click_search_suggestion(term: string) {
  callAmplitude('click_search_suggestion', { term });
}

export function click_remove_deal_from_shopping_list(
  promotion: GfPromotionDto | GfFlyerItemDto,
) {
  callAmplitude('click_remove_deal_from_shopping_list', { promotion });
}

export function click_a_deal(promotion: GfPromotionDto) {
  callAmplitude('click_a_deal', { promotion });
}
export function click_close_modal() {
  callAmplitude('click_close_modal');
}

export function click_add_to_shopping_list_button(
  promotion: GfPromotionDto | GfFlyerItemDto,
) {
  callAmplitude('click_add_to_shopping_list_button', { promotion });
}
export function view_deals_list_page() {
  callAmplitude('view_deals_list_page');
}
export function view_favourites_page() {
  callAmplitude('view_favourites_page');
}

/**
 * flyer sub page. for example, grocery flyer list
 */
export function view_flyer_tag_page(eventProperties?: any) {
  callAmplitude('view_flyer_tag_page', eventProperties);
}

export function view_search_page() {
  callAmplitude('view_search_page');
}
export function view_shopping_list_page() {
  callAmplitude('view_shopping_list_page');
}
export function view_store_detail_page(store: GfStoreDto) {
  callAmplitude('view_store_detail_page', { store });
}
export function view_deals_detail_page(promotion: GfPromotionDto) {
  callAmplitude('view_deals_detail_page', { promotion });
}

export function view_not_found_page() {
  callAmplitude('view_not_found_page');
}

export function view_map_page() {
  callAmplitude('view_map_page');
}
export function view_landing_info_page() {
  callAmplitude('view_landing_info_page');
}

export function view_about_page() {
  callAmplitude('view_about_page');
}

export function view_signin_page() {
  callAmplitude('view_signin_page');
}
export function click_fav_button_in_store_detail_page(store: GfStoreDto) {
  callAmplitude('click_fav_button_in_store_detail_page', store);
}

export function click_category_list(category: string) {
  callAmplitude('click_category_list', { category });
}

export function click_get_mobile_app(platform: string) {
  callAmplitude('click_get_mobile_app', { platform });
}

export function click_close_download_banner_mobile() {
  callAmplitude('click_close_download_banner_mobile');
}

export function click_submit_feedback_message() {
  callAmplitude('click_submit_feedback_message');
}

export function click_select_option(option: string) {
  callAmplitude('click_select_option', { option });
}

export function click_side_bar_menu() {
  callAmplitude('click_side_bar_menu');
}

/**
 * "In the mobile view, a pop-up window appears asking the user to subscribe to us. This event is triggered when the user clicks the subscribe button."
 */
export function click_subscription_button() {
  callAmplitude('click_subscription_button');
}

export function error_subscription_invalid_email() {
  callAmplitude('error_subscription_invalid_email');
}

export function error_subscription_email_already_exist() {
  callAmplitude('error_subscription_email_already_exist');
}

export function success_subscription_email() {
  callAmplitude('success_subscription_email');
}
export function click_map_marker(store: GfStoreDto) {
  callAmplitude('click_map_marker', store);
}
export function click_fav_button_in_flyer_detail_page() {
  callAmplitude('click_fav_button_in_flyer_detail_page');
}

export function click_fav_button_in_favourites_store_page() {
  callAmplitude('click_fav_button_in_favourites_store_page');
}

/**
 * in the shopping list in the mobile view. if you add deals to shopping list, there will be a redeem button link to supermarket's website
 */
export function click_redeem_button_mobile() {
  callAmplitude('click_redeem_button_mobile');
}

export function click_close_button_search_box() {
  callAmplitude('click_close_button_search_box');
}

export function double_click_flyer() {
  callAmplitude('double_click_flyer');
}

export function share_menu(params: GfMenuDto) {
  callAmplitude('share_flyer', {
    params,
  });
}

export function open_push_notification(body: any) {
  try {
    callAmplitude('open_push_notification', {
      body,
    });
  } catch (error) {}
}

export function push_notification_received(notification: any) {
  try {
    callAmplitude('push_notification_received', { notification });
  } catch (error) {}
}

export function push_notification_registered_error(error: any) {
  try {
    callAmplitude('push_notification_registered_error', { error });
  } catch (error) {}
}

export function push_notification_registered(token: any) {
  try {
    callAmplitude('push_notification_registered', { token });
  } catch (error) {}
}

export function push_notification_granted(result: any) {
  try {
    callAmplitude('push_notification_granted', { result });
  } catch (error) {}
}

export function push_notification_not_granted(result: any) {
  try {
    callAmplitude('push_notification_not_granted', { result });
  } catch (error) {}
}

export function platform(platform: string) {
  callAmplitude('mobile platform', { platform });
}
