// Path: ./src/app/mobile-desktop-common-components/LanguageSwitcher/index.tsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Menu,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import styled from 'styled-components/macro';
import PopupState, { bindToggle, bindMenu } from 'material-ui-popup-state';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { localstorageSet } from 'utils/localstorage';
import ChineseFlagSvg from 'app/assets/svg/ChineseFlagSvg';
import CanadaFlagSvg from 'app/assets/svg/CanadaFlagSvg';
import FranceFlagSvg from 'app/assets/svg/FranceFlagSvg';

const languageOptions = [
  {
    value: 'en',
    label: 'English',
    testId: 'English-Language-Button',
    flagLogo: CanadaFlagSvg,
  },
  {
    value: 'zh',
    label: '中文',
    testId: '中文-Language-Button',
    flagLogo: ChineseFlagSvg,
  },
  {
    value: 'fr',
    label: 'French',
    testId: 'French-Language-Button',
    flagLogo: FranceFlagSvg,
  },
];

const styles = {
  button: {
    color: '#C6C5CA',
    borderRadius: 18,
    width: 82,
    height: 36,
    font: 'normal normal 400 17px/20px SFProText',
    letterSpacing: '0px',
    opacity: '1',
    padding: '0px',
  },
  menuPaper: {
    marginTop: '7px',
    marginLeft: '3px',
    width: '250px',
    height: '140px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '15px',
    justifyContent: 'center',
    overflow: 'hidden',
    zIndex: 999999999999,
  },
  formControlLabel: {
    width: '100%',
    margin: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '50px',
    paddingRight: '9px',
    alignItems: 'center',
    letterSpacing: '-0.41px',
    color: 'rgba(0, 0, 0, 1)',
    opacity: '1',
    fontFamily: 'SFProText',
  },
};

const FlagComponent = ({ value }) => {
  const FlagImageComponent = languageOptions.find(
    val => val.value === value,
  )?.flagLogo;

  return FlagImageComponent ? (
    <FlagImageComponent width={30} height={16} />
  ) : (
    <CanadaFlagSvg width={30} height={16} />
  );
};

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const { replace, location } = useHistory();

  let label = getLanguageLabel(language);

  const [value, setValue] = useState(language);
  const [selectedLabel, setSelectedLabel] = useState(label);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedLanguage = event.target.value;
    setValue(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    const languageLabel = getLanguageLabel(selectedLanguage);

    switch (selectedLanguage) {
      case 'en':
        handleLanguageChange('en', 'english', languageLabel);
        break;
      case 'zh':
      case 'zh-CN':
        handleLanguageChange('zh', 'chinese', languageLabel);
        break;
      case 'fr':
        handleLanguageChange('fr', 'french', languageLabel);
        break;
      default:
        break;
    }

    window?.location?.reload();
  };

  const handleLanguageChange = (
    langCode: string,
    stateValue: string,
    label: string,
  ) => {
    localstorageSet('Lang', langCode);
    replace({ state: stateValue });
    setSelectedLabel(label);
  };

  /**
   * Sets the default language to Chinese if the user's preferred language is not stored locally.
   *
   * @function
   * @name setDefaultLanguageIfNotSet
   * @returns {void}
   *
   * @description
   * This function checks if the user's preferred language is stored in the local storage.
   * If not, it sets the default language to Chinese ('zh'), updates the state, and triggers
   * the necessary actions to handle the language change.
   *
   */
  const setDefaultLanguageIfNotSet = () => {
    const localLang = localStorage.getItem('Lang');
    const defaultLanguage = 'zh';
    if (!localLang) {
      const languageLabel = getLanguageLabel(defaultLanguage);

      setValue(defaultLanguage);
      i18n.changeLanguage(defaultLanguage);
      handleLanguageChange(defaultLanguage, 'chinese', languageLabel);
    }
  };

  useEffect(() => {
    const localLang = localStorage.getItem('Lang');
    if (localLang) {
      const languageLabel = getLanguageLabel(localLang);
      setSelectedLabel(languageLabel);
      setValue(localLang as string);
    }
    setDefaultLanguageIfNotSet();
  }, [location.state]);

  function getLanguageLabel(langCode: string): string {
    switch (langCode) {
      case 'zh-CN':
      case 'zh':
        return '中文';
      case 'fr':
        return 'Fr';
      default:
        return 'ENG';
    }
  }

  return (
    <Box data-testid="languageswitcher-test-id">
      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => (
          <>
            <Button
              data-testid={selectedLabel}
              variant="outlined"
              color="inherit"
              style={styles.button}
              {...bindToggle(popupState)}
              startIcon={
                <FlagWrapper>
                  <FlagComponent value={value} />
                </FlagWrapper>
              }
            >
              {selectedLabel}
            </Button>
            <Menu
              {...bindMenu(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              sx={{ zIndex: 99999999 }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: styles.menuPaper,
              }}
            >
              <FormControl
                component="fieldset"
                style={{
                  width: 250,
                }}
              >
                <RadioGroup
                  data-testid="language switcher radio group handle chinese"
                  aria-value={selectedLabel}
                  name="language"
                  style={{
                    alignItems: 'center',
                  }}
                  value={value}
                  onChange={handleChange}
                  onClick={popupState.close}
                >
                  {languageOptions.map(({ value, label, testId }, index) => (
                    <FormControlLabel
                      key={value}
                      value={value}
                      control={<Radio color="primary" />}
                      label={label}
                      labelPlacement="start"
                      data-testid={testId}
                      style={{
                        ...styles.formControlLabel,
                        borderBottom:
                          index !== languageOptions.length - 1
                            ? '1px solid #dcdcdd'
                            : 'none',
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Menu>
          </>
        )}
      </PopupState>
    </Box>
  );
};

const FlagWrapper = styled.div`
  margin-right: -5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
