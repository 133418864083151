// Path: ./src/utils/FlyerTagListId.tsx
interface FlyerTagListProp {
  Grocery: {
    Id: string;
  };
  Restaurant: {
    Id: string;
  };
  ChineseSuperMarket: {
    Id: string;
  };

  ElectricalAppliances: {
    Id: string;
  };

  upComingFlyer: {
    Id: string;
  };
}

export const FlyerTagListId: FlyerTagListProp = {
  Grocery: {
    Id: '51a189af-5a46-4e51-b2cc-85584c3cfded',
  },
  Restaurant: {
    // Copy paste MenuTag id here
    Id: 'ae8a2c78-ac00-4ac6-8ba4-258da07c809c',
  },
  ChineseSuperMarket: {
    Id: '441ca9f7-b5e1-49a5-beda-604059dbb994',
  },
  ElectricalAppliances: {
    Id: '1b60cefb-5901-454e-ac84-2d1c879c16c7',
  },
  upComingFlyer: {
    Id: 'upComingFlyer',
  },
};
